import React, { useState, useEffect } from 'react';
import { Redirect, Link } from "react-router-dom";
import { withRouter } from '../Hooks';

import MediaQuery from 'react-responsive'

import '../styles/App.css'
import '../styles/Home.css';
    
const Home = (props) => {
  const [ showTesting, setShowTesting ] = useState(false)

  return(
    <div className="main" style={{height: '100%'}}>
    	<div className="btn-container">

        <div className='home_menu_list' style={{maxHeight: '70vh'}}>  

          <Link to='/songbook'><div>Songbook</div></Link>
          <Link to='/archive'><div>Archive</div></Link>
          <hr/>
          <Link to='/composer-upload'><div>Submit as Composer</div></Link>
          <hr/>
          <Link to='/request-list'><div>Open Requests</div></Link>
          <hr/>
          <Link to='/track-tagger'><div>Track Tagger</div></Link>
          <hr/>
          <Link to='/users'><div>User Accounts</div></Link>
          <Link to='/website-users'><div>Website Users</div></Link>

          <Link to='/vl-composers'><div>Composer VLID List</div></Link>
          <Link to='/download-tool'><div>Download 4EM Tool</div></Link>

          <div 
            style={{color: 'red', margin: '40px 0 5px 0', cursor: 'pointer'}}
            onClick={() => setShowTesting(!showTesting)}
            >
              {!showTesting ? ("SHOW TESTING PAGES") : ("HIDE")}
          </div>

          {showTesting && (
            <div>
              <Link to='/test-composer-upload'><div>Testing Submit as Composer</div></Link>
              <Link to='/test-songbook'><div>Testing Songbook</div></Link>
            </div>
          )}

        </div>  
    	</div>

    </div>
  );
}

export default withRouter(Home);