import './polyfills'
import fetch from 'isomorphic-fetch'

import promise from 'es6-promise';
import 'isomorphic-fetch';

promise.polyfill();

import React from 'react';
import ReactDOM from 'react-dom/client';

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { CaptureConsole } from '@sentry/integrations';


import App from './Components/App';
import './index.css';

Sentry.init({
  dsn: "https://f730e271d3fe4cedafda5c5f12073cb8@o253119.ingest.sentry.io/6038024",
  integrations: [
    new Integrations.BrowserTracing(),
    new CaptureConsole({
      levels: ['error']
    })
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)


// ReactDOM.render(<App/>, document.getElementById('root'));