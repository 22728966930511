/*
* @Author: Joe Steccato
* @Date:   2023-04-27 17:08:51
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-08-21 10:25:28
*/
import { useState, useEffect } from 'react'
import Dropdown from 'react-dropdown';

import AlbumInput from './AlbumInput'

import { DROPDOWN_OPTS } from '../../Constants';

import { useOpenRequests } from '../../Hooks'

import styles from './TrackMoreInfo.module.scss'


const TrackMoreInfo = ({ 
	track, 
	updateTrackFields, 
	
	handleUserInput, 
	handleDropDown, 
	handleToggle 
}) => {
	const [ showMore, setShowMore ] = useState(false)

	const { titleList: openRequestList } = useOpenRequests()
	
	useEffect(() => {
		if(
		  (track.trackSubmissionType !== 'Standard Spec') ||
		  (track.trackSubmissionType !== '') ||
		  (track.trackFee !== '') ||
		  (track.trackRequest !== '')
		){
		  setShowMore(true)
		}
	},[track])

	const handleLibrary = (e) => {
		const trackUpdates = {trackLibrary: e.value}
		if(e.value === 'Arketype'){
			trackUpdates.trackSubmissionType = 'Upfront Fee - Arketype Trailer'
		}
		updateTrackFields(trackUpdates)
	}

	const handleSubmissionType = (e) => {
		const trackUpdates = {trackSubmissionType: e.value}
		if(e.value.includes('Arketype')){
			trackUpdates.trackLibrary = 'Arketype'
		}
		updateTrackFields(trackUpdates)
	}


	return(
		<div className={"showMore" + (showMore ? " showMoreActive" : "")} >
		  <div 
		    className="showMoreText" 
		    onClick={() => setShowMore(!showMore)}
		   >
		      SHOW {showMore ? "LESS -" : "MORE +"}
		  </div>

		  {showMore ? (
		    <>
		      <div className={styles.row}>
		        <div className={styles.label}>Request:</div>
		        <input
		          onBlur={(e) => updateTrackFields({ [e.target.name]: e.target.value })}
		          name="trackRequest"
		          value={track['trackRequest']}
		          onChange={handleUserInput}  
		        />
		      </div>

		      <div className={styles.row}>
		        <div className={styles.label}>Open Request #:</div>
		        <Dropdown
		          name="trackRequestNum"
		          options={openRequestList} 
		          value={track['trackRequestNum']}
		          onChange={(e) => handleDropDown(e.value,"trackRequestNum")} />
		      </div>

		      <div className={styles.row}>
		        <div className={styles.label}>Album:</div>
		        <AlbumInput
		        	id={'trackFormAlbum'}
		          	onBlur={(name, value) => updateTrackFields({ trackAlbum: value })}
		          	name="trackAlbum"
		          	isAdmin={true}
		          	thisComposer={null}
		          	value={track['trackAlbum'] || ''}
		          	valueChanged={(e) => {
		          	  handleUserInput({target: { value: e, name: 'trackAlbum'}})
		          	}}
		          	setValue={(album) => {
		          		updateTrackFields({ trackAlbum: album })
		          	}}
		        />
		      </div>
		      
		      <div className={styles.row}>
		        <div className={styles.label}>Library:</div>
		        <Dropdown
		        	name="trackLibrary"
		        	options={['4 Elements Music', 'Arketype']} 
		        	value={track['trackLibrary'] || '4 Elements Music'}
		        	onChange={handleLibrary} 
		        />
		      </div>

		      <div className={styles.row}>
		        <div className={styles.label}>Type:</div>
		        <Dropdown
		        	name="trackSubmissionType"
		        	options={DROPDOWN_OPTS[2]} 
		        	value={track['trackSubmissionType']}
		        	onChange={(e) => handleDropDown(e.value,"trackSubmissionType")} 
		        />
		      </div>

		      <div className={styles.row}>
		        <div className={styles.label}>Fee:</div>
		        <textarea
		       		onBlur={(e) => updateTrackFields({ trackFee: e.target.value })}
		       		name="trackFee"
		       		value={track['trackFee']}
		       		onChange={handleUserInput}  
		       	/>
		      </div>
		      

		          <label className={styles.rowCheckbox}>
		            <input
		              name="trackAddedComposerPayments"
		              type="checkbox"
		              checked={track['trackAddedComposerPayments']}
		              onChange={handleToggle}  
		            />
		            Added to Composer Payments
		          </label>
		    </>
		  ) : null}
		</div>
	)
}

export default TrackMoreInfo