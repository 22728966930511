import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import styles from './MainMenuPopup.module.scss'


const Item = ({ closePopup, to, name }) => <Link onClick={closePopup} {...{to}}>{name}</Link>

const Spacer = () => <div className={styles.spacer}></div>


const PAGES = [
	{
		to: '/' ,
		name: 'Home',
	},
	{
		to: '/songbook' ,
		name: "Songbook",
	},
	{
		to: '/archive' ,
		name: "Archive",
	},
	{
		to: '/composer-upload' ,
		name: "Submit as Composer",
	},
	{
		to: '/request-list' ,
		name: "Open Request",
	},
	{
		to: '/track-tagger' ,
		name: "Track Tagger",
	},
	{
		to: '/users' ,
		name: "User Accounts",
	},
	{
		to: '/website-users' ,
		name: "Website Users",
	},
	{
		to: '/vl-composers' ,
		name: "Composer VLIDs",
	},
	{
		to: '/download-tool' ,
		name: "Download 4EM Tool",
	}

]



const MainMenuPopup = ({ closePopup, logout }) => {

	const linkMarkup = PAGES.map((page,i) => 
		<Item 
			{...{closePopup}} 
			key={i+"_"+page.name} 
			to={page.to} 
			name={page.name}
		/>
	)

	return (  
			<div className={styles.container} onClick={closePopup}>  
				<div className={styles.menuList}>  

					{linkMarkup}
					<Spacer/>
					<Link onClick={logout}>Logout &#8594;</Link>
					
				</div>  
			</div>  
		);  

}

export default MainMenuPopup;