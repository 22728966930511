/*
* @Author: Joe Steccato
* @Date:   2023-04-27 17:35:31
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-05-18 16:29:42
*/

import {useEffect, useState} from 'react'

import ComposerInput from './ComposerInput';

import styles from './TrackForm.module.scss'

const TrackCollaborator = ({ track, updateTrackFields, handleUserInput }) => {
	const [ showCollab, setShowCollab ] = useState(false)

	if(!showCollab)
		return(
			<div 
				className={styles.collabLabel} 
				onClick={() => {setShowCollab(!showCollab)}}
			>
			  	Collab(s): {track['trackCollaborator']}
			</div>
		)

	return(
		<div className={styles.collabLabel + " " + styles.open}>

		  	<ComposerInput
		  		id={'trackFormCollab'}
		   		onBlur={(name,value) => updateTrackFields({ [name]: value })}
		   		name="trackCollaborator"
		   		placeholder="Collaborator(s)"  
		   		className={styles.inputCollab}
		   		value={track['trackCollaborator']}
		   		composerChanged={(e) => {
		   		  handleUserInput({target: { name: 'trackCollaborator', value: e }})
		   		}}
		    	setPayment={() => null}
		  	/>

		  	<div 
		  		className={styles.collabLine} 
		  		onClick={() => {setShowCollab(!showCollab)}}
		  	>
		    	HIDE COLLABS
		  	</div>

		  	<hr/>
		</div>
	)
}

export default TrackCollaborator