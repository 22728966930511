// import '../../styles/Submit.css'
import { FC } from 'react'; 
import styles from './PreviousUploads.module.scss';

function formatDate(isoString: string): string {
	var d = new Date(isoString);
	return d.toLocaleDateString("en-US");
}


interface PreviousUploadsProps {
  previousFiles: {
    name: string;
    date: string;
  }[];
}


const PreviousUploads: FC<PreviousUploadsProps> = ({ previousFiles }) => (
	<div className={styles.container}>
		<div className={styles.fileHeader}>
			<div className={styles.name}>PREVIOUS UPLOADS</div>
			<div className={styles.date}>DATE UPLOADED</div>
		</div>
		{previousFiles.map((f,i) => (
			<div key={i} className={styles.fileItem}>
				<div className={styles.name}>
					{f.name}
				</div>
				<div className={styles.date}>
					{formatDate(f.date)}
				</div>
			</div>
		))}
	</div>
)

export default PreviousUploads;