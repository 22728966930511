/*
* @Author: Joe Steccato
* @Date:   2023-04-10 14:56:31
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-10-09 16:56:10
*/

export * from './exportForSession';
export * from './filterTracks';


export function validateEmail(value){
	var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
	if(value.match(mailformat))
		return true;
	else
		return false;
}

export const generatePassword = (
  length = 10,
  wishlist = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$"
) => Array(length)
      .fill('')
      .map(() => wishlist[Math.floor(crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * wishlist.length)])
      .join('');


export const capitalize = (word) => word.charAt(0).toUpperCase() + word.slice(1)
  

export function pad(n) {
  let width = 4;
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
}


export function genericDateString(separator){
  var timeNow = new Date();
  return [
    timeNow.toLocaleDateString('en-us',{ weekday: 'short' }),
    timeNow.toLocaleDateString('en-us',{ dateStyle: 'short' }),
    timeNow.toLocaleTimeString('en-us',{ timeStyle: 'short' })
  ].join(separator)
}

export const emailToName = (email) => email.charAt(0).toUpperCase() + email.substring(1, email.indexOf("@"))


export const createNotesHeading = ( newTrack, status="", email ) => {
  let date = new Date();
  let dateString = [
    date.toLocaleDateString('en-us',{ weekday: 'short' }),
    date.toLocaleDateString('en-us',{ dateStyle: 'short' }),
    date.toLocaleTimeString('en-us',{ timeStyle: 'short' })
  ].join(" ")
  
  let names = newTrack['trackFiles'] || [{name: ''}];
  if(names.length < 1) names = [{name: ''}]
  let revFilenames = names[names.length-1].name || '';
  const prevNotes = newTrack['trackNotes'];
  const newNotes = `${prevNotes}\n ${revFilenames} \n-- by: ${emailToName(email)} on [${dateString}]\n\n${status}\n`;
  
  return(newNotes)
}

export function parseDate(dateStr) {
  const monthMap = {
    'Jan': 0,
    'Feb': 1,
    'Mar': 2,
    'Apr': 3,
    'May': 4,
    'Jun': 5,
    'Jul': 6,
    'Aug': 7,
    'Sep': 8,
    'Oct': 9,
    'Nov': 10,
    'Dec': 11
  };

  const match = /(\w+)\.\s+(\d+),\s+(\d+)/.exec(dateStr);
  if (!match) {
    throw new Error("Invalid date format");
  }

  const [, monthAbbrev, day, year] = match;

  const monthNumber = monthMap[monthAbbrev];
  if (monthNumber === undefined) {
    throw new Error("Invalid month abbreviation");
  }

  return new Date(Number(year), monthNumber, Number(day));
}
