import React, { useState, useEffect } from 'react';

import LoadingBars from '../Common/LoadingBars'

import '../../styles/ExportFilesPopup.css'
import '../../styles/ComposerSubmitPopup.css'

// Popup appears when composer submits a track
const ComposerSubmitPopup = (props) => {
	return (  
		<div className='popup'>  
			<div className='popup_inner composer_popup_inner' style={{maxHeight: '70vh'}}>  
				<h2>{props.loading ? "UPLOADING..." : "UPLOAD SUCCESSFUL"}</h2>  
					{props.loading ? (
						<LoadingBars loading={true}/>
					):(
					<div>
						<div className="composerSubmitTitle">{props.submissionTitle} ({props.newFiles.length} files)</div>
						<div className="composerSubmitFileList">
							{props.newFiles.map((f,i) => {
								return(<div key={i} className="submitPopupFile">{f.name}</div>)
							})}
						</div>
						<button className="composerPopupOK" onClick={props.closePopup}>
							OK
						</button>
						<div id='closePopup' onClick={props.closePopup}>&#10005;</div>  
					</div>
				)}
			</div>  
		</div>  
	);  
}

export default ComposerSubmitPopup;