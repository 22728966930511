import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useCopyClipboard } from '@lokibai/react-use-copy-clipboard'
import axios from '../../axios'
import Tooltip from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap_white.css';
import { ReactSVG } from 'react-svg';


import { COLOR_KEY } from '../../Constants'

import KeywordCategory from './KeywordCategory'

import 'react-tabs/style/react-tabs.css';
import '../../styles/InstructionalPopup.css';
import '../../styles/ExportFilesPopup.css'

import styles from './TrackTagger.module.scss'

var mood_order = ["Abstract","Atmospheric","Energetic","Strength","Dramatic","Emotional","Sad","Relaxed","Romantic","Comical/ Humorous","Positive/ Optimistic"]
var style_order = ["Film/ TV/ Stage","Orchestral","Settings","Corporate","Sports","Children","Trailer Music","Drama","Underscores","Electronica/ Dance","Pop","Rock","Blues","Country","Urban","Reggae","Religious","Americana","Traditional Dance","Classical","Vocals","Latin","International","Holiday/ Special Occasion","Jazz","Musical Feel"]

function convertKeywords(data){
	return Object.keys(data).map(key => {
		data[key].sort((a,b) => (a.keyword > b.keyword) ? 1 : -1)
		return { 
	  		subcat: key,
	  		items: data[key]
	  	}
	})
}

function sortSubcats(data, arr){
	return arr.map(subcat => 
		data.find(keyword => keyword.subcat === subcat)
	)
}


const TrackTagger = (props) => {
	const initSelected = []
	const composerSelected = []

	// Separate composer custom keywords from Filemakerr VLID keywords
	props.currentKeywords.forEach(k => {
		if(k.substring(0,2) !== "VL") composerSelected.push(k)
		else initSelected.push(k)
	})

	const [ tabSelected, setTabSelected ] = useState( 0 )
	const [ selected, setSelected ] = useState( initSelected )

	const [ keywords, setKeywords ] = useState([])
	const [ instruments, setInstruments ] = useState([])

	const [ tagsMood, setTagsMood ] = useState([])
	const [ tagsStyle, setTagsStyle ] = useState([])
	const [ isCopied, setCopied ] = useCopyClipboard( '' )
	const [ showCopied, setShowCopied ] = useState(false)


	useEffect(() => {
		if(isCopied){
			setShowCopied(true)
			setTimeout(() => {
				setShowCopied(false)
			}, 4000)
		}
	},[isCopied])


	useEffect(() => {
	    // First GET request
	    axios.get('/keywords')
	      	.then(function(response) {
	        	const { data } = response;
	
	        	const kw_list = convertKeywords(data.keywords);
	        	const i_list = convertKeywords(data.instruments);
	
	        	setInstruments(i_list);
	        	setKeywords(kw_list);
	      	})
	    	.catch(function(error) {
	      		console.error('Error fetching keywords:', error);
	    	});

	    // Second GET request
	    axios.get('/keywords/get_tags_json')
	      	.then(function(response) {
	        	const { data } = response;
	        	const tags_mood = sortSubcats(convertKeywords(data.tags["Mood"]), mood_order);
	        	const tags_style = sortSubcats(convertKeywords(data.tags["Musical Style"]), style_order);
	
	        	setTagsMood(tags_mood);
	        	setTagsStyle(tags_style);
	      	})
	      	.catch(function(error) {
	        	console.error('Error fetching tags:', error);
	      	});
	    // Additional code for your component
	  }, []);


	const tabChange = (i,li,e) => { setTabSelected(i); }

	const toggleKeyword = (id,isSelected) => {
		if(isSelected){
			const new_sel = [...selected]
			var index = new_sel.indexOf(id);
			if (index !== -1) {
			  new_sel.splice(index, 1);
			}
			setSelected(new_sel)
			props.setSelected(new_sel)
		}else{
			const new_sel = [...selected,id]
			setSelected(new_sel)
			props.setSelected(new_sel)
		}
	}


	return (  
		<div>
		  {props.instrumentsOnly ? (
		    <div className={styles.container}>
		      <div className={styles.columnContainer}>
		        {instruments.map((k, i) => {
		          return <KeywordCategory key={i} keyword={k} selected={selected} toggleKeyword={toggleKeyword} />;
		        })}
		      </div>
		      <button onClick={() => props.closePopup([...selected, ...composerSelected])}>Done</button>
		    </div>
		  ) : (
		    <div>
		      <div className={styles.trackTitle}>

		        {props.trackTitle && <div><b>{props.trackTitle}</b><br /></div>}

		        <div className={styles.selectedCount}>
		        	{selected.length} tags selected.
		        </div>

		      </div>
		      <Tabs onSelect={tabChange} default={0}>
		        <TabList>
		          <Tab>Keywords</Tab>
		          <Tab>Instruments</Tab>
		          <Tab>Tags - Mood</Tab>
		          <Tab>Tags - Musical Style</Tab>
		        </TabList>
		        <TabPanel>
		          <div className={styles.tabContainer}>
		            {keywords.map((k, i) => {
		              return <KeywordCategory key={i} keyword={k} selected={selected} toggleKeyword={toggleKeyword} />;
		            })}
		          </div>
		        </TabPanel>
		        <TabPanel>
		          <div className={styles.tabContainer}>
		            {instruments.map((k, i) => {
		              return <KeywordCategory key={i} keyword={k} selected={selected} toggleKeyword={toggleKeyword} />;
		            })}
		          </div>
		        </TabPanel>
		        <TabPanel>
		          <div className={styles.tabContainer}>
		            {tagsMood.map((k, i) => {
		              return <KeywordCategory key={i} keyword={k} selected={selected} toggleKeyword={toggleKeyword} />;
		            })}
		          </div>
		        </TabPanel>
		        <TabPanel>
		          <div className={styles.tabContainer}>
		            {tagsStyle.map((k, i) => {
		              if (k) {
		                return <KeywordCategory key={i} keyword={k} selected={selected} toggleKeyword={toggleKeyword} />;
		              }
		              return null;
		            })}
		          </div>
		        </TabPanel>
		      </Tabs>
		      {!props.isPopup && selected.length > 0 && (
		        <div
		          className={styles.taggerCopyButton}
		          onClick={() => setCopied(selected || [])}
		        >
		          <ReactSVG
		            src={process.env.PUBLIC_URL + './svgs/copy.svg'}
		            renumerateIRIElements={false}
		            wrapper="span"
		            className={"tagging_svg " + styles.tagging_svg}
		            style={{ width: "16px", marginRight: "6px" }}
		            id=""
		          />
		          <div>{showCopied ? "Copied!" : `Copy ${selected.length} Tags`}</div>
		        </div>
		      )}
		    </div>
		  )}
		</div>
	);  
}

export default TrackTagger;