import { getColor } from '../Constants' 

export function filterTracks(track,filter="",selectedResponse = 0, status, showArchived="BOTH"){
  if(showArchived !== "BOTH"){
    const shouldShow = showArchived == "CURRENT" ? 0 : 1
    if((track.isArchived ? 0 : 1) == shouldShow){
      return false
    }
  }

  const withPayments = (track.trackSubmissionType ? track.trackSubmissionType.includes("Fee") : false) && !track.trackAddedComposerPayments ? "$" : "";
  const isStarred = track.trackStar === true ? "* star starred" : "";
  const color = "color:" + getColor(track)

  if(status !== "SHOW ALL"){
    if(status === "Up For Review"){
      if(track.trackStatus !== "Up For Review" && track.trackStatus !== "Up For Review (Relisten)")
        return false
    }
    else if(track.trackStatus !== status) 
    { 
      return false
    } 
  }
  let requestLc = `${track.trackRequest && track.trackRequest} ${track.trackRequestNum && track.trackRequestNum} ${track.trackLibrary && track.trackLibrary}`
  let lc =  requestLc + isStarred + " " + withPayments + " " + track.trackTitle + " " + track.trackComposer + " " + track.trackStatus + " " + track.trackNotes + " " + track.trackSubmissionType + " " + color;
  lc = lc.toLowerCase()
  let responded = track.trackResponded;
  if(filter === undefined) filter = ""
  let searchFilter = filter.toLowerCase();
  let isMatch;
  switch(selectedResponse){
    case 0:
      isMatch = (searchFilter === "") ? true : lc.includes(searchFilter);
      break;
    case 1:
      if(responded){ 
        isMatch = (searchFilter === "") ? true : lc.includes(searchFilter);
      }
      break;
    case 2:
      if(!responded){
        isMatch = (searchFilter === "") ? true : lc.includes(searchFilter);
      }
      break;
    default:
      isMatch = false;
      console.log("Response Radio Button value not recognized: ");
  }
  return isMatch;
}
