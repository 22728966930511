import React, { useState, useEffect } from 'react';
import Textarea from 'react-textarea-autosize';

import styles from './FileInput.module.scss'

const FileItem = ({id, file, updateFile, showCheckboxes}) => {
  if(!file.name) 
    return null

  const [ fileName, setFileName ] = useState( file.name )
  const [ current, setCurrent ] = useState( file.isCurrent )
  const [ reference, setReference ] = useState( file.isReference )

  const warning = !(fileName.includes('.wav') || fileName.includes('.aif')) //|| (file.name.includes(' .wav') || file.name.includes(' .aif'))

  useEffect(() => {
    setFileName(file.name);
    setCurrent(file.isCurrent);
    setReference(file.isReference)
  }, [file])

  const updateName = () => {
    updateFile({
      ...file,
      name: fileName,
    },id)
  }

  const handleUserInput = (e) => {
    if(e.keyCode==13){
      e.preventDefault();
      document.getElementById("fileItemTextArea").blur();
    }else{
      setFileName(e.target.value)
    }
  }

  const updateSelect = (e,otherBox, otherValue) => {
    updateFile({
      ...file,
      [e.target.name]: e.target.checked,
      [otherBox]: e.target.checked ? false : otherValue
    },id)
  }

  const backspaceDelete = (e) => {
    if(fileName===''){
      if(e.keyCode === ( 8 || 46)){
        updateName()
      }
    }else if(e.keyCode === 13){
      e.preventDefault();
      document.getElementById("fileItemTextArea").blur();
      updateName()
    }
  }
  
  return (
    <div className={styles.fileRow}>

        <Textarea
          onBlur={updateName}
          value={fileName}
          spellCheck={false}
          onKeyDown={backspaceDelete}
          onChange={handleUserInput}  
          // className={"inputFile " + (warning ? 'mp3File' : '')}
          className={styles.name + " " + (warning  ? styles.mp3File : '')}
        />


      {showCheckboxes && (
        <div className={styles.checkboxes}>
            <input
              name={"isCurrent"}
              className={styles.checkbox}
              type={"checkbox"}
              checked={current}
              onChange={(e) => updateSelect(e,'isReference',reference)}  
            />  
            <input
              name={"isReference"}
              className={styles.checkbox}
              type={"checkbox"}
              checked={reference}
              onChange={(e) => updateSelect(e,'isCurrent',current)}  
            />
          </div>
      )}
      
    </div>
  )
}


const AddFileInput = ({ addFiles }) => {
  const [ newFileName, setNewFileName ] = useState( '' )

  const onEnterPress = (e) => {
    if(e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      addFile();
    }
  }

  const addFile = () => {
    const newFiles = newFileName.split('\n')
      .filter(name => name !== '')
      .map(name => ({
        name: name,
        isCurrent: true,
        isReference: false,
        date: new Date().toISOString()
      }))

    addFiles(newFiles);
    setNewFileName('');
  }

  return(
    <div className={styles.addFile}>

      <Textarea
        className={styles.name}
        placeholder="Enter a new filename..."
        onKeyDown={onEnterPress}
        value={newFileName} 
        onChange={(e) => setNewFileName(e.target.value)}  
      />

      <div className={styles.checkboxes + " " + styles.addButton}>
        <button
          name="addTrackNotesBtn"
          className="addRevisionBtn btn-flat"
          onClick={addFile}>
          +
        </button>
      </div>

    </div>
  )
}

const FileHeader = ({ showCheckboxes }) => (
  <div className={styles.fileRow + " " + styles.header}>
    <div className={styles.name}>Filenames:</div>

    {showCheckboxes && (
      <div className={styles.checkboxes}>
        <div className={styles.checkbox}>Cur.</div>
        <div className={styles.checkbox}>Ref.</div>
      </div>
     )}

  </div>
)



const FileInput = ({ files, updateFiles, showCheckboxes }) => {

  const updateFile = (updatedFile,id) => {
    if(updatedFile.name === ''){
      files.splice(id,1)
    }else{
      files.splice(id,1,updatedFile)
    }
    updateFiles(files)
  }

  return (
    <div className={styles.fileInput + (!showCheckboxes ? ` ${styles.stems}` : '')}>
      

         <FileHeader {...{showCheckboxes}}/>

         {files.map((file, index) => (
            <FileItem 
              id={index} 
              key={index} 
              file={file} 
              updateFile={updateFile} 
              showCheckboxes={showCheckboxes}
            />
          ))}

         <AddFileInput addFiles={(newFiles) => updateFiles([...files, ...newFiles])} />

     </div>
  )
}

export default FileInput;