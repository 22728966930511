import React, { useState, useEffect } from 'react';
import { withRouter } from '../Hooks';

import { 
  getAuth,
  signInWithEmailAndPassword, 
  verifyPasswordResetCode,
  sendPasswordResetEmail,
  confirmPasswordReset
} from "firebase/auth";

import app from '../config/config';

import '../styles/Login.css';


const auth = getAuth(app);


function getParameterByName(name, url) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}


const ResetPassword = (props) => {

    const params = props.router.location.search

    const code = getParameterByName("oobCode",params)
    let invalidCode = false
    if  (code === "" || code === null)
    {
      invalidCode = true;
    }

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const [codeError, setCodeError] = useState(invalidCode ? "Invalid Code parameter - please contact trackinfo@4elementsmusic.com" : null)
    const [loginError, setLoginError] = useState('')

    const [isResettingPassword, setIsResettingPassword] = useState(false)
    const [loading, setLoading] = useState(false)

    const [newPasswordSet, setNewPasswordSet] = useState(false)
    // const [code, setcode] = useState(code)
    const [newEmailSent, setNewEmailSent] = useState(false)

    useEffect(() => {
      console.log("reset password - init")
      if(props.authenticated){
        signOut(auth).then(function() {})
      }

      if(codeError === null){
        verifyPasswordResetCode(auth, code)
            .then(function(email) {
              // Display a "new password" form with the user's email address
              // console.log("code ok")
            })
            .catch(function() {
              // Invalid code
              console.log("bad code")
              setCodeError("This link has expired.")
            })
      }

    },[props.authenticated])


    const handleLoginSubmit = async (event) => {
      setLoading(true)
      
      event.preventDefault();
      confirmPasswordReset(auth, code, password)
      .then(function() {
        // Success
        console.log("password set")
        setNewPasswordSet(true)
      })
      .catch(function(e) {
        // Invalid code
        console.log(e)
      })
    }


    const handleResetPasswordSubmit = () => {
      sendPasswordResetEmail(auth, email).then(function() {
        // Email sent
        setLoginError("Please check your email for a link to reset your password.");
        setNewEmailSent(true)
      }).catch(function(e){
         setLoginError(e.message);
         setNewEmailSent(false)
      })
    }




  return(
      <div className="loginContainer">
          <h1 
            style={{
              fontWeight: '300', 
              letterSpacing: '2px',
              margin:"30px 0px"
            }}
          >
            4EM Composer Portal
          </h1>

          <div className="loginBox">

          {!newPasswordSet ? (
            <form 
              method="post" 
              onSubmit={(password === confirmPassword) && handleLoginSubmit}
            >
              {codeError ? (
                !newEmailSent ? (
                  <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around'}} className="loginInputContainer">

                    <div className="loginErrorMsg" style={{marginBottom: '20px', width: "75%"}}>{codeError}</div>
                    <div style={{fontSize: '14px', marginBottom: '6px'}}>Enter your email below for a new link:</div>
                    <div className="loginInputContainer" style={{width: '90%'}}>
                      <input
                        name="email"
                        className="inputLogin" 
                        placeholder="Email*"
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <div className="loginButtonsList">
                        <div className="loginButtonRow">
                          <button
                            onClick={handleResetPasswordSubmit}
                            className="loginSubmit"
                          >
                            {!newEmailSent ? "RESET PASSWORD" : "DONE"}
                          </button>
                        </div>

                        <div className="loginErrorMsg">{loginError}</div>
                      
                      </div>
                    </div>
                  </div>

              ) : (
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around'}} className="loginInputContainer">
                  <div className="loginErrorMsg" style={{color: "#1d252c", marginBottom: "10px"}}>
                    All done!
                  </div>
                  <div className="loginErrorMsg">{loginError}</div>
                 </div>
              )
            ):(
              <div className="loginInputContainer">
                <div style={{marginBottom: '30px'}}>Enter a new password:</div>

                <input 
                  name="password"
                  type="password"
                  className="inputLogin" 
                  placeholder="New Password*"
                  autoComplete="off"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />

                <div style={{marginBottom: '30px'}}>
                  Confirm password:
                </div>

                <input 
                  name="confirmPassword"
                  type="password"
                  className="inputLogin" 
                  placeholder="Confirm Password*"
                  autoComplete="off"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />

                <div className={newEmailSent ? "loginErrorMsg loginErrorBold" : "loginErrorMsg"}>
                  {loginError}
                 </div>

                {password !== confirmPassword && (confirmPassword !== "") && (
                  <div className="loginErrorMsg">
                    Passwords do not match, please check that both passwords match.
                  </div>
                )}

                <div className="loginButtonsList">
                  <div className="loginButtonRow">
                    {(password === confirmPassword) && (password !== '') ? 
                      <input
                        disabled={password !== confirmPassword}
                        type="submit"
                        value="SUBMIT"
                        className="loginSubmit"
                        />
                     : null}
                  </div>
                </div>
              </div>
            )}
          </form>
        ):(
          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', height:"70%"}} className="loginInputContainer">
            <div style={{}}>Your password has been set.</div>
            <div className="loginLink" onClick={() => props.router.navigate("/login")}>Click here to login</div>
          </div>
        )}
      </div>
      <div className="spacer"></div>
    </div>
  )
}


export default withRouter(ResetPassword);