import React, { useState } from 'react';
import { Link, Navigate, useLocation } from "react-router-dom";
import { withRouter } from '../Hooks';

import { ReactSVG } from 'react-svg';

import fb from '../config/config';

import { getAuth, signOut } from "firebase/auth";

import MainMenuPopup from './MainMenuPopup';

import styles from './AppHeader.module.scss'

import '../styles/App.css'

const auth = getAuth(fb);



const HeaderItem = ({children}) => <div className={styles.headerItem}>{children}</div>


const AppHeader = ({ isAdmin, authenticated, email }) => {
	const [ showMenu, setShowMenu ] = useState(false)

	const location = useLocation();
	const { pathname } = location;

	if(pathname.includes("/player/")){
		return null
	}

	const logoutUser = () => {
		signOut(auth).then(() => { 
			setShowMenu(false) 
		  	return( <Navigate to="/login" /> )
		})
	}

	return(
		<div className={styles.appHeader}>

		    <HeaderItem>
		    	{isAdmin ? (
		    		<div className={styles.burger + ` ${showMenu && styles.menuOpen}`}>
		    			<ReactSVG
		    			 	src={process.env.PUBLIC_URL + './svgs/hamburger.svg'}
		    			 	renumerateIRIElements={false}
		    			 	className={styles.hamburgerButton}
		    			 	onClick={() => setShowMenu(!showMenu) }
		    			/>
		    			{showMenu ?  
		    			  <MainMenuPopup  
		    			    logout={logoutUser}
		    			    closePopup={() => setShowMenu(!showMenu) }  
		    			  />  
		    			  : 
		    			  null  
		    			}
		    		</div>
		    	) : null}
		    </HeaderItem>


		  	<div className={styles.headerMiddle}>
		  	  	<Link to='/'>
		  	    	<img src="logo.png" alt="4EM Portal" className={styles.logo}/>
		  	   </Link>
		  	</div>


			<HeaderItem>
				{authenticated ? (<>
					<a className={styles.logout} onClick={logoutUser}>
					  LOGOUT
					</a>
	
					<br/>
	
					<div className={styles.email}>
					  {email || (null)}
					</div>
				</>) : (<HeaderItem/>)}
			</HeaderItem>

		</div>
	)
}

export default AppHeader;