import React, { useState, useEffect, useRef } from 'react';
import axios from '../../axios'

import '../../styles/ExportFilesPopup.css'

const CreateRequestListPopup = (props) => {
	const [ title, setTitle ] = useState("")
	const [ number, setNumber ] = useState("")

	const createNewRequest = async () => {
		const newRequest = {
			request_number: number,
			title: title,

			due_date: "",
			body: "",
			is_visible: false,
			show_due_date: false
		}

		const result = await axios.post('/open_requests',{
			...newRequest,
			secret: "viva43l3m3nts!"
		});

		props.closePopup()
	}

	const CustomDateInput = ({ value, onClick }) => (
	    <input style={{  padding: '3px 4px', height: 20}} 
	    	onChange={null} 
	    	onClick={onClick} 
	    	value={value} 
	    	placeholder="Click to pick due date..." 
	    	readOnly
	    	/>
	  );

	return (
		<div className='popup'>  
			<div className='popup_inner'>  
				<h1 style={{margin: '6px 0px'}}>
					Create New Request:
				</h1>  

				<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%'}}>
					<div className='filePopupList requestForm'>

						<div className='requestFormInput'><span className="requestLabel">Request #: </span>
							<input type="text" 
								placeholder="eg. ####"
								value={number} 
								onChange={(e) => setNumber(e.target.value.toString())}
							/>
						</div>

						<div className='requestFormInput'><span className="requestLabel">Title: </span>
							<input type="text" 
								placeholder="Enter title..."
								value={title} 
								onChange={(e) => setTitle(e.target.value)}
							/>
						</div>

					</div>
				</div>

				{(number !== "") & (title !== "") ? 
					<button id='download'onClick={createNewRequest} className='trackButton btn-flat'>Create Request</button> 
				: null}
				 
				<div id='closePopup' onClick={props.closePopup}>&#10005;</div>  
			</div>  
		</div>  

	)
}

export default CreateRequestListPopup;