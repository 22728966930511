import React, { useState, useEffect } from 'react';
import { useList } from 'react-firebase-hooks/database';

import fb from '../../config/config';
import { getDatabase, ref, get, set, child, orderByChild, query, equalTo, onValue, push, update } from "firebase/database";

import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions'

import ComposerSubmitPopup from './ComposerSubmitPopup';
import ComposerSelectFormType from './ComposerSelectFormType';
import ComposerInfoForm, { ComposerNotes } from './ComposerInfoForm';
import ComposerSubmit from './ComposerSubmit';
import ComposerPreviousUploads from './ComposerPreviousUploads';
import SubmitButtonBar from './SubmitButtonBar';
import PreviousUploads from './PreviousUploads'

import Header from './Header'

import { BLANK_TRACK } from '../../Constants'

import { withRouter, useComposerOptions } from '../../Hooks';

import { genericDateString } from '../../Helpers'

import styles from './ComposerForm.module.scss'


const stemPrefixes = [' stem', '-stem','.stem', '_stem']
const soundDesignPrefixes = [' sd', '-sd','.sd', '_sd']


// adjust existing array of trackFiles, updating isCurrent/isReference for old files and adding new files
function updateTrackFiles(oldFiles, newFiles){
  const updatedFiles = (Array.isArray(oldFiles) ? oldFiles : [])
                        .map(f => ({
                          ...f,
                          isCurrent: false,
                          isReference: f.isCurrent ? true : false
                        }))
  updatedFiles.push(...newFiles)
  return updatedFiles
}


const db = getDatabase(fb)
const functions = getFunctions(fb)
// connectFunctionsEmulator(functions, "localhost", 5001);


const ComposerForm = ({composer, isAdmin, envRefs, email, currentUser}) => {
  const userUID = currentUser.uid

  const { 
    loading: composerLoading, 
    objects: composerObjects, 
    names: composerNames 
  } = useComposerOptions({ name: composer, userUID })

  const userRef = ref(db,"users/" + userUID) 
  const tracksRef = child(ref(db), envRefs[0])
  const cTracksRef = query(tracksRef, orderByChild('trackSubmittedBy'), equalTo(userUID))

  const [ snapshots, loading, error ] = useList(cTracksRef);

  const [ popupSubmit, setPopupSubmit ] = useState( false )

  const [ uploadingFiles, setUploadingFiles ] = useState([])

  const [ selectedTrack, setSelectedTrack ] = useState(null)
  const [ previousFiles, setPreviousFiles ] = useState([])

  const [ createTrack, setCreateTrack ] = useState({
    ...BLANK_TRACK, 
    trackComposer: composer
  })

  const [ updateTrack, setUpdateTrack ] = useState(null)

  const [ notes, setNotes ] = useState('')
  const [ submissionType, setSubmissionType ] = useState( 'list' )

  const [ submitLoading, setSubmitLoading ] = useState(false)

  const [ formErrors, setFormErrors ] = useState(true)
  const [ showFormErrors, setShowFormErrors ] = useState(false)

  const [ submissionTitle, setSubmissionTitle ] = useState('')

  const [ totalPercent, setTotalPercent ] = useState(0)

  const [ titleExists, setTitleExists ] = useState( false )

  const filesAdded = uploadingFiles.length > 0;


  useEffect(() => {
    if(!composerLoading){
      setCreateTrack({
        ...BLANK_TRACK, 
        trackComposer: composerNames[0],
        trackFee: composerObjects[composerNames[0]].defaultFee,
        trackSubmissionType: composerObjects[composerNames[0]].defaultPaymentType
      })
    }
  },[composerLoading])


  useEffect(() => {
    const setUpdatingTrack = async () => {
      try{
        const snap = await get(child(ref(db), envRefs[0] + "/" + selectedTrack))
        setUpdateTrack(snap.val())
        setSubmissionType('update')

        if(Array.isArray(snap.val().trackFiles))
          setPreviousFiles(snap.val().trackFiles.reverse())
      }catch(e){
        console.error(e)
      }
    }

    if(selectedTrack !== null)
    {
      if(selectedTrack === "pre-existing"){
        setSubmissionType('quick')
        setPreviousFiles([])
      }else{
        setUpdatingTrack()
      }
    }

  },[selectedTrack])

  useEffect(() => {
    if(!filesAdded){
      setShowFormErrors(false)
    }
  },[filesAdded])


  useEffect(() => {
    if(uploadingFiles.length > 0){
      window.addEventListener('beforeunload', keepOnPage);
    }else{
      window.removeEventListener('beforeunload', keepOnPage);
    }
    return () => {
      window.removeEventListener('beforeunload', keepOnPage);
    }
  },[uploadingFiles])


  useEffect(() => {
    if(submissionType === 'create')
    {
      if(
          createTrack.trackTitle !== '' &&
          createTrack.trackKey !== '' &&
          createTrack.trackBPM !== '' &&
          createTrack.trackTimeSig !== '' &&
          createTrack.trackLibrary !== '' &&
          !titleExists
      ){
        setFormErrors(false)
      }else{
        setFormErrors(true)
      }
    }
    else if(
      ((submissionType === 'update') && (updateTrack !== null)) || 
      ((submissionType === 'quick'))
    )
    {
      setFormErrors(false)
    }
  },[createTrack,updateTrack,titleExists,submissionType])


  const keepOnPage = (e) => {
    var message = 'Warning!\n\nNavigating away from this page will delete you\'re current submission.\nAre you sure you want to leave?.';
    e.returnValue = message;
    return message;
  }


  const submit = async () => {
    if(formErrors){
      setShowFormErrors(true)
      return
    }

    let dateString = genericDateString()
    var timeNow = new Date();

    setPopupSubmit(true)
    setSubmitLoading(true)

    const datedFiles = [] // non-stem files (track files, versions, etc)
    const stemFiles = []  // stem files ONLY
    const soundDesignFiles = []  // stem files ONLY

    // sort uploaded files into datedFiles and stemFiles
    uploadingFiles.forEach(f => {
      const newFile = {
        ...f,
        isCurrent: true,
        isReference: false,
        date: timeNow.toISOString(),
        id: f.id,
        name: f.name,

        awsKey: f.key
      }

      const checkSoundDesignFiles = createTrack.trackLibrary === "Arketype" || (updateTrack && updateTrack.trackLibrary === 'Arketype')

      if(stemPrefixes.some(v => f.name.toLowerCase().includes(v))){
        stemFiles.push(newFile)
      }else if(
        checkSoundDesignFiles &&
        soundDesignPrefixes.some(v => f.name.toLowerCase().includes(v))
      ){
        soundDesignFiles.push(newFile)
      }else{
        datedFiles.push(newFile)
      }
    })

    const onlySoundDesign = stemFiles.length === 0 && datedFiles.length === 0 && soundDesignFiles.length > 0
    try{
      // create
      //   should create a track record using input fields and BLANK_TRACK
      //   post as new track to realtime db
      //   get uid to add to user
      if(submissionType === 'create'){
        // push new track to 'tracks' realtime DB
        const newTrackRef = push(tracksRef)
        const composerDefaultInfo = composerObjects[createTrack.trackComposer]

        // new track record
        const newTrack = {
          ...createTrack,

          id: newTrackRef.key,

          trackFiles: datedFiles,
          trackStemFiles: stemFiles,
          trackSoundDesign: soundDesignFiles,
          trackNotes: `COMPOSER NOTES ${dateString}\nMM: ${createTrack.trackBPM}\nKEY: ${createTrack.trackKey}\nTime Sig.: ${createTrack.trackTimeSig}\n\n"${notes}"\n\n`,
          trackTimeAdd: timeNow.toISOString(),
          trackTimeMod: timeNow.toISOString(),
          trackSubmittedBy: userUID,
          trackKeywords: createTrack.trackInstruments.map(instrument => instrument.id),

          trackFee: composerDefaultInfo['defaultFee'] || '',
          trackSubmissionType: createTrack.trackLibrary === 'Arketype' 
            ? 'Upfront Fee - Arketype Trailer'
            : composerDefaultInfo['defaultPaymentType']
              ? composerDefaultInfo['defaultPaymentType']
              : 'Standard Spec' 
        }

        // set new track record in 'tracks' DB
        await set(newTrackRef, newTrack)

        // push new track record ID to the user's submitted tracks
        await push(child(userRef,"submittedTracks"), {trackUID: newTrack.id})
        updateUser(newTrack.id, datedFiles, stemFiles, soundDesignFiles, true, newTrack);
      }
      else if(submissionType === 'update')
      {
        // update
        //   ONLY update track files, date modified, append user's notes to track notes
        const trackUpdates = {}

        trackUpdates['/trackResponded'] = false
        trackUpdates['/trackTimeMod'] = timeNow.toISOString()
        trackUpdates['/trackNotes'] = `${updateTrack.trackNotes}\n\nCOMPOSER NOTES ${dateString}\n\n"${notes}"\n\n`

        // adjust existing array of trackFiles, updating isCurrent/isReference for old files and adding new files
        trackUpdates['/trackFiles'] = updateTrackFiles(updateTrack.trackFiles, datedFiles)

        // append new stem files
        trackUpdates['/trackStemFiles'] = updateTrackFiles(updateTrack.trackStemFiles, stemFiles)

        // append new sound design files
        trackUpdates['/trackSoundDesign'] = updateTrackFiles(updateTrack.trackSoundDesign, soundDesignFiles)

        // Only update track status if this is not a Sound Design ONLY submission
        if(!onlySoundDesign){
          // updating track status relies on if track "Accepted"
          trackUpdates['/trackStatus'] = updateTrack.trackStatus.includes("Accepted")
                                          ? 'Accepted, Assets Submitted'
                                          : 'Up For Review'
        }

        if(soundDesignFiles.length > 0){
          trackUpdates['/trackSoundDesignReceived'] = true;
        }

        await update(child(tracksRef, updateTrack.id), trackUpdates)

        const trackRecord = {...updateTrack, trackNotes: notes}
        updateUser(updateTrack.id, datedFiles, stemFiles, soundDesignFiles, false, trackRecord);
      }
      else if(submissionType === 'quick')
      {
        // pre-existing
        //   only update user
        const trackRecord = {
          trackTitle: "Pre-Existing Title", 
          trackComposer: composerNames[0],
          trackNotes: notes
        }
        updateUser(0, datedFiles, stemFiles, soundDesignFiles, false, trackRecord)
      }
    }catch(e){
      console.error(e)
    }
  }


  const updateUser = async (trackUID, datedFiles, stemFiles, soundDesignFiles, isNewTrack, trackRecord) => {
    try{
      var moveUploadsToDropbox = httpsCallable(functions, 'moveUploadsToDropbox');

      await moveUploadsToDropbox({
          trackUID: trackUID,

          trackTitle: trackRecord.trackTitle, 
          trackNotes: trackRecord.trackNotes,
          composer: trackRecord.trackComposer,
          files: [...datedFiles, ...stemFiles, ...soundDesignFiles], 
        
          isNewTrack: isNewTrack,

          trackRecord: trackRecord,

          bucket: '4elementsmusic', 
          dbname: envRefs[0],
      })

      let userPreviousFiles = (await get( child(ref(db),"users/" + userUID + "/uploadedFiles") )).val() || []
      
      if(!Array.isArray(previousFiles))
        previousFiles = []
      
      const newFiles = [...datedFiles, ...stemFiles, ...soundDesignFiles].map(f => ({...f, title: trackRecord.trackTitle}))
      const userFiles = [ ...previousFiles, ...newFiles ]
      
      await set(child(ref(db),"users/" + userUID + "/uploadedFiles"), userFiles)

      setSubmitLoading(false)
      setSubmissionTitle(trackRecord.trackTitle)
    }catch(e){
      console.error(e)
    } 
  }


  const trackChanged = (updates) => {
    if(submissionType === "create"){
      setCreateTrack({
        ...createTrack,
        ...updates
      })
    }else{
      setUpdateTrack({
        ...updateTrack,
        ...updates
      })
    }
  }


  const cancelSubmission = () => {
    if(filesAdded){
      if (!window.confirm("Do you really want to cancel your current submission?")) {
        return
      }
    }
    uploadingFiles.forEach(f => {
      var deleteUpload = httpsCallable(functions, 'deleteUpload');
      const s3DeleteParams = {
        key: f.key
      }
      deleteUpload(s3DeleteParams)
        .then()
        .catch(e => {
           console.error(e)
        });
    })
    clearForm();
  }


  const closePopup = () => {
    clearForm();
    setPopupSubmit(false)
  }


  const clearForm = () => {
    setSubmissionType("list")
    setCreateTrack({
      ...BLANK_TRACK, 
      trackComposer: composerNames[0]
    })
    setUpdateTrack(null)
    setSelectedTrack(null)
    setPreviousFiles([])
    setNotes('')
    setUploadingFiles([])

    setShowFormErrors(false)
    setFormErrors(false)
    setTotalPercent(0)
  }


  const showComposerInfoForm = (filesAdded && (submissionType === "create")) || (filesAdded && (submissionType === "update") && (updateTrack))


  return(
    <div className={styles.composerForm}>
      <div className={`${styles.composerFormMain} ${submissionType === 'list' && styles.list}`} > 
        <div className={styles.formContainer}>  

          <Header 
            name={composerNames[0]}
            submissionType={submissionType}
          />
  
          <ComposerSelectFormType
            {...{
              snapshots, 
              selectedTrack, setSelectedTrack, 
              submissionType, setSubmissionType
            }}
          />
  
          {submissionType !== 'list' ? (
            <div className={styles.mainForm}> 
              <ComposerSubmit 
                composer={composer}
                uploadingFiles={uploadingFiles}
                setUploadingFiles={setUploadingFiles}
                setTotalPercent={setTotalPercent}
              >
  
                {(submissionType === "update") && (previousFiles.length > 0) ? (
                  <PreviousUploads previousFiles={previousFiles} />
                ): null}
  
              </ComposerSubmit>
  
              {showComposerInfoForm ? (
                <ComposerInfoForm 
                  {...{
                    submissionType, 
                    notes, setNotes, 
                    titleExists, setTitleExists, 
                    composerNames, 
                    composerObjects, 
                    showFormErrors
                  }}
                  thisTrack={submissionType === "create" ? createTrack : updateTrack}
                  updateTrack={trackChanged}
                />
              ) : null}
  
            </div>
  
          ) : (
  
            <ComposerPreviousUploads 
              uid={userUID} 
              snapshots={loading ? [] : snapshots} 
              loading={loading}
            />
  
          )}

          {filesAdded ? <ComposerNotes {...{notes, setNotes}}/> : null}
  
        </div>
      </div>
        
      {(submissionType !== 'list') &&
        <SubmitButtonBar {...{ submit, totalPercent, cancelSubmission, formErrors, showFormErrors }} />
      }
    
      {popupSubmit &&  
        <ComposerSubmitPopup  
          loading={submitLoading}
          submissionTitle={submissionTitle}
          newFiles={uploadingFiles || []}
          closePopup={closePopup}  
        />  
      }
  
    </div>
  )
}
export default withRouter(ComposerForm);