import React, { useState, useEffect } from 'react';

import { withRouter } from '../../Hooks';


function str_pad_left(string,pad,length) {
    return (new Array(length+1).join(pad)+string).slice(-length);
}

const AudioPlayer = (props) => {
	const [duration, setDuration] = useState(null)

	setTimeout(() => {
	  var audio = document.getElementById("mp3")
	  var time = audio.duration
	  var minutes = Math.floor(time / 60);
	  var seconds = Math.floor(time - (minutes * 60));
	  var formatted = minutes + ":" + str_pad_left((seconds).toString(),'0',2)
	  setDuration(formatted)
	},100)

	return(
		<audio id="mp3" controls autoPlay> 
      <source 
        src={encodeURI(`https://4elementsmusic.s3.amazonaws.com/audio/mp3/${props.track}.mp3`)} 
        type="audio/mpeg"
      />
    </audio>
	
	)
}


const PlayerPage = ({ router }) => {
		const track = router.params.track

		return <AudioPlayer track={track} />
}

const PlayerPageRouter = withRouter(PlayerPage)

export { AudioPlayer, PlayerPageRouter };