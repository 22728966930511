import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import 'react-tabs/style/react-tabs.css';
import '../../styles/InstructionalPopup.css';
import '../../styles/ExportFilesPopup.css'

import TrackTagger from './TrackTagger'

const Popup = (props) => {
	const initSelected = []
	const composerSelected = []

	props.currentKeywords.forEach(k => {
		if(k.substring(0,2) !== "VL") composerSelected.push(k)
		else initSelected.push(k)
	})

	const [ selected, setSelected ] = useState( initSelected )


	useEffect(() => {
		props.updateKeywords(selected)
	},[selected])


	return (  
		<div className='popup'>  
			<div className='popup_inner' 
					style={{
						height: props.instrumentsOnly ? '80vh' : '90vh', 
						width: props.instrumentsOnly ? '70vw' : '90vw', 
						left: '2%', 
						right: '2%', 
						top: '2%', 
						bottom: '2%', 
						justifyContent: 'flex-start'
					}}
				>  
				<div 
					id='closePopup' 
					className="instructionsClose" 
					onClick={() => props.closePopup([...selected, ...composerSelected])}
					>
					&#10005;
				</div> 

			 	<TrackTagger 
			 		trackTitle={props.trackTitle}
			 		setSelected={(list) => setSelected(list)}
			 		instrumentsOnly={props.instrumentsOnly}
			 		currentKeywords={props.currentKeywords}
			 		closePopup={() => props.closePopup([...selected, ...composerSelected])}
			 		isPopup={true}
			 	/>
		
			</div>  
		</div>  
	);  
}

export default Popup;