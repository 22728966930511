import { withRouter } from '../Hooks';

import styles from './PageNotFound.module.scss'
    
const PageNotFound = (props) => (

  	<div className={styles.container}>

      <h1>404</h1>
      <br/>
      <div>Page not found.</div>
      <br/>

      <a onClick={() => props.router.navigate(-1)}>
        <div className={styles.go_back}>
          &#8592; Go back
        </div>
      </a>

  	</div>
);

export default withRouter(PageNotFound);