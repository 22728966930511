/*
* @Author: Joe Steccato
* @Date:   2023-04-07 10:59:34
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-05-09 17:10:33
*/

import ScaleLoader from 'react-spinners/ScaleLoader';


import styles from './LoadingBars.module.scss'

export const override = `
  display: block;
  margin: 0 auto;
  border-color: red;
  width: auto;
`;

const LoadingBars = ({loading, color="#e3e3e3"}) => (!loading ? null :

	  <div className={styles.loadingBars}>
	    <ScaleLoader
	      css={override}
	      sizeunit={"px"}
	      size={300}
	      color={color}
	      loading={loading}
	    />
	</div>

)

export default LoadingBars;