import React, { Component, useState, useEffect } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

import { withRouter } from '../../Hooks';
import TrackTagger from './TrackTagger';

const TrackTaggerPage = (props) => {
  const [ selected, setSelected ] = useState([])  

  return(
    <div className='track_tagger_page' 
      style={{
        height: props.instrumentsOnly ? '80vh' : '90vh', 
        width: props.instrumentsOnly ? '70vw' : '100vw', 
        left: '2%', 
        right: '2%', 
        top: '2%', 
        bottom: '2%', 
        justifyContent: 'flex-start'
      }}
    >  

      <TrackTagger
        trackTitle={props.showAudioPlayer ? props.match.params.track : null}
        instrumentsOnly={false}
        currentKeywords={[]}
        isPopup={false}
        setSelected={(list) => setSelected(list)}
      />
      
    </div>
  )
}

export default withRouter(TrackTaggerPage);