import React, { Component, useState, useEffect } from 'react';
import { withRouter } from '../../Hooks';

import ClipLoader from 'react-spinners/ClipLoader';
import { useList } from 'react-firebase-hooks/database';

import fb from '../../config/config';
import { getDatabase, ref, query } from 'firebase/database'
import { getFunctions, httpsCallable } from 'firebase/functions'

import EditUser from './EditUser'
import SearchBar from '../Common/SearchBar';

import LoadingBars, { override } from '../Common/LoadingBars'

import '../../styles/Users.css'

import styles from './Users.module.scss'


const db = getDatabase(fb)
const functions = getFunctions(fb)


const GOOGLE_DOC_LINK = "https://docs.google.com/document/d/1eKWY4k55hShnWK2DWXoJ3EIYYwucgKLEVN8h7jBLBag/edit?usp=sharing"


function filterUsers(fullUser,filter){
  const { user, userInfo } = fullUser
  const arr = [user.displayName, user.email, userInfo.name]
  if(userInfo.composerIDs){
    Object.keys(userInfo.composerIDs).forEach(k => {
      arr.push.apply(arr, [k, userInfo.composerIDs[k]])
    });
  }
  const lc = arr.join(" ").toLowerCase();
    let searchFilter = filter.toLowerCase();
    return lc.includes(searchFilter);
}

const User = (props) => {
  const { user, userInfo } = props.fullUser
  const [ isAdmin, setIsAdmin ] = useState(userInfo.isAdmin || false)
  const [ adminLoading, setAdminLoading ] = useState( false )

  const handleAdminChange = () => {
    if(window.confirm("Are you sure you want to change Admin status for '"+ user.displayName +"'?")){

      // update user
      setAdminLoading(true)

      var updateUserInfo = httpsCallable(functions, 'updateUserInfo');
      updateUserInfo({
          uid: user.uid,
          infoUpdates: {
            "/isAdmin": !isAdmin
          },
          userUpdates: false,
        })
        .then(result => {
          setIsAdmin(!isAdmin)
          setAdminLoading(false)
        })
        .catch(error => {
          console.error(error.message)
        })
      }
  }

  return(
    <div 
      key={user.uid} 
      className={styles.user + " " + (isAdmin ? styles.isAdmin : '')}
    >
      <div style={{width: '30%'}} className={styles.userListText}><b>{user.displayName}</b></div>
      <div style={{width: '30%'}} className={styles.userListText}>{user.email}</div>
      <div style={{width: '20%'}} className={styles.userListText}>
        {userInfo.composerIDs ? Object.keys(userInfo.composerIDs).length : ""}
      </div>

      <div style={{width: '10%', margin: 'auto auto'}}>
      {adminLoading ? (
          <ClipLoader
            css={override}
            sizeunit={"px"}
            size={10}
            color={'#1d252c'}
            loading={adminLoading}
          />

        ) : (
        <input 
          className="adminCheckbox"
          onChange={handleAdminChange}
          type="checkbox" 
          checked={isAdmin}/>
        )

      }
      </div>
      
      <div style={{width: '10%'}}><button className="editUserButton" onClick={props.setUserToEdit}>EDIT</button></div>
    </div>
  )
}

const SortArrow = ({ sortBy, name, sortDirection }) =>  sortBy === name ? 
  <span>{sortDirection ? <>&#9650;</> : <>&#9660;</>}</span> : null


const Users = (props) => {
  const [ users, setUsers ] = useState([])

  const [query, setQuery] = useState("");

  const [ uid, setUid ] = useState('')
  const [ isLoading, setIsLoading ] = useState(true)
  const [ isAdding, setIsAdding ] = useState(false)

  const vlComposersRef = ref(db,'vl_composers') // fb.database().ref().child("vl_composers")
  const [ snapshots, loading, error ] = useList(vlComposersRef);

  const [ sortBy, setSortBy ] = useState( 'displayName' )
  const [ sortDirection, setSortDirection ] = useState( true )

  const [ editingUser, setEditingUser ] = useState(null)

  const [ vlComposers, setVlComposers ] = useState([])


  const filtered = users.filter(u =>  filterUsers(u,query))
  filtered.sort((a, b) => {
    const sortKey = (sortBy === 'admin') ? 'userInfo.isAdmin' : `user.${sortBy}`;
    const aSortKey = (sortKey.includes('.')) ? sortKey.split('.').reduce((obj, key) => obj[key], a) : a[sortKey];
    const bSortKey = (sortKey.includes('.')) ? sortKey.split('.').reduce((obj, key) => obj[key], b) : b[sortKey];
    return (aSortKey || '').localeCompare(bSortKey || '');
  })

  if(sortDirection === false){
    filtered.reverse()
  }

  // get user list
  useEffect(() => {
    getUserList();
  },[])


  useEffect(() => {
    if(!loading){
      const vl_c = snapshots.map(s => ({ id: s.key, text: s.val().name }))      
      setVlComposers(vl_c)
    }
  },[snapshots,loading])


  const setNewSort = (column) => {
    if(column === sortBy)   setSortDirection(!sortDirection)
    else                    setSortBy(column)
  }


  const onSearchInput = (val) => {
    setQuery(val);
  }
  
  const onClearQuery = () => {
    setQuery('')
  }

  const getUserList = async () => {
    try{
      var listUsers = httpsCallable(functions, 'listUsers');
      const result = await listUsers()
      setIsLoading(false)
      setUsers(result.data)
    }catch(e){
      console.error(e)
    }

  }

  const handlePopupClose = (shouldRefresh) => {
    if(shouldRefresh) getUserList()
    setEditingUser(null)
  }


  const addUser = () => {
    setIsAdding(true)
    setEditingUser({
      user: {},
      userInfo: {}
    })
  }


  if(isLoading)
    return(
      <div>
        <div className="main">
           <LoadingBars loading={true}/>
       </div>
      </div>
   )


  return (
    <div  className={styles.content}>

      <div className={styles.header}>

        <div className={styles.headerLeft}></div>
        
        <div className={styles.headerMain}>
          <h1>Portal User Accounts</h1>

          <div style={{fontSize: "9px"}}>
            (These accounts are for logging into the portal.)
          </div>
          <a 
            href={GOOGLE_DOC_LINK}
            target="_blank" 
            rel="noopener noreferrer" 
            style={{color: "#ff0000"}}
          >
              Google Doc Instructions
            </a>
        </div>

        <div className={styles.headerRight}>
          <button 
            className={styles.editUserButton + " " + styles.addUserButton}
            onClick={addUser}
          >
            + Add User
          </button>
        </div>

      </div>
        
      <div className={styles.main}>
        <SearchBar 
          page={"requests"}
            query={query}
            onSearchInput={onSearchInput}
            onClear={onClearQuery}
            placeholder={"Search Users..."}
        />

        <div className={styles.listHeader}>
          <div 
            style={{width: '30%'}} 
            onClick={() => setNewSort('displayName')}>
              NAME <SortArrow name={'displayName'} sortDirection={sortDirection} sortBy={sortBy}/>
          </div>
          <div 
            style={{width: '30%'}} 
            onClick={() => setNewSort('email')}>
              EMAIL <SortArrow name={'email'} sortDirection={sortDirection} sortBy={sortBy}/>
          </div>
          <div 
            style={{width: '20%'}} 
            onClick={() => console.log()}>
              COMPOSER IDS 
          </div>

          <div 
            onClick={() => console.log()}
            style={{width: '10%'}}>
              ADMIN
          </div>
          <div 
            style={{width: '10%'}}>
              &nbsp;
          </div>

        </div>
        <div className={styles.listData}>
        {filtered.map(u => {
          return(
            <User 
                fullUser={u} 
                key={u.user.uid} 
                setUserToEdit={() => {
                setIsAdding(false)
                setEditingUser(u)
              }}
            />
          )
        })

        }
        </div>
        </div>
        {editingUser !== null ?  
          <EditUser 
            vlComposers={vlComposers}
            isAdding={isAdding} 
            fullUser={editingUser}
            closePopup={handlePopupClose}
          />  
          : 
          null  
        }
      </div>
  )
}

export default withRouter(Users);