/*
* @Author: Joe Steccato
* @Date:   2023-04-11 16:26:15
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-05-23 17:23:50
*/

import React from 'react'
import { ReactSVG } from 'react-svg';

import styles from './FormHeader.module.scss'

const LinkSVG = process.env.PUBLIC_URL + './svgs/link.svg'
const StarSVG = process.env.PUBLIC_URL + './svgs/star.svg'

const FormHeader = ({ 
	addTrack, 
	editMode, 
	exitEditMode, 
	newTrackID, 
	isStarred, 
	saved, 
	updateTrackFields, 
	setCopied 
}) => {

	const starTrack = () => updateTrackFields({ trackStar: !isStarred })

	return(
		<div>
		    <div className={styles.formHeader}>
		    	<h2>{editMode ? 'Update' : 'Add'} a Track:</h2>

		      	{editMode ? (

		      		<React.Fragment>
	
		      			<button 
		      			  className={"btn-flat " + styles.trackButton}
		      			  onClick={exitEditMode}>
		      			    &#8592; BACK
		      			</button>
	
		      			<div style={{opacity: 0}}>
		      				<ReactSVG
								src={LinkSVG} 
								renumerateIRIElements={false}
								wrapper="span"
								className={`${styles.linkButton} ${styles.svgButton}`}
							/>
						</div>
	
						<div onClick={starTrack}>
							<ReactSVG
								src={StarSVG} 
								renumerateIRIElements={false}
								wrapper="span"
								className={`${isStarred ? styles.isStarred : ''} ${styles.starButton}`}
							/>
						</div>
	
		      		</React.Fragment>
	
		      	):(
	
		      		<div className={styles.trackAddButtons}>
		      		  <button 
		      		    className={styles.trackButton + " btn-flat " + styles.mobileCancel}
		      		    onClick={exitEditMode}>
		      		      &#8592; CANCEL
		      		  </button>
		      		  <button 
		      		    className={`${saved ? '' : styles.updateButton} ${styles.trackButton} btn-flat`}
		      		    onClick={addTrack}>
		      		      + ADD
		      		  </button>
		      		</div>

		      	)}

		    </div>
		</div>
	)
}

export default FormHeader;