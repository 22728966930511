/*
* @Author: Joe Steccato
* @Date:   2023-10-10 14:28:50
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-12-04 10:47:06
*/
import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from '../../Hooks';
import axios from '../../axios'

import LoadingBars from '../Common/LoadingBars'

import { capitalize } from '../../Helpers'
import { format, parseISO } from 'date-fns'

import Dropdown from 'react-dropdown';

import UserInfo from './UserInfo';

import styles from './UserDetails.module.scss'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { DROPDOWN_OPTIONS } from '../../Constants'


const { WEBSITE_USER_OPTIONS } = DROPDOWN_OPTIONS


const UserLoading = () => <div className={styles.containerLoading}><LoadingBars loading={true} color={"#d3d3d3"} /></div> 

const NoResults = ({ children }) => <div className={styles.containerLoading}><div className={styles.no_activity}>{children}</div></div>

// all projects
const Projects = ({ userID }) => {
	const [ userProjects, setUserProjects ] = useState([])
	const [ loading, setLoading ] = useState(true)

	const filemakerURL = "fmp://a017715.fmphost.com/EOOE%20Catalog?script=Open%20From%20Browser&param="

	useEffect(() => {
		fetchProjects(userID)
	},[])

	// Fetch User's record for viewing/editing
	const fetchProjects = async (id) => {
		const result = await axios.get(`/projects/internal_all_user_projects?secret=@viva43l3m3nts!&user_id=${id}`);
		if(result.data.data || (result.data && result.data.length < 1)){
			setUserProjects([])
		}else{
			setUserProjects(result.data)
		}
		setLoading(false)
	}

	if(loading){
		return <UserLoading/>
	}

	if(userProjects.length < 1){
		return <NoResults>No projects.</NoResults>
	}

	return(
		<>
			<div className={styles.project_list}>
				{userProjects.map((a, i) => {
					return(
						<div key={`project_${i}`} className={styles.activity_row}>
							<div className={styles.project_link}>
								{a.title}

							</div>
							<div className={styles.track_count}>
								{a.track_count} tracks
								&nbsp;(<a
									target="_blank"
									href={`https://4elementsmusic.com/project?project_id=${a.id}`}
								>
									Website
								</a>)
								(<a
									target="_blank"
									href={`${filemakerURL}${[a.title, ...a.track_titles].join("%0A")}`}
								>
									Filemaker
								</a>)
							</div>
							
						</div>
					)
				})}
			</div>
		</>
	)
}

// all activity
const Activity = ({ userID }) => {
	const [ userActivity, setUserActivity ] = useState([])
	const [ loading, setLoading ] = useState(true)

	useEffect(() => {
		fetchActivity(userID)
	},[])

	// Fetch User's record for viewing/editing
	const fetchActivity = async (id) => {
		const result = await axios.get(`/users/get_user_activity?secret=@viva43l3m3nts!&user_id=${id}`);
		if(result.data.data && result.data.data.length < 1){
			setUserActivity([])
		}else{
			setUserActivity(result.data)
		}
		setLoading(false)
	}

	if(loading){
		return <UserLoading/>
	}else if(userActivity.length < 1){
		return <NoResults>No Activity.</NoResults>
	}

	return(
		<>
			<div className={styles.activity_list}>
				{userActivity.map((a, i) => {
					return(
						<div key={`project_${i}`} className={styles.activity_row}>
							<div className={styles.date}>{format(parseISO(a.date), 'M/d/yy h:mma')}</div>

							<div className={styles.description}>
								{a.type} 
							</div>

							<div className={styles.website_link}>
								{a.item_type}: <a href={a.website_link} target="_blank">
									{a.item_title}
								</a>
							</div>

							<div className={styles.download_link}>
								{a.download_link ? (
									<a 
										target="_blank"
										href={`https://4elementsmusic.com/download?file=${a.download_link}`}
									>
										{a.download_link}
									</a>
								) : ` ` }
							</div>
						</div>
					)
				})}
			</div>
		</>
	)
}




const USER_ATTRIBUTES = {
	text: [
		'firstName', // text
		'lastName', // text
		'email', // text
		'telephone', // text
		'extension', // text

		'company', // text
		'companyRole', // text

		'streetAddress', // text
		'city', // text
		'state', // text
		'postalcode', // text
		'country' // text
	],
	dropdown: [
		'default_format', // dropdown
		'download_limit', // dropdown
		'show_all_pros', // dropdown
		'show_pro_suspect', // dropdown
	],
	checkbox: [
		'confirmation_token', // LOCKED ?? just a is confirmed checkbox?
		'mailinglist', // checkbox
	]

}


// info about user (name, address, email, company)
const Info = ({ thisUser, setUser: setNewUser }) => {
	const [ user, setUser ] = useState(thisUser)
	const [ isSaved, setIsSaved ] = useState(true)

	const handleUserChanges = (attribute, value) => {
		setUser({
			...user,
			[attribute]: value
		})
		setIsSaved(false)
	}

	const onSave = async () => {
		const result = await axios.post(`/users/update_internal`, {
		  secret: "@viva43l3m3nts!",
		  id: user.id,
		  user
		});

		setNewUser(user)
		setIsSaved(true)
	}

	return(
		<>
		<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', overflowY: 'scroll'}}>

			<div style={{display: 'flex', flexDirection: 'column', width: 600}}>
				{USER_ATTRIBUTES.text.map((attr, i) => {
					return(
						<div className={styles.userDetail} key={i}>
							<div className={styles.label}>{capitalize(attr)}:</div>
							<div className={styles.value}>
							<input value={user[attr]} onChange={(e) => handleUserChanges([attr], e.target.value)}/>
							</div>
						</div>
					)
				})}
			</div>

			<div style={{display: 'flex', flexDirection: 'column', width: 200, justifyContent: "space-between"}}>
				<div className={styles.composer_pro}>
					Show All PROs
					<Dropdown
					  name="composer_pro"
					  className=""
					  options={WEBSITE_USER_OPTIONS.PRO} 
					  value={WEBSITE_USER_OPTIONS.PRO[user.show_all_pros]}
					  onChange={(e) => handleUserChanges("show_all_pros", WEBSITE_USER_OPTIONS.PRO.indexOf(e.value))} 
					/>
				</div>
				<div className={styles.composer_suspect}>
					Is PRO Suspect:
					<Dropdown
					  name="composer_suspect"
					  className=""
					  options={WEBSITE_USER_OPTIONS.SUSPECT} 
					  value={WEBSITE_USER_OPTIONS.SUSPECT[user.show_pro_suspect]}
					  onChange={(e) => handleUserChanges("show_pro_suspect", WEBSITE_USER_OPTIONS.SUSPECT.indexOf(e.value))} 
					/>
				</div>
				<div className={styles.composer_downloads}>
					Download Limit:
					<Dropdown
					  name="composer_downloads"
					  className=""
					  options={WEBSITE_USER_OPTIONS.DOWNLOAD_LIMIT} 
					  value={WEBSITE_USER_OPTIONS.DOWNLOAD_LIMIT[user.download_limit + 1]}
					  onChange={(e) => handleUserChanges("download_limit", WEBSITE_USER_OPTIONS.DOWNLOAD_LIMIT.indexOf(e.value) - 1)} 
					/>
				</div>
				<div className={styles.composer_downloads}>
					Download Format:
					<Dropdown
					  name="composer_downloads"
					  className=""
					  options={WEBSITE_USER_OPTIONS.DOWNLOAD_FORMAT} 
					  value={user.default_format}
					  onChange={(e) => handleUserChanges("default_format", e.value)} 
					/>
				</div>
				<div className={styles.composer_downloads}>
					<label>Mailing List:
					  <input 
					  	type="checkbox" 
					  	checked={user.mailinglist}
					  	onChange={(e) => handleUserChanges("mailinglist", !user.mailinglist)} 

					  />
					</label>
				</div>
			</div>
			
		</div>
		<div style={{color: "#999999", fontSize: 10}}>
			id: {user.id} 
			&nbsp;&nbsp;||&nbsp;&nbsp; 
			{user.confirmation_token ? "Account Not Confirmed" : "Account Confirmed"}
		</div>
		{!isSaved ? <button onClick={onSave}>Save Changes</button> : null}
		</>
		
	)
}


const UserDetails = ({ router }) => {
	const userID = router.params.id

	const [ savedUser, setSavedUser ] = useState(null)
	const [ user, setUser ] = useState(null)
	const [ isSaved, setIsSaved ] = useState(true)
	const [ loading, setLoading ] = useState(true)

	const [ tabIndex, setTabIndex ] = useState(0)

	if(!userID)
		router.navigate("/website-users")

	useEffect(() => {
		fetchUser(userID)
	},[])

	useEffect(() => {
	    const handleBeforeUnload = (e) => {
	      	if (!isSaved) {
	        	const message = 'You have unsaved changes. Are you sure you want to leave?';
	        	e.returnValue = message; // Legacy method for cross browser support
	        	return message;
	      	}
	    };

	    window.addEventListener('beforeunload', handleBeforeUnload);

	    return () => {
	      	window.removeEventListener('beforeunload', handleBeforeUnload);
	    };
	}, [isSaved]); // Dependency array ensures this effect runs only when isSaved changes

	const handleTabSelect = (index) => {
	  	if (!isSaved && (tabIndex == 0)) {
	  	  	const confirmLeave = window.confirm('You have unsaved changes. Are you sure you want to change tabs?');
	  	  	if (!confirmLeave) {
	  	    	return false; // Prevent tab change
	  	  	}
	  	  	setUser({...savedUser})
	  	  	setIsSaved(true)
	  	}
	  	setTabIndex(index); // Proceed with tab change
	  	return true;
	};

	// Fetch User's record for viewing/editing
	const fetchUser = async (id) => {
		const result = await axios.get(`/users/${id}`);
		setLoading(false)
		setUser(result.data.data.attributes)
		setSavedUser(result.data.data.attributes)
	}

	const onSave = async () => {
		const result = await axios.post(`/users/update_internal`,{
		  secret: "@viva43l3m3nts!",
		  id: user.id,
		  user
		});

		setUser(user)
		setSavedUser(user)
		setIsSaved(true)
	}

	const goBack = () => {
		// Check if there are unsaved changes
		if (!isSaved) {
		  	// Prompt the user and confirm if they really want to leave
		  	const confirmLeave = window.confirm('You have unsaved changes. Are you sure you want to leave?');
		  	if (!confirmLeave) {
		  	  	// If the user chooses not to leave, stop the navigation
		  	  	return;
		  	}
		}

		// If there are no unsaved changes, or the user confirms, proceed with navigation
		router.navigate("/website-users");
	}


	const deleteUser = async () => {
		const confirmDelete = window.confirm(`ARE YOU SURE YOU WANT TO DELETE THE USER "${savedUser.firstName} ${savedUser.lastName}"?`);
		if (!confirmDelete) {
		  	// If the user chooses not to leave, stop the navigation
		  	return;
		}

		const result = await axios.post(`/users/delete_user`, {
		  secret: "@viva43l3m3nts!",
		  id: user.id
		});

		router.navigate("/website-users");
	}

	if(loading || !user){
		return <UserLoading/>
	}

	return(
		<div className={styles.container}>
			<div className={styles.header}>
				<div className="requestSideButtons requestSideButtonsLeft">
					<button className="editUserButton addUserButton"
					  onClick={goBack}
					>
					  &#8592; All Users	
					</button>
				</div>
				<h1>
					{`${savedUser.firstName} ${savedUser.lastName}`}
				</h1>
				<div className="requestSideButtons">
					<button className="editUserButton addUserButton"
					  onClick={deleteUser}
					>
						🗑 Delete User
					</button>
				</div>	
			</div>


			<Tabs className={styles.tabs} selectedIndex={tabIndex} onSelect={handleTabSelect}>
			    <TabList>
			      	<Tab>Info</Tab>
			      	<Tab>Projects</Tab>
			      	<Tab>Activity</Tab>
			    </TabList>

			    <TabPanel>
			    	<UserInfo 
			    		{...{user, setUser, isSaved, setIsSaved, onSave}}
			    		buttonText={'Save Changes'}
			    		requiredFields={['email', 'firstName', 'lastName']}
			    	/>
			    </TabPanel>

			    <TabPanel>
			    	<Projects userID={userID} />
			    </TabPanel>

			    <TabPanel>
			    	<Activity userID={userID}/>
			    </TabPanel>
			</Tabs>
		</div>
	)
}

export default withRouter(UserDetails);