import React, { useState, useEffect } from 'react';
import Dropdown from 'react-dropdown';

import { DROPDOWN_OPTS } from '../../Constants';
import firebase from '../../config/config';

import InstructionalPopup from './InstructionalPopup'
import ExportFilesPopup from './ExportFilesPopup'
import ExportToCSV from './ExportToCSV'

import { exportForSession } from '../../Helpers'

import styles from './Sidebar.module.scss'


const DROPDOWN_SEARCH = ["SHOW ALL",...DROPDOWN_OPTS[0]]
const ARCHIVED_SEARCH = ["CURRENT", "ARCHIVED", "BOTH"]


const SideBarItem = ({children}) => <div className={styles.item}>{children}</div>


const Button = ({children, onClick}) => (
  <button className="btn-top btn-flat" onClick={onClick}>
    {children}
  </button>
)


const CounterItem = ({children}) => (
  <SideBarItem>
    <div className={styles.counter}>
      {children}
    </div>
  </SideBarItem>
)


const RespondedRadio = ({ responded, setResponded }) => {
  return ["All", "Responded", "Not Responded"]
    .map((option, index) => (
        <label className={styles.radioLabel} key={'radio_'+index}>
          <input 
            type="radio" 
            className={styles.radioButton}
            onChange={() => setResponded(index)}
            checked={responded === index}
          />
          {option}
        </label>
    ))
}


const OpenArrow = ({ onClick, open }) => (
  <div className={styles.openArrow}>
    <span onClick={onClick}>{!open ? `\u25BA` : `\u25C0`}</span>
  </div>
)


const SideBar = ({ filtered, tracks, status, setStatus, responded, setResponded, isArchive, selected }) => {
  const [ open, setOpen ] = useState( true )

  const [ showInstructionalPopup, setShowInstructionalPopup ] = useState( false )
  const [ numSubmissionFiles, setNumSubmissionFiles ] = useState(-1)

  // Export
  const [ showExportCSVPopup, setShowExportCSVPopup ] = useState( false )
  const [ popupExport, setPopupExport ] = useState( false )
  const [ exportType, setExportType ] = useState( '' )
  const [ exportFileList, setExportFileList ] = useState( [] )


  // Export Track Files for Logic Session
  const exportFilenames = (type) => {
    const fileNames = exportForSession(filtered, type)
    
    setPopupExport(true)
    setExportFileList(fileNames)
    setExportType(type)
  }

  const PopupMarkup = <>
    {showInstructionalPopup ?  
      <InstructionalPopup  
        closePopup={() => setShowInstructionalPopup(false)}  
      />  
      : null  
    }
    {showExportCSVPopup ?  
      <ExportToCSV  
        tracks={tracks}
        closePopup={() => setShowExportCSVPopup(false)}  
      />  
      : null  
    }
    {popupExport ?  
      <ExportFilesPopup  
        type={exportType}
        fileList={exportFileList}
        closePopup={() => setPopupExport(false)}  
      />  
      : null  
    }
  </>

  const mainStyle = isArchive ? {backgroundColor: "indianred"} : {}


	return (
		<div className={styles.sidebar + " " + (!open ? styles.closed : '' ) + " " + (selected ? styles.selected : '')} style={mainStyle}>
        {open ? (
          <>
             {isArchive ? 'ARCHIVE' : null}
             <SideBarItem>
               <Dropdown
                 name="trackStatus"
                 className={styles.dropdown}
                 options={DROPDOWN_SEARCH} 
                 value={status}
                 onChange={(e) => setStatus(e.value)}
               />
             </SideBarItem>

             <SideBarItem>
               <RespondedRadio
                 {...{responded, setResponded}}
               />
             </SideBarItem>

             <CounterItem>
               <div># Search Results:</div>
               <div className={styles.numContainer}>
                 <div className={styles.counterNum + ' ' + styles.blue}>
                   {filtered.length}
                 </div>
               </div>
             </CounterItem>

             <CounterItem>
               <div>Total:</div>
               <div className={styles.numContainer}>
                 <div className={styles.counterNum + ' ' + styles.green}>
                   {tracks.length}
                 </div>
               </div>
             </CounterItem>

            <SideBarItem>   
               <Button onClick={() => exportFilenames('Listening')}>
                 Export Listening Filenames
               </Button>

               <Button onClick={() => exportFilenames('Asset-Review')}>
                 Export Asset Review
               </Button>

               <Button onClick={() => exportFilenames('Mastering')}>
                 Export Mastering Filenames
               </Button>

               <Button onClick={() => setShowExportCSVPopup(true)}>
                 Export CSV
               </Button>

               <Button onClick={() => setShowInstructionalPopup(true)}>
                 Songbook Help
               </Button>

             </SideBarItem>

             {PopupMarkup}

          </>

          ) : 
          null
        }
        
        {/*<OpenArrow {...{open, onClick: () => setOpen(!open)}}/>*/}

      </div>
	)
}
export default SideBar;
