import React, { useState, useEffect } from 'react';
import { withRouter } from '../../Hooks';

import SubmissionsPausedPopup from './SubmissionsPausedPopup'
import ComposerForm from './ComposerForm';

import '../../styles/App.css'
import '../../styles/Home.css';
import styles from './ComposerRoute.module.scss'


const ComposerRoute = (props) => {
  const composer = props.currentUser['displayName'] || ""
  const email = props.currentUser['email']

  const [showPausedPopup, setShowPausedPopup] = useState(false)

  return(
    <div className={styles.outer}>
      <div className={styles.mainComposer}>    
        <div className={styles.composerSubmissionView}>

          {composer !== null && (
            <ComposerForm
              composer={composer}
              isAdmin={props.isAdmin}
              envRefs={props.envRefs}
              email={email}
              currentUser={props.currentUser}
            />
          )}

        </div>
      </div>

      {showPausedPopup && (
        <SubmissionsPausedPopup setShowPausedPopup={setShowPausedPopup} />
      )}

    </div>
  );
}

export default withRouter(ComposerRoute);