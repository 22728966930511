/*
* @Author: Joe Steccato
* @Date:   2023-05-16 14:43:15
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-05-16 15:40:17
*/

import { useState, useEffect } from 'react'

import fb from '../config/config'
import { getDatabase, get, child, ref } from 'firebase/database'


const db = getDatabase(fb)


const DEFAULT_OBJECT = (name) => ({
	[name]: {
		name,
		defaultFee: '',
		defaultPaymentType: 'Standard Spec',
		isArk: false
	}
})


export const useComposerOptions = ({ name, userUID }) => {
	const [ loading, setLoading ] = useState( true )
	const [ objects, setObjects ] = useState( DEFAULT_OBJECT(name) )
	const [ names, setNames ] = useState( [name] )

	useEffect(() => {
	  	const fetchComposerOptions = async () => {
	  		try{
	  			// fetch list of composer IDs from user account
	  			const cArr = []
	  			const userComposerIDs = (await get(child(ref(db), "users/" + userUID + "/composerIDs"))).val()
	  			if(!userComposerIDs){
	  				setLoading(false)
	  				return
	  			}
	
	  			const cObjs = {}
	  			const promises = Object.keys(userComposerIDs)
	  				.map(VLID => get(child(ref(db), "vl_composers/" + VLID)))
	
	  			const promiseResults = (await Promise.all(promises))
	  			promiseResults.forEach(r => cObjs[r.val().name] = {...r.val(), VLID: r.key});
	  			setObjects(cObjs)
	  			
	
	  			const composerNames = Object.keys(cObjs).sort((a,b) => a.length - b.length);
	  			setNames(composerNames)
	  		}catch(e){
	  			console.error(e)
	  		}
	  		setLoading(false)
	  	}
	  	
	  	fetchComposerOptions()
	},[])

	return { loading, objects, names }
}

