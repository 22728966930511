import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from "react-router-dom";
import { ReactSVG } from 'react-svg';
import Dropdown from 'react-dropdown';
import { useList } from 'react-firebase-hooks/database';

import fb from '../../config/config';
import { 
	getDatabase, 
	ref, 
	set, 
	orderByChild, 
	query as dbQuery 
} from "firebase/database";

import { withRouter } from '../../Hooks';

import { DROPDOWN_OPTS } from '../../Constants';
import SearchBar from '../Common/SearchBar';

import LoadingBars from '../Common/LoadingBars'

import styles from './ComposerListVL.module.scss'

// import '../../styles/ComposerList.css';


const db = getDatabase(fb)


function filterComposers(c,filter=""){
  const arr = [c.name, c.defaultFee, c.id, c.defaultPaymentType]
  const lc = arr.join(" ").toLowerCase();
    let searchFilter = filter.toLowerCase();
    return lc.includes(searchFilter);
}


const composerSort = (a, b, sort) => {
	if ((a[sort] || "") < (b[sort] || "")) {
	  return -1;
	}
	if ((a[sort] || "") > (b[sort] || "")) {
	  return 1;
	}
	return 0;
}

const ComposerListVL = (props) => {
	const composerRef = dbQuery(ref(db,'vl_composers'), orderByChild('name')) // fb.database().ref().child('vl_composers')

	const [ snapshots, loading, error ] = useList( composerRef );
	const [ filtered, setFiltered ] = useState ([])
	const [ composers, setComposers ] = useState( [] )
	const [ selectedRow, setSelectedRow ] = useState( -1 )
	const [ sortBy, setSortBy ] = useState( 'name' )
	const [ sortDirection, setSortDirection ] = useState( false )

	const [ addingComposer, setAddingComposer ] = useState( false )

	const [query, setQuery] = useState("");

	const composerRefs = useRef([]);


	// Load from Realtime DB
	useEffect(() => {
		if(loading) return

		const arr = snapshots
			.filter(s => !s.val().isCollaborator)
			.map(s => ({ isArk: false, ...s.val(), id: s.key }))
		setComposers(arr)

		if(query !== "")
		  onSearchInput(query)
		else
		  setFiltered(arr)
	},[loading, snapshots])


	// Sort-By Changed
	useEffect(() => {
		const sortComposers = [...composers];
		let sort = sortBy;
		if(sort === "fee") sort = "defaultFee"
		if(sort === "type") sort = "defaultPaymentType"
		if(sort === "ark") sort = "isArk"

		sortComposers.sort((a,b) => composerSort(a, b, sort));

		setComposers(sortComposers);
		setSortDirection(false)

		if(query !== "")
		  onSearchInput(query)
		else
		  setFiltered(sortComposers)
	},[sortBy])


	// Sort-Direction Change
	useEffect(() => {
		const sortComposers = [...composers].reverse()
		setComposers(sortComposers)
		if(query !== "")
		  onSearchInput(query)
		else
		  setFiltered(sortComposers)
	},[sortDirection])


	// User Input
	const handleUserInput = (e,name,i) => {
	  const newComposers = [...filtered];
	  newComposers[i] = { ...filtered[i], [name]:  e.target.value};
	  setFiltered(newComposers)
	}

	const setComposerSubType = (v,i,idx) => {
		const newComposers = [...filtered];
		newComposers[i] = { ...filtered[i], defaultPaymentType: v};
		pushComposerUpdates(i,'defaultPaymentType',v,idx);
		setFiltered(newComposers);
	}

	const setComposerLibrary = (field,v,i,idx) => {
		const newComposers = [...filtered];
		newComposers[i] = { ...filtered[i], [field]: v };
		pushComposerUpdates(i,field,v,idx);
		setFiltered(newComposers);
	}

	const pushComposerUpdates = async (i,child,value,idx) => { 
		const childToUpdate = ['vl_composers',i,child].join("/")
		try{
			await set(ref(db, childToUpdate), value)
			
			const newComposers = [...filtered];
			newComposers[idx] = { ...filtered[idx], [child]: value};

			setFiltered(newComposers)
		}catch(e){
			console.log(e)
		}
	}

	const setNewSort = (column) => {
		if(column === sortBy) 	setSortDirection(!sortDirection)
		else 					setSortBy(column)
	}

	const onKeyPressHandler = (e,i,child,value,idx) => {
		if(e.key === 'Enter'){
			pushComposerUpdates(i,child,value,idx)
		}
	}

	const onSearchInput = (val) => {
	  const newFiltered = [...composers].filter(r => filterComposers(r,val));
	  setFiltered(newFiltered);
	  setQuery(val);
	}

	const onClearQuery = () => {
	  setQuery('')
	  setFiltered(composers)
	}

	const SortArrow = () => (!sortDirection ? <span>&#9650;</span> : <span>&#9660;</span>)


	return(
		<div className={styles.main}>
		
		<div className={styles.composer_page_header}>
			<h3 className={styles.header}>Composer VLID</h3>
		</div>

		{loading ? <LoadingBars /> :(

				<div className={styles.composer_list_main}>
					<SearchBar 
					  page={"requests"}
					  query={query}
					  onSearchInput={onSearchInput}
					  onClear={onClearQuery}
					  placeholder={"Search Composers..."}
					/>

					<div className={styles.composer_row + " " + styles.header_row}>
						<div 
							className={`${styles.composer_id} ${(sortBy==='id' ? styles.selected_sort_column : "")}`} 
							onClick={() => setNewSort('id')}>
								VLID # {sortBy === 'id' ? <SortArrow/> : null }
						</div>
						<div 
							className={`${styles.composer_name} ${(sortBy==='name' ? styles.selected_sort_column : "")}`} 
							onClick={() => setNewSort('name')}>
								COMPOSER NAME {sortBy === 'name' ? <SortArrow/> : null }
						</div>
						<div 
							className={`${styles.composer_fee} ${(sortBy==='fee' ? styles.selected_sort_column : "")}`} 
							onClick={() => setNewSort('fee')}>
								FEE {sortBy === 'fee' ? <SortArrow/> : null }
						</div>
						<div 
							className={`${styles.composer_paytype} ${(sortBy==='type' ? styles.selected_sort_column : "")}`} 
							onClick={() => setNewSort('type')}>
								PAYMENT TYPE {sortBy === 'type' ? <SortArrow/> : null }
						</div>
						<div 
							className={`${styles.composer_ark} ${(sortBy==='ark' ? styles.selected_sort_column : "")}`} 
							onClick={() => setNewSort('ark')}>
								ARK {sortBy === 'ark' ? <SortArrow/> : null }
						</div>

						<div 
							className={`${styles.composer_4em} ${(sortBy==='4em' ? styles.selected_sort_column : "")}`} 
							onClick={() => setNewSort('4em')}>
								4EM {sortBy === '4em' ? <SortArrow/> : null }
						</div>

					</div>

					<div className={styles.composer_item_container}>

					{(!loading && snapshots) && filtered.map((c,i) => {
						const rowColor = i % 2;
						const isDisabled = false;
						return(
							<div key={c.id}  ref={el => composerRefs.current[c.id] = el} 
								className={`${styles.composer_row} ${styles.not_header} ${styles["row_color_"+rowColor]}`}
								onClick={()=>setSelectedRow(c.id)}
							>
								<div className={styles.composer_id}>
									{c.id}
								</div>
								<input name="composer_name"
									onKeyPress={(e) => onKeyPressHandler(e,c.id,"name",e.target.value,i)}
									onBlur={(e) => pushComposerUpdates(c.id,"name",e.target.value,i)}
									disabled={true} 
									className={styles.composer_name} 
									value={c.name}
									onChange={(e) => handleUserInput(e,"name",i)}
								/>
								<input name="composer_fee"
									onKeyPress={(e) => onKeyPressHandler(e,c.id,"defaultFee",e.target.value,i)}
									onBlur={(e) => pushComposerUpdates(c.id,"defaultFee",e.target.value,i)}
									disabled={isDisabled} 
									className={styles.composer_fee} 
									value={c.defaultFee} 
									placeholder="-"
									onChange={(e) => handleUserInput(e,"defaultFee",i)}
								/>
								<div className={styles.composer_paytype}>
									<Dropdown
									  name="composerDefaultSubmissionType"
									  className=""
									  options={DROPDOWN_OPTS[2]} 
									  value={c.defaultPaymentType || "-"}
									  onChange={(e) => setComposerSubType(e.value, c.id, i)} 
									/>
								</div>
								<div className={styles.composer_ark}>
									<input type="checkbox" checked={c.isArk} 
									  onChange={() => setComposerLibrary('isArk',!c.isArk, c.id, i)} 
									  style={{width: '20px'}}
									/>
								</div>

								<div className={styles.composer_4em}>
									<input type="checkbox" checked={c.is4em} 
									  onChange={() => setComposerLibrary('is4em',!c.is4em, c.id, i)} 
									  style={{width: '20px'}}
									/>
								</div>
							</div>
						)}
					)}
					</div>
				</div>
			)}
		</div>
	)
}

export default withRouter(ComposerListVL);