import React, { useState, useEffect } from 'react';

import { PopupContainer } from '../Common/Popup'

// import '../../styles/ExportFilesPopup.css'


const ExportFilesPopup = (props) => {
	const [list, setList] = useState(props.fileList)
	const [total, setTotal] = useState(props.fileList.length - 1)
	const [selected, setSelected] = useState(0)

	const [ lastSelected, setLastSelected ] = useState(-1)

	const toggleFile = (e,i,v) => {
		const newFile = [...list[i]]
		newFile[0] = v

		const newList = [...list]
		let newT = 0
		if(selected > 0){
			newList.forEach(f => {
				if(f[3])
					f[0] = v;
				if(f[0])
					newT += 1
			})
		}
		else
			newT = total + (v ? 1 : -1)

		newList[i] = newFile

		setTotal(newT-1)
		setList(newList)
	}

	const selectFile = (e,i,v,shift=false) => {
		e.preventDefault()
		e.stopPropagation()

		const newFile = [...list[i]]
		const newList = [...list]
		let newS = selected


		if(shift)
		{
			if( i > lastSelected){
				let stop = !v ? i-1 : i;
				for(var j=lastSelected; j<=i; j++){
					const newF = newList[j]
					if(newF[3] != v)
					{
						newF[3] = v
						newList[j] = newF
						newS += (v ? 1 : -1)
					}
				}
			}
			else{
				let stop = !v ? lastSelected-1 : lastSelected;
				for(var j=i; j<=lastSelected; j++){
					const newF = newList[j]					
					if(newF[3] != v)
					{
						newF[3] = v
						newList[j] = newF
						newS += (v ? 1 : -1)
					}
				}
			}
			if(!v)
			{
				newFile[3] = true
				newList[i] = newFile
				// newS += 1
			}
			newS = 0;
			newList.forEach(f => {
				newS += (f[3] ? 1 : 0)
			})
		}else{
			newFile[3] = v
			newList[i] = newFile
			newS += (v ? 1 : -1)
		}

		setSelected(newS)
		setLastSelected(i)
		setList(newList)
	}

	const downloadExportList = () => {
	  let date = new Date().toISOString().substr(2,8).replace(/-/g, "");
	  let output = ""
	  let output_txt = props.type + ' Session ' + date + '.txt'

	  list.forEach((file) => {
	  	if(file[0]){
	  		output += file[1]+'\n'
	  	}
	  })

	  var a = document.createElement('a');
	  a.setAttribute('href', 'data:text/plain;charset=utf-u,'+encodeURIComponent(output));
	  a.setAttribute('download', output_txt);
	  a.click()
	  props.closePopup();
	}

	const handleItemClick = (file,e,index) => {
		if(e.target.type === "checkbox") return

		if(e.metaKey) {
			// click with CMD-- select one additional item
			selectFile(e,index,!file[3])
		}else if(e.shiftKey){
			// click with SHIFT-- select group of items
			selectFile(e,index,!file[3],true)
		}else{
			// click without CMD-- deselect items
			const newList = [...list]
			let newS = 0
			newList.forEach((f,i) => {
				if(f[3])
					f[3] = false
				if(i === index)
				{
					newS = 1;
					f[3] = true;
				}
			})
			setSelected(1);
			setLastSelected(index)
			setList(newList)
		}
	}

	return (  
		<PopupContainer> 
			<div 
				className='popup_inner' 
				style={{maxHeight: '70vh'}}
			>  
				<h1>Export for 4EM {props.type} Session:</h1>  

				{total === 0 ? (

						<div style={{fontSize: 12, margin: "60px 0"}}>No files are available for this session type.</div>


					):(
					<>

				<button 
					id='download' 
					className='trackButton btn-flat' 
					onClick={downloadExportList}
				>	
					Export {total} Tracks
				</button>  

				<div className='filePopupList'>
					{list.map((file,i) => {
						const warning = file[1] ? !(file[1].includes('.wav') || file[1].includes('.aif')) : false;
						return(
							<div 
								key={i} 
								className={"fileItem " + (warning ? 'mp3File' : '')}
							>
								{i !== 0 ? (
									<div 
										className={"exportFileItem " + (file[3] ? 'exportFileItemSelected ' : ' ') + (i===lastSelected ? "exportFileLastSel" : "")} 
										onClick={(e) =>  handleItemClick(file,e,i) }
									>
										<input 
											type="checkbox" 
											style={{width: '20px'}}
											id={"track"+i.toString()} 
											name={"track"+i.toString()} 
											checked={file[0]}
											onChange={(e) => toggleFile(e,i,e.target.checked)}
										/>
										<label className={"noselect"} htmlFor={"track"+i.toString()}>{file[1]}</label>
									</div>
								):(
									<span className="fileInfo"><div>{file[1]}</div></span>
								)}
								
							</div>
						)
					})}
				</div>
				<div style={{fontSize: 12}}>{selected > 0 ? (selected + " selected.") : ("CMD or SHIFT click to select multiple.")}</div>

				</>
				)}

					<div id='closePopup' onClick={props.closePopup}>&#10005;</div>  


			</div>  
		</PopupContainer>
	);  
}

export default ExportFilesPopup;