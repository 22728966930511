import React, { useState, useEffect } from 'react';
import Switch from "react-switch";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import styles from './ComposerSelectFormType.module.scss'


function compare( a, b ) {
  if ( a.dateMod < b.dateMod ){
    return -1;
  }
  if ( a.dateMod > b.dateMod ){
    return 1;
  }
  return 0;
}


const ComposerUpdateDropdown = (props) => {
  const previousTracks = props.tracks.map(t => ({
      label: t.val().trackTitle,
      dateMod: t.val().trackTimeMod,
      value: t.val().id
  }))

  previousTracks.sort(compare).reverse().unshift({label: 'Assets for Pre-Existing 4EM Titles', value: 'pre-existing', date: 0})

  return(
    <div className="composerPreviousTitles" style={{maxWidth: '500px', width: '100%', margin: 'auto'}}>
      <Dropdown
        name="previousTrack"
        options={previousTracks} 
        value={props.selectedTrack}
        onChange={(e) => props.setSelectedTrack(e.value)}
        placeholder="- Select previous track -"
       />
    </div>
  )
}

const ComposerSelectFormType = (props) => {
  const buttonStyle = props.submissionType === 'create' ? styles.create : styles.update

  const createButtonStyle = [styles.typeButton, props.submissionType === 'create' && styles.selected ].join(' ')
  const updateButtonStyle = [styles.typeButton, props.submissionType === 'update' && styles.selected ].join(' ')

  return (
    <div className={styles.container}>
      <div className={styles.buttonRow}>

         <div 
           onClick={() => props.setSubmissionType('create')}
           className={createButtonStyle}
         >
             New Submission
         </div>

         <div className={styles.or}>
           or
         </div>

         <div onClick={() => props.setSubmissionType('update')}
           className={updateButtonStyle}
         >
           Update Submission
         </div>

       </div>
      
      <div className={styles.dropdown}>
        {((props.submissionType !== "update") && (props.submissionType !== 'quick')) ? (
            null
          ) : (
            <ComposerUpdateDropdown 
              tracks={props.snapshots} 
              selectedTrack={props.selectedTrack} 
              setSelectedTrack={(trackname)=>props.setSelectedTrack(trackname)}
            />
        )}
      </div>
    </div>
    )
}

export default ComposerSelectFormType;