
const TYPES = {
	"Listening": ['Up For Review', "Up For Review (Relisten)"],
	"Mastering": ['Accepted, Assets Reviewed', 'Accepted, Assets Requested (Rush)'],
	"Asset-Review": ['Accepted, Assets Submitted', "Ingested, Assets Submitted)"]
}


export const exportForSession = (tracks, type) => {
	let date = new Date().toISOString().substr(2,8).replace(/-/g, "");
	const header = [true, `${type} Session ${date}`, null] 

	const files = tracks.map(snap => {
		const track = snap.val()
		if(
			TYPES[type].indexOf(track.trackStatus) < 0 ||
			!track.trackFiles ||
			(type === 'Mastering' && track.trackSubmissionType.toLowerCase().includes("arketype"))
		)
			return []

		return track.trackFiles.map(file => {
			return file.isCurrent || file.isReference
				? [true,file.name,track.trackComposer,false] 
				: []
		})
	})
	return [header,...files.flat(1).filter(f => f.length > 0).reverse()]
}