/*
* @Author: Joe Steccato
* @Date:   2023-07-17 11:20:40
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-07-17 12:09:21
*/

import styles from './KeywordCategory.module.scss'


const KeywordCategory = (props) => {
  
  const { keyword: k, selected } = props;


  if (!k || k.subcat === 'Key') return null;

  return (
    <div className={styles.container}>

      <b><u>{k.subcat}</u></b>

      {k.items.map((item) => {
        if (item.keyword === 'Specify BPM') return null;

        const isSelected = selected.includes(item.id);

        return (
          <div
            key={item.id}
            className={`${styles.keywordItem} ${isSelected ? styles.selectedItem : ''}`}
            onClick={() => props.toggleKeyword(item.id, isSelected)}
          >
              <div className={styles.keywordText}>
              	{isSelected ? <>&#10003;</> : null} {item.keyword}
              </div>
          </div>
        );
      })}
    </div>
  );
};


export default KeywordCategory;