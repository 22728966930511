import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import Files from "react-butterfiles";

import FileUpload from './FileUpload'

import '../../styles/Submit.css'

import styles from './ComposerSubmit.module.scss'


const ACCEPTED_FILE_TYPES = [
	"audio/wav", 
	"audio/x-wav", 

	"sound/aiff",
	"audio/aiff",
	"audio/x-aiff",
	
	"audio/mpeg",

	"audio/midi" , 
	"audio/x-midi"
]



function formatDate(isoString){
	var d = new Date(isoString)
	return d.toLocaleDateString("en-US")
}


const FailedFilesPopup = ({ failedFiles, close }) => failedFiles 
	? <div className="failedFilesPopup">
			<div className="failedFilesHeader">
				<div className="failedIcon">&#9888;</div>
				<div className="failedText">
					{failedFiles.length} of your files {failedFiles.length === 1 ? "is" : "are"} not supported.
				</div>
			
				<div className="failedFilesPopupCancelButton" onClick={close}>&#10006;</div>
			</div>

			<ul className="failedFilesList">
				{failedFiles.map((failedFile,i) => 
					<li key={i}>
						<b>{failedFile.name}:</b>&ensp;{failedFile.reason}
					</li>
				)}
			</ul>

		</div>
	: null



const DropZoneInstructions = ({ inDropZone, browseFiles }) => (
	<div className="myDropZoneInstructions" style={{opacity: (inDropZone ? "1.0" : "0.6" )}}>
		{inDropZone ? (
			<span>Drop files here to upload...</span>
		):(
			<div>
				<span>Drag & Drop Files to Start Upload</span><br/>
				<span>or <a onClick={browseFiles}>Click Here</a> to Upload.</span>
			</div>
		)}
	</div>
)


const UploadReminders = () => (
	<div 
		className="uploadReminders"
		style={{
			fontWeight: '300', 
			fontSize: '12px', 
			margin: '8px auto 30px auto', 
			letterSpacing: '1px',
			color: '#757575'
		}}
	> 
		<div>You can upload up to a total of 2GB at once per submission.</div>
		<div><b>Please submit 24-bit 48khz WAV files.  Zips are not supported.</b></div>
		<div><b>Please include "stem" in your stem filenames.</b></div>
	</div>
)


const ComposerSubmit = ({ composer, uploadingFiles, setUploadingFiles, setTotalPercent, children }) => {
	const [ errors, setErrors ] = useState([])
	const [ failedFiles, setFailedFiles ] = useState(null)
	const [ inDropZone, setInDropZone ] = useState(false) 


	const cancelUpload = (i,id, didDelete) => {
		const newFiles = [...uploadingFiles]
		const fileToRemove = newFiles.splice(i,1)
		setUploadingFiles(newFiles)
	}


	const filesAdded = (files) => {
		const filesToSet = []
		files.forEach((f,i) => {
			const thisFile = {...f, isDuplicate: false, loaded: 0, complete: false }
			thisFile.key = 'public/submissions/'+composer+'/'+thisFile.id+'/'+thisFile.name
			filesToSet.push(thisFile)
		})

		setUploadingFiles( [...uploadingFiles, ...filesToSet] );
	}

	const handleDragLeave = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setInDropZone(false)
	}

	const handleDragOver = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setInDropZone(true)
	}

	const setUploaded = (loaded, i, id) => {
		const newUploadingFiles = [...uploadingFiles]
		newUploadingFiles[i].loaded = loaded;
		newUploadingFiles[i].complete = loaded === 100;

		let total = newUploadingFiles
									.map(file => (file.loaded))
									.reduce((a, b) => a + b, 0)

		let percent = total/(newUploadingFiles.length * 100)

		setTotalPercent(Math.round(percent*100))
		setUploadingFiles(newUploadingFiles)
	}

	const onSuccess = ( files ) => {
		const success = []
		const failedFiles = []
		files.forEach(f => {
			if(ACCEPTED_FILE_TYPES.indexOf(f.type) === -1){
				failedFiles.push({name: f.name, reason: 'Invalid file type.'})
			}else if(f.name.indexOf('/') > -1){
				failedFiles.push({name: f.name, reason: "'/' are not allowed in filenames."})
			}else if(f.name.indexOf("#") > -1){
				failedFiles.push({name: f.name, reason: "'#' are not allowed in filenames."})
			}else{
				success.push(f)
			}
		})
		if(success.length > 0)
			filesAdded(success)
		
		setFailedFiles(failedFiles.length ? failedFiles : null)
		setInDropZone(false);
	}

	const onError = ( errors ) => { 
		setInDropZone(false);
		setErrors( errors ) ;
	}

	return(
		<div className="submitContainer">
      <div className="submitBox">

      	{children}
        
        <div className="submitInputContainer">
        	<Files
			  	  multiple={true} 
			  	  maxSize={"2gb"}
			  	  multipleMaxSize={"2gb"}
			  	  accept={[]}
			  	  onSuccess={onSuccess}
			  	  onError={onError}
			  	  base64={true}
					>
			    	{({ browseFiles, getDropZoneProps, getLabelProps }) => (
			        <div>
			          <div {...getDropZoneProps({ className: "myDropZone" })}
			            style ={{
			            	border: (inDropZone ? "2px solid #1d252c" : "2px solid #00000000")
			            }}
			            onDragOver={handleDragOver}
			            onDragLeave={handleDragLeave}			        	
			          >

			            {uploadingFiles.length > 0 ? (
			            	<ol className="fileOrderedList">
			            	  {uploadingFiles.map((file,i) => (
			            	  	<FileUpload 
			            	  		key={file.id} 
			            	  		file={file} 
			            	  		composer={composer} 
			            	  		cancelUpload={(didDelete) => cancelUpload(i,file.id,didDelete)}
			            	  		setUploaded={(loaded) => setUploaded(loaded,i,file.id)}
			            	  	/>
			            	  ))}
			            	  
			            	  <button 
			            	  	className="addFilesButton"
			            	  	onClick={browseFiles} 
			            	  >
			            	  	ADD FILES
			            	  </button>
			            	</ol>

			            ) : <DropZoneInstructions {...{ inDropZone, browseFiles }} />}
	 
			          </div>

			          <UploadReminders />
			            
			        </div>
			    	)}	
					</Files>
        </div>
      </div>
      
      <div className="spacer"></div>

      <FailedFilesPopup {...{ failedFiles }} close={() => setFailedFiles(0)}/>

    </div>
	)
}

export default ComposerSubmit;