import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Redirect, Link } from "react-router-dom";
import { withRouter } from '../../Hooks';

import { COLOR_KEY } from '../../Constants'

import 'react-tabs/style/react-tabs.css';
import '../../styles/InstructionalPopup.css';
import '../../styles/ExportFilesPopup.css'

const Popup = (props) => {

	const close = () => {
		props.closePopup();
	}

	const logout = () => {
		props.logout();
	}

	return (  
			<div className='menu_list' 
				style={{
					position: 'absolute',
					top: 'calc(100vh - 40px)', 
					width: '100%', 
					height: '40px',
					backgroundColor: "#ffcf43", 
					borderColor: "#1d252c", 
					pointerEvents: 'auto',
					zIndex: '100',
					overflowY: 'hidden',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center'
				}}>  
				
				<div 
					style={{
						color: '#1d252c',
						fontWeight: '300'
					}}
				>
					{props.message}
				</div>
				<div 
					id='closePopup' 
					style={{fontSize: "18px", top: '10px'}} 
					onClick={props.closePopup}
				>
					&#10005;
				</div>  
			</div>  

	);  
}

export default withRouter(Popup);