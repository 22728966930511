import React, { useRef, useState, useEffect, useMemo } from 'react';

import { COLOR_KEY, getColor } from '../../Constants'
import { filterTracks } from '../../Helpers'

import { useList } from 'react-firebase-hooks/database';

import fb from '../../config/config';
import { getDatabase, ref, set, remove, push, orderByChild, query as queryDB, child } from "firebase/database";

import SideBar from './SideBar';
import Track from './Track';

import SearchBar from '../Common/SearchBar';
import PopupMessage from '../Common/PopupMessage'
import LoadingBars from '../Common/LoadingBars'

import '../../styles/App.css'

import styles from './TrackList.module.scss'


const db = getDatabase(fb)


const TrackList = ({ selected, setSelected, envRefs, isArchive, setLoading }) => {
  const tracksRef = queryDB(ref(db, envRefs[0]), orderByChild('trackTimeMod'))

  // React Firebase Hooks list of Tracks
  const [ tracks, loading, error ] = useList(tracksRef);

  // Search Criteria
  const [ query, setQuery ] = useState( '' )
  const [ status, setStatus ] = useState( 'SHOW ALL' )
  const [ responded, setResponded ] = useState( 0 )
  const [ showArchived, setShowArchived ] = useState( 'BOTH' )

  const [ selectedIndex, setSelectedIndex ] = useState(-1)

  // Popups
  const [ popupMessage, setPopupMessage ] = useState( null )

  const trackListRef = useRef(null)

  // Memoize filtered tracks array
  const filtered = useMemo(() => {
    if (query === '' && responded === 0 && status === 'SHOW ALL') {
      return [...tracks].reverse()
    } else {
      return tracks.filter(snap => filterTracks(snap.val(),query,responded,status)).reverse()
    }
  }, [query, responded, status, tracks])


  useEffect(() => {
    if(popupMessage){
      setTimeout( function(){ 
        setPopupMessage(null)
      }, 3000);
    }
  },[popupMessage])

  useEffect(() => {
    if(!loading)
      setLoading(false)
  },[loading])

  // Clear Search Filters
  const onClearQuery = () => {
    setQuery('')
    setStatus( 'SHOW ALL' )
    setShowArchived( 'BOTH' )
    setResponded( 0 )
  }

  // Set Selected Track
  const setSelectedTrack = (trackId, index) => { 
    setSelected(trackId);
    setSelectedIndex(index)
  } 


  const handleScroll = (pixels) => {
    if (trackListRef.current) {
      const { scrollTop } = trackListRef.current;

      trackListRef.current.scrollTo({
        top: scrollTop + pixels,
        behavior: 'smooth'
      });
    }
  };


  // Move selected track up/down by 1
  const trackNav = (e, direction) => {
    e.preventDefault()
    e.stopPropagation()

    let newIndex = selectedIndex + direction
    let newSelection = filtered[newIndex]
    if(newSelection !== undefined){
      setSelectedTrack(newSelection.key, newIndex);
      handleScroll(91 * direction)
    }else{
      console.log("can't move in that direction")
    }
  }

  // Handle Key Down, which is used for User navigating through track list
  const handleKeyDown = (e) => {
    switch(e.keyCode){
      case 40:   trackNav(e,1);   break;
      case 38:   trackNav(e,-1);  break;
      default:  /* console.log(e.keyCode + " pressed") */  break;
    }
  }

  // Array of Track components
  const trackListMarkup = filtered.map((track, index) => (
        <Track 
          key={"track_"+track.key} 
          index={index}
          track={track.val()}
          envRefs={envRefs}
          isArchive={isArchive}
          setSelectedTrack={setSelectedTrack}
          selected={track.key === selected}
          setPopupMessage={setPopupMessage}
        />
    )
  )

  return (
    <>
      <SideBar
        {...{        
          filtered, tracks,
          status, setStatus,
          responded, setResponded,
          isArchive,
          selected
        }}
      />

      <div className={styles.trackList + " " + (selected ? styles.selected : styles.notSelected)}>

        <div className={styles.trackSearchContainer}>
          <SearchBar 
            query={query}
            onSearchInput={setQuery}
            onClear={onClearQuery}
            placeholder={false}
          />
        </div>

        <div 
          className={styles.tracksListContainer} 
          tabIndex="1" 
          onKeyDown={handleKeyDown}
          ref={trackListRef}
        >

          {loading ? (
              <LoadingBars color={"#1d252c"} loading={loading} />
            ) : (
               <> {trackListMarkup} </>
          )}
        </div>

      </div>

      {popupMessage && (
        <PopupMessage 
          message={popupMessage} 
          closePopup={() => setPopupMessage(null)}
        />
      )}
    </>
  )
}
export default TrackList;