import React, { useEffect, useState, useRef, useMemo } from 'react';
import { ReactSVG } from 'react-svg';

import fb from '../../config/config';
import { getDatabase, ref, set, remove, push, orderByChild, query as queryDB, child } from "firebase/database";

import { COLOR_KEY, getColor } from '../../Constants'

import styles from './Track.module.scss'

import { useWindowWidth } from '@react-hook/window-size'


function formattedDate(date){
	return (new Date(date).toLocaleDateString('en-us',{ dateStyle: 'short' }))
}


const TrackButtons = ( {onArchiveTrack, onRemoveTrack, whiteSVG} ) => (
	<div className={styles.trackButtons + " " + (whiteSVG ? styles.white : "")}>
	    <ReactSVG
	  		 src={process.env.PUBLIC_URL + './svgs/archive.svg'}
	  		 renumerateIRIElements={false}
	  		 className={styles.trackButton + " " + styles.archiveButton}
	  		 onClick={onArchiveTrack}
	  	/>
	  	<ReactSVG
	  	    src={process.env.PUBLIC_URL + './svgs/trash.svg'}
	  	    renumerateIRIElements={false}
	  	    className={styles.trackButton + " " + styles.removeButton}
	  	    onClick={onRemoveTrack}
	  	/>
	</div>
)

const db = getDatabase(fb)


function Track({ track, index, selected, setSelectedTrack, envRefs, isArchive, setPopupMessage }){	
	let textColor = "#000"
	let whiteSVG = false

	const childRef = ref(db,envRefs[0])
	const archiveRef = ref(db,envRefs[1])

	const trackStyle = getColor(track)
		
	if(trackStyle === "darkgray" || trackStyle === "darkgreen"){
		textColor = "#ffffff"
		whiteSVG = true
	}

	const outerStyle = {
		backgroundColor: COLOR_KEY[trackStyle], 
		color: textColor
	}

	const onRemoveTrack = async (e) => {
		e.preventDefault();
		e.stopPropagation();
        if (window.confirm('Are you sure you want to remove this track?')){
        	try{
        	  	await remove(child(childRef, track.id))
        	  	if(selected === track.id){
        	  	  setSelectedTrack(null, -1)
        	  	}
        	  	let popupMsg = `"${track.trackTitle}" removed!`
        	  	setPopupMessage(popupMsg)
        	}catch(e){
        	  	console.error(e)
        	}
        }
	}

	const onArchiveTrack = async (e) => {
		e.preventDefault();
		e.stopPropagation();
	      if (window.confirm('Are you sure you want to archive this track?')){
	      	try{

	      		const trackToArchive = {...track};
	      		let ref = push(archiveRef)

	      		await set(ref, {
	      		...trackToArchive,
	      		id: ref.key
	      		})

	      		await remove(child(childRef, track.id))
	      		if(selected === track.id){
	      		  setSelectedTrack(null, -1)
	      		}

	      		let popupMsg = `"${trackToArchive.trackTitle}" ${isArchive ? "un-archived" : "archived" }!`
	      		setPopupMessage(popupMsg)
	      	}catch(e){
	      	  console.error(e)
	      	} 
	      }
	}

	let latestFile = ''
	if(track.trackFiles){
		latestFile = track.trackFiles.length 
									? track.trackFiles[track.trackFiles.length-1].name 
									: ''
	}


 	const onlyWidth = useWindowWidth()
  	const isMobileSized = (onlyWidth < 1110) ? true : false;


	const SubmissionType = () => {
		const { trackSubmissionType, trackLibrary } = track

		if(['','Standard Spec'].indexOf(trackSubmissionType || '') > -1)
			return <div></div>

		const typeStyle = (trackLibrary === 'Arketype' || trackSubmissionType.includes('Arketype')) 
			? styles.isArk 
			: styles.submissionType

		return <div className={typeStyle}>{trackSubmissionType.replace(' Trailer', '')}</div>
	}


	return(
		<div 
			onClick={(e) => {setSelectedTrack(track.id,index)}}
			style={outerStyle} 
			className={styles.track + " " + (selected ? styles.selected : '')}
		> 
			<div className={styles.detailsStatus}>
				<div>{track.trackStatus}</div>
				<SubmissionType />
			</div>

			<div className={styles.titleComposer}>
				<div className={styles.title}>
					{track.trackTitle}
				</div>

				<div className={styles.composer}>
					{track.trackComposer}

					{track.trackCollaborator !== '' ? (
						<div><span className={styles.collab}>
							&nbsp;w/ {track.trackCollaborator}
						</span></div>
					) : null}
				</div>
			</div>

			{!isMobileSized && (
				<div className={styles.detailsFile}>
					<div className={styles.versionDesc}>
						{track.trackVersionDesc && (track.trackVersionDesc)} {(track.trackSoundDesign && track.trackSoundDesign.length > 0 ? "+SD" : "")}
					</div>
					<div>{latestFile}</div>
				</div>
			)}

			<div className={styles.detailsDate}>
				<TrackButtons {...{onArchiveTrack, onRemoveTrack, whiteSVG}} />

				<div className={styles.date}>
					Modified: {formattedDate(track.trackTimeMod)}
				</div>

				<div className={styles.date}>
					Added: {formattedDate(track.trackTimeAdd)}
				</div>
			</div>

		</div>
	)
}

function trackPropsEqual(prev, next){
	return prev.track.trackTimeMod === next.track.trackTimeMod
		&& prev.selected === next.selected
}

export default React.memo(Track, trackPropsEqual);