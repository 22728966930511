import React, { useState, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';
import { useList } from 'react-firebase-hooks/database';

import fb from '../../config/config';
import { getDatabase, ref, get, orderByChild, query, push, child } from "firebase/database";

import '../../styles/ComposerInput.css';

const db = getDatabase(fb)

const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

const AlbumInput = (props) => {
  const placeholder = props.placeholder
  const [ snapshots, loading, error ] = useList(query(ref(db, 'album_projects'), orderByChild('name')));

  const name = props.name

  const [ value, setValue ] = useState( props.value )
  const options = (loading || error) ? [] :  snapshots.map(s => s.val())
  const [ suggestions, setSuggestions ] = useState( [] )

  useEffect(() => {
    setValue(props.value)
  },[props.value])

  const onChange = (event, { newValue, method }) => {
    props.valueChanged(newValue)
    setValue(newValue)
  }

  const onBlur = (event) => { 
    props.onBlur(name, value) 
  }

  const getSuggestions = (value) => {
    const escapedValue = escapeRegexCharacters(value.trim());
    if (escapedValue === '') {
      return [];
    }
    const regex = new RegExp('^' + escapedValue, 'i');
    const newSuggestions = options.filter(o => regex.test(o.name));
    if (newSuggestions.length === 0) {
      return [
        { isAddNew: true }
      ];
    }
    return newSuggestions;
  }

  const getSuggestionValue = (suggestion) => {
    if (suggestion.isAddNew) {
      console.log(suggestion)
      push(child(ref(db), 'album_projects'), { name: value })      
      return value;
    }
    return suggestion.name;
  };

  const renderSuggestion = (suggestion) => {
    if (suggestion.isAddNew) 
      return <span>Add "{value}"</span>
    
    return suggestion.name;
  };
  
  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions( getSuggestions(value) )
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions( [] )
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    const newValue = suggestion.isAddNew ? value : suggestion.name
    props.setValue(newValue)
  };

  const inputProps = {
    placeholder,
    name,
    value,
    onChange,
    onBlur,
  };

  return (
     <Autosuggest 
        id={props.id}
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        onSuggestionSelected={onSuggestionSelected}
        inputProps={inputProps} 
      />
  )
}

export default AlbumInput;