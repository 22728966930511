import React, { useState, useEffect, useRef } from 'react';
import { ReactSVG } from 'react-svg';

import styles from './SearchBar.module.scss'


const SearchBar = (props) => {
	const [ query, setQuery ] = useState( props.searchQuery )
	const timeoutRef = useRef(null);
	const page = props.page || ""

	const onClearQuery = () => {
		setQuery( '' )
		props.onClear()
	}

	const handleKeyDown = (e) => {
		if(e.keyCode === 13){
			if(query !== ''){
				props.onSearchInput(query)
			}else{
				onClearQuery();
			}
		}else if((e.keyCode === 8) && (e.target.value === '')){
			onClearQuery();
		}
	}

	const performSearch = () => {
		if(query !== ''){
			props.onSearchInput(query)
		}else{
			onClearQuery();
		}
	}

	return(
		<div className={styles.searchBar}>
         	<input 
           		placeholder={props.placeholder || "Search Tracks..."}
           		value={query}
           		onChange={(e) => setQuery(e.target.value)}
           		onKeyDown={handleKeyDown}
           		onBlur={performSearch}
           	/>
          	<ReactSVG
          		src={process.env.PUBLIC_URL + './svgs/close.svg'}
          		renumerateIRIElements={false}
          		wrapper="div"
          		className={styles.clearButton}
          		onClick={onClearQuery}
          	/>
          	<ReactSVG
          		src={process.env.PUBLIC_URL + './svgs/search.svg'}
          		renumerateIRIElements={false}
          		wrapper="div"
          		className={styles.searchButton}
          		onClick={() => props.onSearchInput(query)}
          	/>
    	</div>
	)
}
export default SearchBar;