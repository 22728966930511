import { initializeApp } from 'firebase/app';


const fbConfig = {
    apiKey: "AIzaSyCq9pgCZHpH_-tQJI2t6QPQ8_D-BDjG1Zk",
    authDomain: "em-song-submissions.firebaseapp.com",
    databaseURL: "https://em-song-submissions.firebaseio.com",
    projectId: "em-song-submissions",
    storageBucket: "em-song-submissions.appspot.com",
    messagingSenderId: "65011328600",
    appId: "1:65011328600:web:9b75f853d1c99253"
}

const firebaseApp = initializeApp( fbConfig );

export default firebaseApp;