import React, { useState, useEffect } from 'react';
import { BLANK_TRACK } from '../../Constants';

import fb from '../../config/config';
import { getDatabase, ref, get, orderByChild, onValue, child, update, push, set } from "firebase/database";

import TrackTags from './TrackTags'
import TrackFiles from './TrackFiles'
import TrackMoreInfo from './TrackMoreInfo'
import TrackTitleComposer from './TrackTitleComposer'
import TrackInfo from './TrackInfo'
import TrackNotes from './TrackNotes'

import FormHeader from './FormHeader'


import { createNotesHeading } from '../../Helpers'

import 'rc-tooltip/assets/bootstrap_white.css';
import '../../styles/TrackForm.css';
import '../../styles/App.css';

import styles from './TrackForm.module.scss'


const db = getDatabase(fb)


const TrackForm = ({
  selected,
  setSelected,
  
  email,
  dbName,
  tracksRef
}) => {
  const [ track, setTrack ] = useState( BLANK_TRACK )
 
  const [ editMode, setEditMode ] = useState( false )
  const [ saved, setSaved ] = useState( true )

  const [ activeInput, setActiveInput ] = useState( null )

  const notComposerSubmitted = track.trackSubmittedBy === undefined || track.trackSubmittedBy  === ""

  useEffect(() => {
    if(selected){
      get(child(ref(db), `${dbName}/${selected}`)).then((snapshot) => {
        if (snapshot.exists()) {
          if(!snapshot.val())
            return
          setTrack(snapshot.val())
          setEditMode(true)
        } else {
          console.error("Can't get Track snap.val(): No data available");
        }
      }).catch((error) => {
        console.error(error);
      });

    }else{
      setTrack(BLANK_TRACK)
      setEditMode(false)
    }
  },[selected])


  // Exit Track Editing Mode
  const exitEditMode = () => {
    setSelected(null)
    setEditMode(false)
    setSaved(true)
  }

  // Highlight and element for the User
  const changeElementColor = (element) => {
    let thisElement = document.getElementById(element);
    thisElement.style.backgroundColor = 'orange';
    setTimeout(function() {
      thisElement.style.backgroundColor = 'transparent';
    }, 1000)
  }

  // Add a new track -- title and composer fields required
  const addTrack = () => {
    if( (track.trackTitle !== "") && (track.trackComposer !== "") ){
      let ref = push(tracksRef)
      const dateTime = new Date().toISOString()

      let trackToAdd = {
        ...track,
        id: ref.key,
        trackTimeAdd: dateTime,
        trackTimeMod: dateTime,
      }

      set(ref, trackToAdd).then(() => {
        setEditMode(true)
        setSaved(true)
        setTrack(trackToAdd)
      }).catch(err => {
        console.error(err)
      });
    }else{
      alert("Please enter a Track Title and Composer")
    }
  }

  // Handle changes to text inputs 
  //   without updating track (wait for onblur)
  const handleUserInput = (e) => {
    const { name, value } = e.target
    setTrack({
      ...track, 
      [name]: value
    })
    setActiveInput(name)
  }

  // Handle toggle changes, also updating firebase DB
  const handleToggle = (e) => {
    const { name } = e.target
    switch(name){
      case "trackResponded":
      case "trackFeePaid":
      case 'trackAuditionCues':
      case 'trackAddedComposerPayments':
      case 'trackReceivedStems':
      case 'trackFolderCreated':
      case 'trackSoundDesignReceived':
        updateTrackFields({ [name]: !track[name] })  
        break;
      default: console.error("TOGGLE ERROR: "+name+" not found")
    }
  }

  // Handle drop down changes
  const handleDropDown = (value,name) => {
    const updates = { [name]: value }

    if(value === 'Accepted, Assets Requested'){
      const newNotes = createNotesHeading(track, 'ACCEPTED, Assets Requested', email)
      updates['trackNotes'] = newNotes
    }

    if(name === 'trackStatus'){
      switch(value){
        case 'Up For Review':
        case "Up For Review (Relisten)":
        case "Accepted, Assets Submitted":
          updates['trackResponded'] = false
          changeElementColor('respondedSpan')
          break;
        default:
          break
      }   
    }
    updateTrackFields(updates)
  }

  // Update many track fields at once
  //   trackUpdates: object of track updates
  const updateTrackFields = (trackUpdates) => {
    // if this is a new track, return
    if(track.id==='' || !editMode){ 
      setTrack({ ...track, ...trackUpdates })
      console.log("Don't update track fields, is a new track!"); 
      return 
    }

    trackUpdates['trackTimeMod'] = new Date().toISOString()

    const dbUpdates = {}

    Object.keys(trackUpdates).forEach(key => {
      const value = trackUpdates[key]
      if(key.substring(0,5) !== 'track'){
        console.error("updateTrackFields: trying to update "+key+" with value "+value);
        return
      }
      dbUpdates["/"+key] = value
    })

    if(editMode){
      const newRef = ref(db, `/${dbName}/${selected}`)
      update(newRef, dbUpdates)
    }

    setTrack({ ...track, ...trackUpdates})
  }

  const trackProps = { 
    track, updateTrackFields, handleUserInput, handleDropDown, handleToggle
  }

  return(
    <div className={styles.trackInputForm + " " + (editMode ? styles.updateTrack : styles.addTrack )}>

      <FormHeader 
        newTrackID={track.id}
        isStarred={track['trackStar'] ? true : false}
        {...{ addTrack, editMode, exitEditMode, saved, updateTrackFields }}
      />

      <TrackTitleComposer {...trackProps}/>

      <TrackInfo {...trackProps}/>

      {editMode ? 
        <TrackTags 
          trackTitle={track.trackTitle}
          keywords={track.trackKeywords || []}
          updateKeywords={(keywords) => updateTrackFields({ trackKeywords: keywords })}
        /> : null
      }
      
      <TrackMoreInfo {...trackProps} /> 
      
      <TrackFiles
        updateTrackFields={updateTrackFields}
        newTrackFiles={track.trackFiles || []}
        newTrackStems={track.trackStemFiles || []}
        newSoundDesignFiles={track.trackSoundDesign || []}
        isArketype={track.trackLibrary === 'Arketype'}
      />

      <TrackNotes email={email} {...trackProps}/>

    </div>
  )
}
export default TrackForm;