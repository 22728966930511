/*
* @Author: Joe Steccato
* @Date:   2023-04-06 23:57:29
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-05-16 17:56:33
*/
 
import styles from './SubmitButtonBar.module.scss'

const SubmitButtonBar = ({ submit, totalPercent, cancelSubmission, formErrors, showFormErrors }) => {
  const showError = showFormErrors && formErrors
  return(
    <div className={styles.container + ` ${showError ? styles.showFormErrors : ''}`}> 
    
      {showError ? <div className={styles.errorMessage}>&#9888; Please fill out all required fields.</div> : null}

      <div className={styles.buttonRow}>

        <button 
          className={`
            ${styles.composerButton} 
            ${!(totalPercent === 100) && styles.disabled}
          `}
          onClick={submit}
          disabled={!(totalPercent === 100)}
        >
          {totalPercent > 0 && totalPercent < 100 ? ("Uploaded "+totalPercent + "%"): "SUBMIT" }
        </button>

        <button onClick={cancelSubmission} className={`${styles.composerButton} ${styles.cancel}`}>
          CANCEL
        </button>
        
      </div>
    </div>
  )
}

export default SubmitButtonBar;