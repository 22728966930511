import React from "react";
import { Route, Navigate, Outlet } from "react-router-dom";

export default function AdminRoute({ authenticated, isAdmin }) {
  return authenticated ? (
      isAdmin ?
        <Outlet /> : 
        <Navigate to="/composer-upload" />
    ) : 
      <Navigate to="/login" />
}