import React, { useState } from 'react';
import { withRouter } from '../Hooks';

import styles from './AppFooter.module.scss'


const AppFooter = ({ router }) => {
	if(
		router.location && (
			router.location.pathname.includes("/login") ||
			router.location.pathname.includes("/composer-upload") ||
			router.location.pathname.includes("/reset-password") ||
			(router.location.pathname === "/")
		)
	)
		return(
			<div className={styles.appFooter}>
				<div className={styles.copyright}>
					COPYRIGHT © 4 ELEMENTS MUSIC {new Date().getFullYear()}
				</div>
			</div>
		)
	else return null
}

export default withRouter(AppFooter);