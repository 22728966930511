import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Dropdown from 'react-dropdown';

import fb from '../../config/config';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, orderByChild, query, child, onValue } from "firebase/database";

import { format } from 'date-fns'
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import 'react-dropdown/style.css';
import 'react-tabs/style/react-tabs.css';
import '../../styles/InstructionalPopup.css';
import '../../styles/ExportFilesPopup.css'

import { DROPDOWN_OPTS } from '../../Constants'


const db = getDatabase(fb)


function isBlank(str) {
    return (!str || /^\s*$/.test(str));
}

const dateNow = Date.now();
const defaultDateStart = Date.parse("01 Jan 2021");

const Popup = (props) => {
	const [ tabSelected, setTabSelected ] = useState( 0 )
	const tracks = props.tracks.reverse()
	const rows = []

	const [ dateStart, setDateStart ] = useState(defaultDateStart)
	const [ dateEnd, setDateEnd ] = useState(dateNow)
	const [ composerPayments, setComposerPayments ] = useState("Both")
	const [ trackFee, setTrackFee ] = useState("Both")
	const [ excludeStatuses, setExcludeStatuses ] = useState([])

	const [ checkboxAll, setCheckboxAll ] = useState(true)

	const [ checkboxArchive, setCheckboxArchive ] = useState(true)

	// Date Start
	// Date End
	// Added to Composer Payments
	// trackSubmissionType !== Standard Spec
	// trackStatus checkboxes

	const exportCSV = () => {
		const dateStartStr = new Date(dateStart).toISOString();
		const dateEndStr = new Date(dateEnd).toISOString();
		const shouldCheckPayment = composerPayments === "Both" ? (false) : (true); 
		const includePaymentAdded = composerPayments === "Added" ? true : false;
		const shouldCheckFee = trackFee === "Both" ? false : true;
		const includeFee = trackFee === "Has Fee" ? true : false;
		tracks.forEach(t => {
			const track = t.val()
			if(
				(dateStartStr <= track.trackTimeAdd && track.trackTimeAdd <= dateEndStr) &&
				(track.trackSubmissionType !== "Standard Spec") &&
				(!excludeStatuses.includes(track.trackStatus)) &&
				(!shouldCheckPayment || (includePaymentAdded === track.trackAddedComposerPayments)) &&
				(!shouldCheckFee || (includeFee === !isBlank(track.trackFee)))
			){
				const jsDate = new Date(Date.parse(track.trackTimeAdd));
				const dateAdded = (jsDate.getMonth()+1).toString()+"/"+(jsDate.getDate().toString())+"/"+(jsDate.getFullYear().toString().slice(-2))
				rows.push([
					dateAdded.replaceAll(","," "),
					track.trackComposer.replaceAll(","," "),
					track.trackRequest.replaceAll(","," "),
					track.trackTitle.replaceAll(","," "),
					track.trackStatus.replaceAll(","," "),
					track.trackFee.replaceAll(","," ").replaceAll("\n"," "),
					(track.trackAddedComposerPayments ? "yes" : "no")
				])
			}			
		})

		if(checkboxArchive)
		{
			const archiveRef = query(ref(db,'archive'), orderByChild('trackTimeMod'))
			onValue(archiveRef, snap => {
			// fb.database().ref().child("archive").orderByChild('trackTimeMod').once('value', snap => {
				for(const [key, value] of Object.entries(snap.val()))
				{
					const track = value
					if(
						(dateStartStr <= track.trackTimeAdd && track.trackTimeAdd <= dateEndStr) &&
						(track.trackSubmissionType !== "Standard Spec") &&
						(!excludeStatuses.includes(track.trackStatus)) &&
						(!shouldCheckPayment || (includePaymentAdded === track.trackAddedComposerPayments)) &&
						(!shouldCheckFee || (includeFee === !isBlank(track.trackFee)))
					){
						const jsDate = new Date(Date.parse(track.trackTimeAdd));
						const dateAdded = (jsDate.getMonth()+1).toString()+"/"+(jsDate.getDate().toString())+"/"+(jsDate.getFullYear().toString().slice(-2))
						rows.push([
							dateAdded.replaceAll(","," "),
							track.trackComposer.replaceAll(","," "),
							track.trackRequest.replaceAll(","," "),
							track.trackTitle.replaceAll(","," "),
							track.trackStatus.replaceAll(","," "),
							track.trackFee.replaceAll(","," ").replaceAll("\n"," "),
							(track.trackAddedComposerPayments ? "yes" : "no")
						])
					}		
				}
				createCSV();
			})
		}else{
			createCSV();
		}
	}

	const createCSV = () => {
		let csv = "data:text/csv;charset=utf-8,Date,Composer,Job(Request),Track Title,Status,Amount(Fee),Added To Composer Payments\n"+ rows.map(e => e.join(",")).join("\n");
		var encodedUri = encodeURI(csv);

		var aLink = document.createElement('a');
		aLink.download = "export.csv";
		aLink.href = encodedUri;

		var event = new MouseEvent('click');
		aLink.dispatchEvent(event);
		props.closePopup();
	}

	const CustomDateInput = ({ value, onClick, placeholder }) => (
	    <input style={{  padding: '3px 4px'}} 
	    	onChange={null} 
	    	onClick={onClick} 
	    	value={value} 
	    	placeholder={placeholder}
	    	readOnly
	    	/>
	  );

	const handleCheckboxChange = (checked,val) => {
		const currentSelection = [...excludeStatuses];
		const indexToRemove = currentSelection.indexOf(val)
		if(indexToRemove > -1)
			currentSelection.splice(indexToRemove,1)
		else
			currentSelection.push(val)

		setExcludeStatuses(currentSelection)
	}

	const handleCheckboxAll = (checked) => {
		if(checkboxAll){
			// add all to exclude list
			setExcludeStatuses(DROPDOWN_OPTS[0])
			setCheckboxAll(false)
		}else{
			// clear all from exclude list
			setExcludeStatuses([])
			setCheckboxAll(true)
		}
	}

	return (  
		<div className='popup'>  
			<div className='popup_inner' style={{maxHeight: '90vh', maxWidth: '50vw'}}>  

				<div id='closePopup' className="instructionsClose" onClick={props.closePopup}>&#10005;</div>  
				<h1>Export CSV</h1>  

				<div>
					<div className='requestFormInput'><span style={{fontSize: '14px', fontWeight: '700'}}>Start Date: </span>
						<DatePicker selected={dateStart} 
							placeholder={"Click to pick start date"}
							onChange={date => setDateStart(date)} 
							useWeekdaysShort={true} 
							dateFormat="MMM. d yyyy"
							customInput={<CustomDateInput/>}
							showYearDropdown={true}
							/>
					</div>
					<div className='requestFormInput'><span style={{fontSize: '14px', fontWeight: '700'}}>End Date: </span>
						<DatePicker selected={dateEnd} 
							placeholder={"Click to pick end date"}
							onChange={date => setDateEnd(date)} 
							useWeekdaysShort={true} 
							dateFormat="MMM. d yyyy"
							customInput={<CustomDateInput/>}
							showYearDropdown={true}
							/>
					</div>
					<label style={{width: '320px', fontSize: '12px', textAlign: 'left', margin: 'auto'}}>
					          
					          <input
					          	style={{width: '14px'}}
					            name={`statusCheckboxAll`}
					            type="checkbox"
					            checked={checkboxArchive}
					            onChange={(e) => setCheckboxArchive(!checkboxArchive)} />
					        Include Archived Tracks
					</label>
					<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
					<div className='requestFormInput' style={{width: '200px', margin: '20px auto'}}><span style={{fontSize: '14px', fontWeight: '700'}}>Composer Payments: </span>
						<Dropdown
						  options={["Both","Added","Not Added"]} 
						  value={composerPayments}
						  onChange={(e) => setComposerPayments(e.value)}
						  placeholder="Select an option"
						 />
					</div>
					<div className='requestFormInput' style={{width: '200px', margin: '20px auto'}}><span style={{fontSize: '14px', fontWeight: '700'}}>Track Fee: </span>
						<Dropdown
						  options={["Both","Has Fee","No Fee"]} 
						  value={trackFee}
						  onChange={(e) => setTrackFee(e.value)}
						  placeholder="Select an option"
						 />
					</div>
					</div>
					<div className='requestFormInput' style={{display: 'flex', flexDirection: 'column', marginBottom: '20px'}}><h4>Select Track Statuses: </h4>
						<label style={{width: '320px', fontSize: '12px', textAlign: 'left', margin: 'auto'}}>
						          
						          <input
						          	style={{width: '14px'}}
						            name={`statusCheckboxAll`}
						            type="checkbox"
						            checked={checkboxAll}
						            onChange={(e) => handleCheckboxAll(e.target.checked)} />
						        All
						</label>
						{DROPDOWN_OPTS[0].map((o,i) => {
							return(
								<label key={i} style={{width: '320px', fontSize: '12px', textAlign: 'left', margin: 'auto'}}>
								          
								          <input
								          	style={{width: '14px'}}
								            name={`statusCheckbox`+i}
								            type="checkbox"
								            checked={!excludeStatuses.includes(o)}
								            onChange={(e) => handleCheckboxChange(e.target.checked,o)} />
								        {o}</label>
							)
						})


						}
					</div>
				</div>
				<div style={{width: '200px', margin: 'auto'}}>
				<button className="btn-flat" style={{width: '100%'}} onClick={exportCSV}>EXPORT CSV</button>
				</div>
			</div>  
		</div>  
	);  
}

export default Popup;