import { FC } from 'react'
import styles from './Header.module.scss'

interface Props {
	submissionType: "list" | "create" | "update" | "quick",
  	name: string;
}

const Header: FC<Props> = ({ submissionType, name }) => (
	<div className={styles.container}>

	  	<h1>
	  	  	{submissionType === "list"
	  	    	? `Welcome, ${name}`
	  	    	: submissionType === "create"
	  	    		? "New Submission"
	  	    		: "Update Submission"
	  	  	}
	  	</h1>

	  	<a 
	  		href="https://docs.google.com/document/d/14HFTtNMcugyZFbsIH3y9lVRYw75F5wo-8xo1RL7QvgY/edit?usp=sharing"
	  	  	target="_blank"
	  	>
	  	  	Guide to Submitting Tracks
	  	</a>
	  	
	</div>
)


export default Header;