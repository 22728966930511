/*
* @Author: Joe Steccato
* @Date:   2023-04-27 18:00:33
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-05-22 18:00:01
*/
import { useState, useEffect } from 'react'

import Textarea from 'react-textarea-autosize';

import { createNotesHeading } from '../../Helpers'

import styles from './TrackForm.module.scss'


const TrackNotes = ({ track, updateTrackFields, handleUserInput, email }) => {
	// Handle changes to Track Notes Field
	const addNotesHeader = () => {
	  const newNotes = createNotesHeading(track, "", email)
	  updateTrackFields({trackNotes: newNotes})
	}

	return(            
		<div className={styles.section}>

			<div className={styles.inputLabel}>
				<span>Notes:</span>
				<button
				  name="addTrack dNotesBtn"
				  className={"btn-flat " + styles.addNoteBtn}
				  onClick={addNotesHeader}>
				  +
				</button>
			</div>
	
			<Textarea
				onBlur={(e) => updateTrackFields({ [e.target.name]: e.target.value })}
				name="trackNotes"
				className={styles.inputNotes}
				wrap="soft"
				spellCheck={false}
				value={track['trackNotes']} 
				onChange={handleUserInput}   
			/>

		</div>
	)
}

export default TrackNotes