import React, { useState, useEffect } from 'react';
import '../../styles/Requests.css';


const EditorInternal = ({ requestInfo, setNewValue }) => {

	return (
		<div style={{width: "25vw", height: "60vh", display: 'flex', flexDirection: 'column', margin: "32px 0"}}>
	
			<div className='requestFormInput' style={{marginBottom: "10px"}}>
				<span><b>Music Category:</b></span>
				<input type="text" style={{marginLeft: '4px', minWidth: 180, width: 180}}
					placeholder="Enter music category..."
					value={requestInfo.music_category} 
					onChange={(e) => setNewValue("music_category", e.target.value)}
				/>
			</div>
	
			<div className='requestFormInput' style={{}}>
				<span><b>Client Category:</b></span>
				<input 
					type="text" 
					style={{marginLeft: '4px',minWidth: 180, width: 180}}
					placeholder="Enter client category..."
					value={requestInfo.client_category} 
					onChange={(e) => setNewValue("client_category", e.target.value)}/>
			</div>
	
			<div style={{fontWeight: "700", fontSize: "16px", margin: "20px 0"}}>
				Notes (internal):
			</div>
			<textarea 
				style={{width: "100%", maxWidth: '25vw',height :'60vh'}}
				value={requestInfo.notes}
				onChange={(e) => setNewValue("notes",e.target.value)}
			/>
		</div>
	)
}
			
export default EditorInternal;