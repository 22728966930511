/*
* @Author: Joe Steccato
* @Date:   2023-10-10 14:28:50
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-11-27 17:51:28
*/
import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from '../../Hooks';
import axios from '../../axios'

import UserInfo from './UserInfo';

import styles from './UserDetails.module.scss'


const USER_DEFAULT = {
	firstName: "",
	lastName: "",
	email: "",
	password: "",
	telephone: "",
	extension: "",
	company: "",
	companyRole: "",
	streetAddress: "",
	city: "",
	state: "",
	postalcode: "",
	country: "",

	default_format: "ASK EVERY TIME",
	download_limit: 10,
	show_all_pros: 0,
	show_pro_suspect: 0,

	mailinglist: true,
}


const AddUser = ({ router }) => {
	const [ user, setUser ] = useState({...USER_DEFAULT})
	const [ isSaved, setIsSaved ] = useState(false)
	const [ error, setError ] = useState(null) // Errors from API Response

	const [ userID, setUserID ] = useState(null)

	const onSave = async () => {
		try{
			const result = await axios.post('/users/create_internal', 
				{ secret: "@viva43l3m3nts!", user}
			);
			setUserID(result.data.data.id)
		}catch(e){
			if(e.response.data.errors){
				setError(e.response.data.errors.join("\n"))
			}else{
				setError(e.message)
			}
			setIsSaved(false)
		}
		setIsSaved(true)
		setUser(user)
	}

	const goBack = () => {
		// Check if there are unsaved changes
		if (!isSaved) {
		  	// Prompt the user and confirm if they really want to leave
		  	const confirmLeave = window.confirm('You have unsaved changes. Are you sure you want to leave?');
		  	if (!confirmLeave) {
		  	  	// If the user chooses not to leave, stop the navigation
		  	  	return;
		  	}
		}
		// If there are no unsaved changes, or the user confirms, proceed with navigation
		router.navigate("/website-users");
	}

	return(
		<div className={styles.container}>
			<div className={styles.header}>
				<div className="requestSideButtons requestSideButtonsLeft">
					<button className="editUserButton addUserButton"
					  onClick={goBack}
					>
					  &#8592; All Users	
					</button>
				</div>
				<h1>Add a Website User</h1>
				<div className="requestSideButtons"></div>
			</div>

			<div className={styles.tabs}>
				{error ? (
						<div style={{fontSize: 12, color: 'red', whiteSpace: 'pre-line'}}>{error}</div>
					): null}
				<UserInfo 
					{...{user, setUser, isSaved, setIsSaved, onSave, disabled: isSaved}}
					buttonText={isSaved ? "User Created!" : "Add User"}
					showPassword
					requiredFields={['email', 'password', 'firstName', 'lastName']}
					hideButton={userID !== null}
				/>
				{userID ? (
					<div><div>User Account Created!</div>
					<button 
						className={styles.saveChanges + " " + styles.changesSaved} 
						onClick={() => router.navigate(`/website-users/${userID}`)}
					>
						Go To User Account &#8594;
					</button> </div>
				):null}
			</div>
		</div>
	)
}

export default withRouter(AddUser);