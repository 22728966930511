import React, { useState, useEffect } from 'react';
import { format, parseISO } from 'date-fns'
import { ReactSVG } from 'react-svg';
import axios from '../../axios'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import RequestInfoEditor from './RequestInfoEditor';
import EditorTabs from './EditorTabs';
import EditorInternal from './EditorInternal';

import { withRouter } from '../../Hooks';

import { pad, parseDate } from '../../Helpers'

import 'react-tabs/style/react-tabs.css';
import '../../styles/Requests.css';


const SaveButton = ({ saving, isSaved }) =>  
	isSaved ? (
		<span style={{color: 'green'}}>
			<span style={{color: 'green'}} className="saveCheckMark">&#10004;</span>
			SAVED
		</span>
	) : (
		<span style={{color: 'red'}}>
			<ReactSVG
				src={process.env.PUBLIC_URL + '/svgs/save.svg'}
				renumerateIRIElements={false}
				wrapper="span"
				className="saveButton"
			/>  
			{saving ? "SAVING..." : "SAVE"}
		</span>
	)


const RequestEditor = ({ router }) => {
	const requestID = router.params.id
	
	if(!requestID)
		router.navigate("/request-list")

	const [ requestInfo, setRequestInfo ] = useState(null)

	const [ loading, setLoading ] = useState( true )

	const [ saving, setSaving ] = useState( false )
	const [ isSaved, setIsSaved ] = useState( true )
	const [ isEditorSaved, setIsEditorSaved ] = useState(true)

	const [ copied, setCopied ] = useState(false)

	const [ editedHtml, setEditedHtml ] = useState(null)

	const handleBeforeUnload = (e) => {
		if(!isSaved){
			e.preventDefault();
			// Setting a returnValue is required for some browsers
			e.returnValue = '';
			// Custom message may not be shown in modern browsers
			return 'Are you sure you want to leave?';
		}	
	};

	useEffect(() => {
	  	window.addEventListener('beforeunload', handleBeforeUnload);
	
	  	// Cleanup the event listener when the component unmounts
	  	return () => {
	  	  	window.removeEventListener('beforeunload', handleBeforeUnload);
	  	};
	}, [isSaved]);


	useEffect(() => {
	    const fetchRails = async () => {
	    	const result = await axios.get('/open_requests/'+requestID);

	    	const newRequestInfo = {...result.data.data.attributes}
	    	if(!newRequestInfo.notes) 
	    		newRequestInfo.notes = ""

	    	setRequestInfo(newRequestInfo)
	    	setEditedHtml(newRequestInfo.body)

	    	setIsSaved(true)
	    	setIsEditorSaved(true)
	    }
	    fetchRails();
	}, []);


	useEffect(() => {
		if(requestInfo && editedHtml !== requestInfo.body){
			setIsSaved(false)
		}
	},[editedHtml])

	useEffect(() => {
		if(isSaved){
			if(!isEditorSaved ){
				setIsSaved(false)
			}
		}
	},[isEditorSaved])

	useEffect(() => {
	 	if (copied) {
	 	  	const timer = setTimeout(() => {
	 	  	  setCopied(false);
	 	  	}, 2000);
	
	 	  	// Cleanup function to clear the timeout if the component unmounts
	 	  	return () => clearTimeout(timer);
	 	}
	}, [copied]);


	const commandSave = (e) => {
	  if (e.keyCode == 83 && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) {
	    e.preventDefault();
	    saveFile();
	  }
	};


	const saveFile = async () => {
		setSaving(true)

		const datePickerFormat = requestInfo.due_date && requestInfo.due_date !== ''
			 ? (requestInfo.due_date)
			 : null

		const newRequestInfo = {
			...requestInfo, 
			due_date: requestInfo.due_date,
			body: editedHtml
		}

		const result = await axios.post('/open_requests',  {
		 	...newRequestInfo,
		 	id: requestID,
		    secret: "viva43l3m3nts!"
		});

		setRequestInfo(newRequestInfo)
		setSaving(false)
		setIsSaved(true)
		setIsEditorSaved(true)
	}

	const copyForMailChimp = () => {
		// const toCopy = `<div style="font-size: 12px;">${editedHtml}</div>`
		const dateForEmail = `<p><strong>Due Date: ${format(parseDate(requestInfo.due_date), "E MMM. d, yyyy")}  10AM EST</strong></p>`
		const toCopy = `<p>Hi Composers,</p>${editedHtml}${requestInfo.show_due_date ? dateForEmail : ''}`
		navigator.clipboard.writeText(toCopy).then(() => {
		  	setCopied(true)
		}).catch(err => {
		  	console.error('Could not copy text: ', err);
		});
	}

	const setNewValue = (property, val) => {
		const newRequestInfo = {
			...requestInfo, 
			[property]: val
		}
		setRequestInfo(newRequestInfo)
		setIsSaved(false)
	}

	const backClicked = () => {
		if(!isSaved)
			if (!window.confirm('Changes are not saved! Are you sure you want to leave?'))
				return
			router.navigate("/request-list")
	}

	if(!requestInfo){
		return (null)
	}

	return (
		<div style={{backgroundColor: "#eee", height: "100%", overflow: "hidden" }} onKeyDown={commandSave} tabIndex="0">
			<div 
				style={{
					padding: "10px 100px", 
					display: "flex", 
					flexDirection: "row", 
					justifyContent:"space-between", 
					color: "#1d252c", 
					// padding: "10px", 
					fontSize: "1.2rem",
					backgroundColor: (isSaved ? "#eee" : "#f00")
				}}
			>
				
				<div>
					<button 
						style={{width: "200px", padding: "2px 0px", fontSize: "16px", height: '40px'}}
						className="sideBarButton track-button btn-top btn-flat" 
						onClick={backClicked}
					>
						&#8592; Request List	
					</button>
				</div>

				<div style={{margin: '0 auto', display: 'flex', flexDirection:'column', justifyContent:'center', left: '-4px'}}>
					{/*<div style={{fontWeight: 'bold', fontSize: 24}}>Request Editor</div>*/}
					<b>4EM REQUEST #{pad(requestInfo.request_number)}: {requestInfo.title}</b>  
					<br/>
					<span style={{fontSize: 16}}>Due Date: {requestInfo.show_due_date ? (format(parseDate(requestInfo.due_date), "E MMM. d, yyyy")+", 10AM EST") : ("Ongoing")}</span>
					<a style={{fontSize: 12}} rel="noreferrer noopener" href="https://www.4elementsmusic.com/open-requests" target="_blank">View on 4EM Site</a>
				</div>

				<div className="buttonColumn">
					<button 
						className="sideBarButton track-button btn-top btn-flat" 
						style={{width: "200px", padding: "2px 20px", fontSize: "16px", height: '40px'}}
						onClick={saveFile}
					>
						<SaveButton {...{saving, isSaved}} />
          			</button>

          			<button 
          				className="sideBarButton track-button btn-top btn-flat" 
          				style={{width: "200px", padding: "2px 20px", fontSize: "14px", height: '40px'}}
          				onClick={copyForMailChimp}
          			>
          				{!copied ? "Copy for MailChimp" : "Copied!" }
          			</button>
        		</div>

			</div>

			<hr style={{margin: 0}}/>


			{requestInfo !== null && (
				<div>
					<RequestInfoEditor 
						requestInfo={requestInfo}
						setNewValue={setNewValue}
					/>
	
					<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
						<EditorTabs 
							currentHtml={requestInfo.body} 
							setEditedHtml={setEditedHtml} 
							setIsEditorSaved={setIsEditorSaved}
						/>
						<EditorInternal 
							requestInfo={requestInfo}
							setNewValue={setNewValue}
						/>
					</div>
				</div>
			)}
		</div>
	)
}
			
export default withRouter(RequestEditor);