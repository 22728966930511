import React, { useState, useEffect } from 'react';

import { useNavigate } from "react-router-dom";

import { withRouter } from '../Hooks';

import { 
  getAuth, 

  setPersistence, 
  browserLocalPersistence, 
  inMemoryPersistence, 

  signInWithEmailAndPassword, 
  sendPasswordResetEmail 
} from "firebase/auth";

import app from '../config/config';

import '../styles/Login.css';

const auth = getAuth(app);

const LoginLoading = () => <div
                            style={{margin: 'auto'}}
                           >
                            Loading...
                           </div>


const Login = (props) => {
  const [email, setEmail ]= useState('')
  const [password, setPassword ]= useState('')

  const navigate = useNavigate()

  const [ isAuthenticated, setIsAuthenticated ]= useState(false)
  const [ loginError, setLoginError ]= useState('')
  const [ isResettingPassword, setIsResettingPassword ]= useState(false)
  const [ useAuthPersistence, setUseAuthPersistence ]= useState(true)
  const [ loading, setLoading ]= useState(false)

  useEffect(() => {
    if(auth.currentUser)
      navigate("/")
  },[])

  const handleLoginSubmit = async (event) => {
    event.preventDefault();

    setLoading(true)
    
    let persist = useAuthPersistence ? browserLocalPersistence : inMemoryPersistence;

    try {
      const user = await signInWithEmailAndPassword(auth, email, password);
      setLoading(false)
      navigate("/")
      await setPersistence(auth, persist)
    } catch(error) {
      console.error(error)
      setLoading(false)
      setLoginError(error.message.replace("Firebase: ",""));
    }
  }

  const handleResetPasswordSubmit = () => {
    sendPasswordResetEmail(auth, email).then(function() {
      // Email sent
      setLoginError("Please check your email for a link to reset your password");
    }).catch(function(e){
      setLoginError(e.message);
    })
  }


  const didForgetPassword = (value) => {
    setIsResettingPassword(value)
    setLoginError(''),
    setPassword('')
  }

  return (
    <div className="loginContainer">
        <h1 style={{fontWeight: '300', letterSpacing: '2px',margin:"30px 0px"}}>4EM Composer Portal</h1>

        <div className="loginBox">

        {loading ? <LoginLoading/> : (

          !isResettingPassword ? (

            <form method="post" onSubmit={handleLoginSubmit}>

              <div style={{fontWeight: 500, letterSpacing: '1px'}}>LOGIN</div>

              <div className="loginInputContainer">
                <input
                  name="email"
                  className="inputLogin" 
                  placeholder="Email*"
                  value={email} 
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input 
                  name="password"
                  type="password"
                  className="inputLogin" 
                  placeholder="Password*"
                  autoComplete="off"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />

                <div className="loginErrorMsg">{loginError}</div>

                <div className="loginButtonsList">

                  <div className="loginButtonRow">
                    <label style={{fontSize: '12px'}}>
                      <input type="checkbox" checked={useAuthPersistence} 
                        onChange={() => setUseAuthPersistence(!useAuthPersistence)} 
                        style={{width: '20px'}}
                      />
                      Keep Me Signed In
                    </label>
                  </div>

                  <div className="loginButtonRow">
                    <input type="submit" value="GO" className="loginSubmit"/>
                  </div>

                  <div className="loginButtonRow">
                    <div
                      className="forgotPasswordLink"
                      style={{
                        textDecoration:'underline',
                        cursor: 'pointer'
                      }}
                      onClick={() => didForgetPassword(true)}
                    >  
                      Forgot Password?
                    </div>
                  </div>

                </div>
            </div>
            </form>

          ) : (

            <div className="resetPasswordForm">
              <div style={{fontWeight: 500, letterSpacing: '1px'}}>RESET PASSWORD</div>
  
              <div className="loginInputContainer">
                <input
                  name="email"
                  className="inputLogin" 
                  placeholder="Email*"
                  value={email} 
                  onChange={(e) => setEmail(e.target.value)}
                />
                
                <div className="loginErrorMsg">{loginError}</div>
                <div className="loginButtonsList">
  
                  <div className="loginButtonRow">
                    <button
                      onClick={handleResetPasswordSubmit}
                      className="loginSubmit"
                      >
                      RESET PASSWORD
                    </button>
                  </div>
                  <div className="loginButtonRow">
            
                   <div
                     className="forgotPasswordLink"
                      style={{
                        textDecoration:'underline',
                        cursor: 'pointer',
                        marginTop: '20px'
                      }}
                      onClick={() => didForgetPassword(false)}
                    >  
                      &#8592; Back to login
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        )}
        </div>

        <div className="spacer"></div>
      </div>
    )

}

export default withRouter(Login);