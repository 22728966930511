
// import '../../styles/App.css'
// import '../../styles/Home.css';

import styles from './SubmissionsPausedPopup.module.scss'

const SubmissionsPausedPopup = ({ setShowPausedPopup }) => (
  <div className={styles.popupOuter}>
    <div className={styles.popupInner}>

      <div 
        id='closePopup' 
        className={styles.close} 
        onClick={() => setShowPausedPopup(false)}
      >
          &#10005;
      </div>  

      <div className={styles.header}>
        Pausing New Submissions Until <br/> January 6th, 2023
      </div>

      <div className={styles.message}>
        <div>We are taking a pause on listening to new submissions for our regular 4EM Music Requests until <b>January 6th</b>.</div>
        <div>However, we are still accepting submissions for <br/><b>Request #0642 Inspiring Orchestral</b> until December 14th.</div>
        <div>We look forward to hearing from you in the new year!</div>
      </div>

    </div>
  </div>
)


export default SubmissionsPausedPopup;