/*
* @Author: Joe Steccato
* @Date:   2023-04-27 17:50:34
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-12-13 12:56:38
*/
import { useState, useEffect } from 'react'
import Dropdown from 'react-dropdown';

import { DROPDOWN_OPTS } from '../../Constants';


const TrackInfo = ({ 
	track, 
	updateTrackFields,

	handleDropDown, 
	handleUserInput, 
	handleToggle 
}) => {
	return(
		<div className="inputColumn">
		  <div className="inputRow inputStatus">
		    <div id="statusLabel">Status:</div>  
		  </div> 
		  <div>
		      <Dropdown
		        name="trackStatus"
		        options={DROPDOWN_OPTS[0]} 
		        value={track['trackStatus']}
		        onChange={(e) => handleDropDown(e.value,"trackStatus")}
		        placeholder="Select an option"
		       />
		  </div> 
		  <div className="inputRow inputStatus">
		    <div id="statusLabel">Version Description:</div>  
		  </div> 
		  <div>
		    <input
		      style={{width: '94%'}}
		      onBlur={(e) => updateTrackFields({ [e.target.name]: e.target.value })}
		      placeholder="Version Description"
		      name="trackVersionDesc"
		      className="inputRequest"
		      value={track['trackVersionDesc'] || ""}
		      onChange={handleUserInput}
		    />
		  </div> 

		  <div>
		    <div className="inputRow chkboxColumn">
		      <label id="respondedSpan" className="checkBoxDiv respondedLabel">
		        <input
		          id="respondedBox"
		          name="trackResponded"
		          className="inputResponded"
		          type="checkbox"
		          checked={track['trackResponded']}
		          onChange={handleToggle}
		        />Responded
		      </label>

		      {track.trackLibrary === 'Arketype' ? (
		      	<label id="respondedSpan" className="checkBoxDiv respondedLabel">
		      	  <input
		      	    id="respondedBox"
		      	    name="trackSoundDesignReceived"
		      	    className="inputResponded"
		      	    type="checkbox"
		      	    checked={track['trackSoundDesignReceived'] || false}
		      	    onChange={handleToggle}
		      	  />Sound Design
		      	</label>
		      ):null}

		    </div> 
		  </div>
		</div>
	)
}

export default TrackInfo