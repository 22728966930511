import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { useOpenRequests } from '../../Hooks';

import { format, parseISO } from 'date-fns'
import { ReactSVG } from 'react-svg';
import axios from '../../axios'

import CreateRequestListPopup from './CreateRequestListPopup';
import SearchBar from '../Common/SearchBar';

import LoadingBars from '../Common/LoadingBars'

import { pad } from '../../Helpers'


import '../../styles/ComposerList.css';
import '../../styles/RequestList.css';


function filterRequests(req,filter){
	const r = req.attributes
	const arr = [("#"+pad(r.request_number)), r.notes, r.body, r.title, r.due_date, r.music_category, r.client_category]
	const lc = arr.join(" ").toLowerCase();__dirname
  	let searchFilter = filter.toLowerCase();
  	return lc.includes(searchFilter);
}


const RequestListItem = ({ request, id, refreshRequests }) => {
	const navigate = useNavigate()

	const { number, title, due_date, is_visible, music_category, request_number, show_due_date } = request

	const [ saving, setSaving ] = useState(false)
	const [ isVisible, setIsVisible ] = useState(is_visible)

	const date = (due_date && due_date !== '') 
		? new Date(Date.parse(due_date.replace('.', ''))) 
		: null
	
	const formattedDate = date && !isNaN(date) ? format(date, "E MMM. d, yyyy") : null

	const onVisibleChange = async () => {
		 const data = {
		 	...request,
		 	is_visible: !isVisible,
		 	id: id,
		    secret: "viva43l3m3nts!"
		 }

		const result = await axios.post('/open_requests', data);
		setIsVisible(!isVisible)
	}

	const deleteRequest = async () => {
		if (window.confirm(`Are you sure you want to delete the request "${title}"`)) {
		  	const data = {
		  		id: id,
		  		secret: "viva43l3m3nts!"
		  	}
		  	const result = await axios.post('/open_requests/delete_request', data);
		  	setSaving(false)
		  	refreshRequests()
		}
	}

	return(
		<div className="request_list_row" style={{backgroundColor: (isVisible ? "#fff" : "#eee"), color: (isVisible ? "#000" : "#00000099")}}>
			<div className="request_list_item request_number">
				#{pad(request_number)}
			</div>
			
			<div className="request_list_item request_title">
				<b>{title}</b>
			</div>
			<div className="request_list_item request_due_date">
				{show_due_date ? (formattedDate || "Ongoing") : "Ongoing"}
			</div>
			<div className="request_list_item request_is_visible">
				<input
					name="isVisible"
					type="checkbox"
					checked={isVisible}
					onChange={onVisibleChange} />
			</div>
			<div className="request_list_item request_edit">
				<button onClick={() => navigate("/request-list/"+id)}>Edit</button>
			</div>
			<div className="request_list_item request_delete">
				<ReactSVG
					onClick={deleteRequest}
				    src={process.env.PUBLIC_URL + './svgs/trash.svg'}
				    renumerateIRIElements={false}
				    wrapper="span"
				    className="removeTrackButton svg-class-name"
				    id="svgTrashButton"
				/>
			</div>

			<div className="request_list_item request_title">
				{music_category || ""}
			</div>
		</div>
	)
}

const RequestList = (props) => {
	const { requestList, requestsLoading, refreshRequests } = useOpenRequests()
	const [ requests, setRequests ] = useState(requestList);

	const [filtered, setFiltered] = useState([]);
	const [loading, setLoading] = useState(true);

	const [query, setQuery] = useState("");
	const [popupCreate, setPopupCreate ] = useState(false);

	const [ sortBy, setSortBy ] = useState( 'request_number' )
	const [ sortDirection, setSortDirection ] = useState( false )

	useEffect(() => {
		if(!requestsLoading){
			setRequests(requestList)
			if(query !== "")
				onSearchInput(query)
			else
				setFiltered(requestList)

			setLoading(false)
		}
		
	}, [requestsLoading])


	useEffect(() => {
		const sortRequests = [...requests];
		let sort = sortBy;
		if(sort === "due_date")
		{
			sortRequests.sort((a,b) => {
				const dateA = a.attributes[sort] ? new Date(Date.parse(a.attributes[sort])).toISOString() : ""
				const dateB = b.attributes[sort] ? new Date(Date.parse(b.attributes[sort])).toISOString() : ""
				if (dateA < dateB) {
				  return -1;
				}
				if (dateA > dateB) {
				  return 1;
				}
				return 0;
			})
		}
		else
		{
			sortRequests.sort((a, b) => {
				if ((a.attributes[sort] || "") < (b.attributes[sort] || "")) {
				  return -1;
				}
				if ((a.attributes[sort] || "") > (b.attributes[sort] || "")) {
				  return 1;
				}
				return 0;
			});
		}
		setRequests(sortRequests);
		if(query !== "")
			onSearchInput(query)
		else
			setFiltered(sortRequests)
		setSortDirection(false)
	},[sortBy])

	useEffect(() => {
		const sortRequests = [...requests].reverse()
		setRequests(sortRequests)
		if(query !== "")
			onSearchInput(query)
		else
			setFiltered(sortRequests)
	},[sortDirection])

	const setNewSort = (column) => {
		if(column === sortBy) 	setSortDirection(!sortDirection)
		else 					setSortBy(column)
	}

	const onSearchInput = (val) => {
	  let list = [...requests].filter(r => { 
	    return filterRequests(r,val);
	  });
	  setFiltered(list);
	  setQuery(val);
	}

	const onClearQuery = () => {
	  setQuery('')
	  setFiltered(requests)
	}

	if(loading)
		return( <LoadingBars loading={true} />)

	return(
		<div style={{height: '100%'}}>
			<div className="composer_page_header" style={{height: '40px', marginBottom: '6px'}}>
				<div style={{width: '170px', margin: "auto"}}>
					<a style={{fontSize: 12}} rel="noreferrer noopener" href="https://www.4elementsmusic.com/open-requests" target="_blank">
						<button 
							className="button btn-top btn-flat" 
							>
								View on 4EM Site
						</button>
					</a>
				</div>
				<div style={{fontSize: '30px', color: "white"}}>
					Request List
				</div>
				<div style={{width: '170px', margin: "auto"}}>
					<button 
						className="button btn-top btn-flat" 
						onClick={() => setPopupCreate(true)}
						>
							+ New Request
					</button>
				</div>
			</div>
			  
			<div className="composer_list_main">
				<SearchBar 
					page={"requests"}
				  	query={query}
				  	onSearchInput={onSearchInput}
				  	onClear={onClearQuery}
				  	placeholder={"Search Requests..."}
				/>
				<div className="header_row composer_row request_list_row request_list_header" style={{paddingRight: '15px'}}>
					<div onClick={() => setNewSort('request_number')} className="request_list_item request_number">
						# {sortBy==='request_number' ? (sortDirection ? <span>&#9650;</span> : <span>&#9660;</span>) : null }
					</div>
					<div onClick={() => setNewSort('title')} className="request_list_item request_title">
						Title {sortBy==='title' ? (sortDirection ? <span>&#9650;</span> : <span>&#9660;</span>) : null }
					</div>
					<div onClick={() => setNewSort('due_date')} className="request_list_item request_due_date">
						Due Date {sortBy==='due_date' ? (sortDirection ? <span>&#9650;</span> : <span>&#9660;</span>) : null }
					</div>
					<div onClick={() => setNewSort('is_visible')} className="request_list_item request_is_visible">
						Visible {sortBy==='is_visible' ? (sortDirection ? <span>&#9650;</span> : <span>&#9660;</span>) : null }
					</div>
					<div className="request_list_item request_edit">
					</div>
					<div className="request_list_item request_delete">
					</div>
					<div onClick={() => setNewSort('music_category')} className="request_list_item request_title">
						Music Category {sortBy==='music_category' ? (sortDirection ? <span>&#9650;</span> : <span>&#9660;</span>) : null }
					</div>
				</div>
				<div className="composer_item_container">
					{filtered.map((r,i) => {
						const rAttr = r.attributes;
						return <RequestListItem key={r.id} id={r.id} request={rAttr} refreshRequests={refreshRequests}/>
					})}
				</div>
			</div>
			{popupCreate ?  
			  <CreateRequestListPopup  closePopup={() => { setPopupCreate(false); refreshRequests() }}  />  
			  : 
			  null  
			}
		</div>
	)
}

export default RequestList;