/*
* @Author: Joe Steccato
* @Date:   2023-04-21 11:31:56
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-04-21 11:38:07
*/

import styles from './Popup.module.scss'


export const PopupContainer = ({ children }) => (
	<div className={styles.popupContainer}>
		{children}
	</div>
)
