import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, Routes, useLocation } from "react-router-dom";

// FIREBASE
import fb from '../config/config';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, get, child } from "firebase/database";

// ROUTE TYPES
import AdminRoute from './RouteTypes/AdminRoute'
import UserRoute from './RouteTypes/UserRoute'

// PAGE COMPONENTS
import Home from './Home';
import Login from './Login';
import ResetPassword from './ResetPassword';
import PageNotFound from './PageNotFound';

import ComposerRoute from './ComposerSubmissions/ComposerRoute';

import SongBook from './SongBook/SongBook';

// USER AND COMPOSER MANAGEMENT
import ComposerListVL from './ComposerListVL/ComposerListVL';
import Users from './Users/Users';
import WebsiteUsers from './WebsiteUsers/WebsiteUsers'
import UserDetails from './WebsiteUsers/UserDetails'
import AddUser from './WebsiteUsers/AddUser'

// OPEN REQUESTS
import RequestList from './OpenRequests/RequestList';
import RequestEditor from './OpenRequests/RequestEditor';

// TRACK TAGGER
import TrackTaggerPage from './TrackTagger/TrackTaggerPage'

import { PlayerPageRouter } from './Common/AudioPlayer'


// DOWNLOAD 4EM TOOL
import DownloadTool from './DownloadTool/DownloadTool'

// COMMON
import AppHeader from './AppHeader';
import AppFooter from './AppFooter';

import '../styles/App.css'


const DATABASE_NAMES = {
  'development': ['devtracks','devarchive'],
  // 'development': ['tracks','archive'],
  'production': ['tracks','archive']
}


// Check Node Environemnt name and set dev and prod DB reference names
const setFirebase = () => {
  const { NODE_ENV } = process.env

  if(DATABASE_NAMES[NODE_ENV]){
    if(NODE_ENV === 'development')
      console.log("USING ~DEV~ ENV")
  
    return DATABASE_NAMES[NODE_ENV]
  }else{
    console.log('ERROR: Unrecognized process.env name' + NODE_ENV + ", using 'development' envi")
    return DATABASE_NAMES['development']
  }
}


const auth = getAuth(fb);
const db = getDatabase(fb)


const App = () => {
  const envRefs = setFirebase()

  const [ loading, setLoading ] = useState(true)
  const [ authenticated, setAuthenticated ] = useState(false)
  const [ isAdmin, setIsAdmin ] = useState(false)
  const [ currentUser, setCurrentUser ] = useState(null)

  const email = (currentUser === null) ? "" : currentUser.email;

  useEffect(() => {
    onAuthStateChanged(auth, authorize)
  },[])

  const authorize = async (user) => {
    setLoading(true)

    const isAdmin = user
     ? (await get(child(ref(db), `users/${user.uid}`) )).val().isAdmin
     : false
                 
    setIsAdmin(isAdmin)
    setAuthenticated(user ? true : false)
    setCurrentUser(user ? user : null)

    setLoading(false)
  }


  if (loading) {
    return <div className="App">Loading...</div>;
  }

  const adminProps = { authenticated, isAdmin }

  return (
    <div className={"App"}>
      <Router>

        <AppHeader {...adminProps} email={email} />

        <div className={"App-body"}>
          <Routes>

            {/* PrivateRoutes */}
            <Route element={ <UserRoute {...adminProps}/> }>

              <Route path="/composer-upload" element={
                  <ComposerRoute {...{currentUser,envRefs}}
                    currentUser={currentUser} 
                    envRefs={envRefs}
                  />
                }
              />

            </Route>

            {/* AdminRoutes */}
            <Route element={ <AdminRoute {...adminProps}/> }>

              <Route path="" element={
                  <Home {...{envRefs}}/>
                }
              />

              <Route path='/songbook' element={
                  <SongBook {...{envRefs}}/>
                }
              />

              <Route path="/archive" element={
                  <SongBook 
                    isArchive
                    envRefs={[...envRefs].reverse()}
                  />
                }
              />

              <Route path='/test-songbook' element={
                  <SongBook
                    isTesting
                    envRefs={['devtracks','devarchive']}
                  />
                }
              />

              <Route path="/test-archive" element={
                <SongBook
                  isTesting
                  isArchive
                  envRefs={['devtracks','devarchive']}
                />}
              />

              <Route path="/test-composer-upload" element={
                  <ComposerRoute 
                    currentUser={currentUser} 
                    isTesting
                    envRefs={['devtracks','devarchive']}
                  />
                }
              />

              <Route path="/website-users"           element={ <WebsiteUsers/> }/>
              <Route path="/website-users/:id"       element={ <UserDetails/> } />
              <Route path="/website-users/add"       element={ <AddUser/> } />



              <Route path="/request-list"           element={ <RequestList/> }/>
              <Route path="/request-list/:id"       element={ <RequestEditor/> } />
              <Route path="/vl-composers"           element={ <ComposerListVL/> }/>
              <Route path="/track-tagger"           element={ <TrackTaggerPage/> }/>
              <Route path="/player/:track?"          element={ <PlayerPageRouter/> }/>

              <Route path="/users"                  element={ <Users/> }/>
              <Route path="/download-tool/:type?"   element={ <DownloadTool/> }/>

            </Route>

            <Route path="/login"                   element={ <Login/> }/>
            <Route path="/reset-password"          element={ <ResetPassword /> }/>
            <Route path="*"                        element={ <PageNotFound/>} />

          </Routes>
        </div>

        <AppFooter />

      </Router>
    </div>
  );
}

export default App;