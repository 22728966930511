import React, { useState, useEffect } from 'react';
import { format, parseISO } from 'date-fns'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import '../../styles/ExportFilesPopup.css'

import styles from './RequestInfoEditor.module.scss'

import { pad, parseDate } from '../../Helpers'


const RequestInfoEditor = ({ requestInfo, setNewValue }) => {
	const { 
		title, 
		request_number, 
		due_date, 
		show_due_date, 
		notes, 
		music_category, 
		client_category, 
		is_visible 
	} = requestInfo

	const datePickerFormat = (due_date) 
		? parseDate(due_date) 
		: null

	const CustomDateInput = ({ value, onClick }) => (
	    <input 
	    	className={styles.CustomDateInput}
	    	onChange={null} 
	    	onClick={onClick} 
	    	value={value} 
	    	placeholder="Click to pick due date..." 
	    	readOnly
	    />
	);

	return (
		<div className={styles.inner}>
		    <div className={styles.requestFormInput + " " + styles.small}>
		        <label htmlFor="requestNumber">Request #:</label>
		        <input 
		            id="requestNumber"
		            type="text"
		            placeholder="eg. ####"
		            value={request_number} 
		            onChange={(e) => setNewValue("request_number", e.target.value.toString())}
		        />
		    </div>

		    <div className={`${styles.requestFormInput} ${styles.large}`}>
		        <label htmlFor="title">Title:</label>
		        <input 
		            id="title"
		            type="text"
		            placeholder="Enter title..."
		            value={title} 
		            onChange={(e) => setNewValue("title", e.target.value)}
		        />
		    </div>

		    <div className={`${styles.requestFormInput} ${styles.customDateInput} ${styles.medium}`}>
		        <label htmlFor="dueDate">Due Date:</label>
		        <DatePicker 
		            selected={datePickerFormat} 
		            onChange={date => setNewValue("due_date", format(date, "E MMM. d, yyyy"))} 
		            useWeekdaysShort={true} 
		            dateFormat="E MMM. d, yyyy"
		            customInput={<CustomDateInput id="dueDate"/>}
		        />
		    </div>

		    <div className={`${styles.requestFormInput} ${styles.requestFormCheckbox}`}>
		        <label 
		            htmlFor="isVisible"
		            onClick={() => setNewValue("is_visible", !is_visible)}
		        >
		            Visible:
		        </label>
		        <input
		            id="isVisible"
		            name="isVisible"
		            type="checkbox"
		            checked={is_visible}
		            onChange={() => setNewValue("is_visible", !is_visible)} 
		        />
		    </div>

		    <div className={`${styles.requestFormInput} ${styles.requestFormCheckbox} ${(due_date === null && styles.disabled_field)}`}>
		        <label 
		            htmlFor="showDate" 
		            onClick={() => {
		                if(due_date !== null)
		                    setNewValue("show_due_date", !show_due_date)
		            }}
		        >
		            Show Date:
		        </label>
		        <input
		            id="showDate"
		            disabled={!due_date ? true : false}
		            name="showDate"
		            type="checkbox"
		            checked={show_due_date}
		            onChange={() => setNewValue("show_due_date", !show_due_date)} 
		        />
		    </div>

		</div>				
	)
}

export default RequestInfoEditor;