/*
* @Author: Joe Steccato
* @Date:   2023-04-04 13:01:51
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-07-24 14:38:14
*/
import React, { useState, useEffect } from 'react'
import yaml from 'js-yaml';
import { Link } from 'react-router-dom'

import { withRouter } from '../../Hooks';
import LoadingBars from '../Common/LoadingBars'


import { capitalize } from '../../Helpers'

import styles from './DownloadTool.module.scss'


const TOOL_S3_PATH = "https://4elementsmusic.s3.amazonaws.com/4emtool/"


const getDmgUrl = (yamlString) => {
	  // Get the current Mac architecture
	  const arch = window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints === 0 ? 'x64' : 'arm64';

	  // Load the YAML file into a JavaScript object
	  const data = yaml.load(yamlString);
	  const version = data.version
	  
	  // Parse YAML data for file urls
	  const files = data.files.filter(f => f.url.includes(`.dmg`));
	  const urls = files.map(f => f.url)

	  return {
	  	version,
	  	arm64: TOOL_S3_PATH + urls.filter(f => f.includes('arm64'))[0],
	  	intel: TOOL_S3_PATH + urls.filter(f => !f.includes('arm64'))[0]
	  }
}


const DownloadButton = ({ link, children }) => (
	<Link to={link} target="_blank" download>
		<button>{children}</button>
	</Link>
)


const DownloadTool = ({ router }) => {
	const [ loading, setLoading ] = useState(true)

	const [ arch, setArch ] = useState(router.params.type ? router.params.type : null)
	const [ downloads, setDownloads ] = useState(null)

	useEffect(() => {
	  fetch(TOOL_S3_PATH + 'latest-mac.yml')
	  	.then(res => res.blob())
	  	.then(blob => blob.text())
	  	.then(yamlString =>{
	  		const dls = getDmgUrl(yamlString)

	  		if(Object.keys(dls).includes(arch)){
	  			autoDownload(dls[arch])
	  		}else if(arch){
	  			setArch(null)
	  		}

	  		setLoading(false)
	  		setDownloads(dls)
	  	}).catch(e => console.error(e))
	},[])


	const autoDownload = (downloadLink) => {
		var a = document.createElement('a');
		a.setAttribute('href', downloadLink);
		a.setAttribute('download', true)
		a.click()
	}


	const versionMarkup = downloads ? <h2>v{downloads.version}</h2> : null


	return(
		<div className={styles.container}>

			<h1>Download 4EM Tool</h1>

			{loading ? (
					<LoadingBars loading={true}/>
				) : (
					<React.Fragment>
						{versionMarkup}

						{arch ? (
							<div className={styles.downloadStarted}>
								Download started for
								<br/>
								<strong>4EM Tool for {capitalize(arch)}...</strong>
							</div>
						) : null}

						{downloads ? (
							<div className={styles.downloadButtons}>
								<DownloadButton link={downloads.intel}>
									Download Intel Mac (.dmg)
								</DownloadButton>

								<DownloadButton link={downloads.arm64}>
									Download M1 Mac (.dmg)
								</DownloadButton>
							</div>
						) : null}
					</React.Fragment>
			)}
		</div>
	)
}

export default withRouter(DownloadTool)