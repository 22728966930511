import React, { useState, useEffect } from 'react';
import { Navigate, Link } from "react-router-dom";
import { withRouter } from '../../Hooks';

import fb from '../../config/config';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, get, set, remove, push, update, orderByChild, query, child } from "firebase/database";

import TrackForm from './TrackForm';
import TrackList from './TrackList';

import LoadingBars from '../Common/LoadingBars'

import '../../styles/App.css'
import styles from './SongBook.module.scss';

import {
  useWindowWidth
} from '@react-hook/window-size'

const db = getDatabase(fb)
const auth = getAuth(fb);


const SongBook = ({ router, isArchive, isTesting, envRefs }) => {
  const email = auth.currentUser['email']
  const childRef = ref(db,envRefs[0])

  const [ selected, setSelected ] = useState( null )

  const [ loading, setLoading ] = useState(true)
  

  const TestingMarkup = isTesting 
    ? <div style={{color: "#f00"}}>THIS IS THE <u>TESTING SONGBOOK</u>.</div>
    : null


  return(
    <>
    {TestingMarkup}
    <div className={styles.main}>

      
      
      <TrackList 
        selected={selected}
        setSelected={setSelected} 

        envRefs={envRefs}

        isArchive={isArchive}
        setLoading={setLoading}
      />

      <TrackForm 
        selected={selected}
        setSelected={setSelected}

        email={email}
        dbName={envRefs[0]}
        tracksRef={childRef}
      />

    </div>
    </>
  );
}

export default withRouter(SongBook);