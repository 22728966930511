import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Line } from 'rc-progress';
import ClipLoader from 'react-spinners/ClipLoader';
import Tooltip from 'rc-tooltip'
import axios from 'axios';

import fb from '../../config/config';
import { getFunctions, httpsCallable } from 'firebase/functions'

import { override } from '../Common/LoadingBars'

import 'rc-tooltip/assets/bootstrap_white.css';
import '../../styles/Submit.css'

import styles from './FileUpload.module.scss'

const acceptedFileTypes = [
	"audio/wav", 
	"audio/x-wav", 

	"sound/aiff",
	"audio/aiff",
	"audio/x-aiff",
	
	"audio/mpeg",

	"audio/midi" , 
	"audio/x-midi"
]


const functions = getFunctions(fb)


function truncateMiddle(str) {
  if (str.length > 76) {
    return str.substr(0, 38).trim() + '...' + str.substr(str.length-39, str.length).trim();
  }
  return str;
}


const FileUpload = (props) => {
	const [ file, setFile ] = useState(props.file)
	const { loaded } = file

	const [ isDeleting, setIsDeleting ] = useState(false)

	const cancelToken = useRef(null)


	useEffect(() => {
		if(loaded===0 && !file.isDuplicate && (cancelToken.current === null))
			upload()
	},[])

	const onCancelPress = (e) => {
		e.stopPropagation();
		cancelUpload();
	}


	const cancelUpload = () => {
		if(loaded === 100){
			const s3DeleteParams = {
				key: 'public/submissions/'+props.composer+'/'+props.file.id+'/'+props.file.name,
			}
			setIsDeleting(true)

			var deleteUpload = httpsCallable(functions, 'deleteUpload');
			deleteUpload(s3DeleteParams).then(result => {
					setIsDeleting(false)
			  	props.cancelUpload(true)
			}).catch(e => {
			 	console.log(e)
			});
		}
		else if ((cancelToken !== null) && (loaded > 0)) {
		  cancelToken.current.cancel("Operation canceled due to new request.");
		  props.cancelUpload(false)
		}else{
			props.cancelUpload(false)
		}
	}


	const upload = (name="") => {
		cancelToken.current = axios.CancelToken.source();
		if(name==="") 
			name = file.name

		const s3GetUrlParams = {
			S3BucketName: '4elementsmusic',
			key: file.key,
			contentType: file.type
		}

		var getPost = httpsCallable(functions,'getS3SignedUrlUpload');
		getPost(s3GetUrlParams).then(result => {
		  	// S3 pre-signed URL
		  	const options = {
		  		onUploadProgress: progressEvent => {
		  			let l = progressEvent.loaded/file.size * 100
		  			const newFile = { ...file, loaded: l }
		  			setFile(newFile)
		  			props.setUploaded(l)
		  		},
		     	  	headers: {
		     	    	'Content-Type': file.type,
		     	  	},
		     	  	cancelToken: cancelToken.current.token
		   	};
		   	// Run a HTTP PUT request to the pre-signed URL
		   	return axios.put(result.data, file.src.file, options).then(result => {

		     	const f = {...file, awsKey: s3GetUrlParams.key}
		     	delete f.isDuplicate

		     	props.setUploaded(100)
		    }).catch(e => {
		    	if(axios.isCancel(e)){
		    		// console.log(e.message)
		    	}else{
		    		console.log(e)
		    	}
		    });
		})
	}


	const replaceFile = () => {
		upload()
		setFile({...file, isDuplicate: false})
	}


	const keepFile = () => {
		console.log("keep both")
		const newName = file.name.substring(0,file.name.lastIndexOf(".")) + (" "+Date.now()) + file.name.substring(file.name.lastIndexOf("."))
		upload(newName)
		setFile({...file, name: newName, isDuplicate: false})
	}

	const strokeColor = loaded === 100 ? "#efefef" : (loaded === 0 ? "#d3d3d300" : "#ffcf43")

	return(
		<React.Fragment>
			<li className={`${styles.fileUpload} ${file.isDuplicate && styles.isDuplicate}`}>
				<div className={styles.uploadInner}>

					<div className={styles.uploadRow}>
						<div className={styles.fileName}>
							{truncateMiddle(file.name)} 
						</div>
					</div>

					<Line 
						className={styles.progressLine}
						percent={loaded} 
						strokeLinecap={"square"}
						strokeWidth="5" 
						trailWidth="5" 
						trailColor={(file.isDuplicate ? "#ff000066" : "#fff")}
						strokeColor={strokeColor} 
					/>

				</div>

				<div className={styles.percent}>
					{Math.round(loaded) || 0}%
				</div>

				<div className={styles.deleteColumn}>
					{isDeleting ? (
						<ClipLoader
						  css={override}
						  sizeunit={"px"}
						  size={10}
						  color={'#1d252c'}
						  loading={isDeleting}
						/>
					):(
						<Tooltip placement="top" trigger={['hover']} overlay={<span>Delete File</span>}>
						  <div 
						  	className={`${styles.downloadIcon} ${styles.downloadCancelButton}`} 
						  	onClick={onCancelPress}
						  >
						  	&#10006;
						  </div>
						</Tooltip>
					)}
				</div>
			</li>

			{file.isDuplicate && (
				<div className={styles.duplicateOptions}>
					<div>
						<button onClick={replaceFile}>Replace</button>
						<button onClick={keepFile}>Keep Both</button>
					</div>
				</div>
			)}
		</React.Fragment>
	)
}

export default FileUpload;