/*
* @Author: Joe Steccato
* @Date:   2023-04-27 16:14:58
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-05-11 23:45:08
*/
import { useEffect, useState } from 'react'
import { useCopyClipboard } from '@lokibai/react-use-copy-clipboard'
import { ReactSVG } from 'react-svg';

import Tooltip from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap_white.css';

import TrackTaggerPopup from '../TrackTagger/TrackTaggerPopup'

import styles from './TrackTags.module.scss'


const TrackTags = ({ keywords, updateKeywords, trackTitle }) => {	 
	const [ showTaggingTool, setShowTaggingTool ] = useState(false)

	const [ isCopied, setCopied ] = useCopyClipboard( '' )
	const [ showCopied, setShowCopied ] = useState(false)

	useEffect(() => {
		if(showCopied){
			setTimeout(() => {
				setShowCopied(false)
			},2000)
		}
	},[showCopied])


	useEffect(() => {
		setShowCopied(false)
	},[trackTitle])


	const redKeywords = keywords
		.filter(keyword => keyword.substring(0,2) !== "VL")
		.map((customKeyword,i) => {
			return <div key={i} className={styles.redKeyword}>{customKeyword}</div>
		})

	return(
		<div className={styles.container} >
		  <div className={styles.inner}>
		    
		    <div 
		    	className={styles.svgBtn} 
		    	onClick={setShowTaggingTool}
		    >
		        <ReactSVG
		         src={process.env.PUBLIC_URL + './svgs/edit.svg'} 
		         renumerateIRIElements={false}
		         wrapper="span"
		         className={styles.tagging_svg}
		         id=""
		        />
		    </div>
		  
		    <div className={styles.header}>
		    	Tagging
		    </div>

		    <div 
		    	className={styles.svgBtn} 
		    	onClick={() => {setCopied(keywords); setShowCopied(true)}}
		    >
		    {(showCopied && isCopied) ? (
		    	<div style={{cursor: 'pointer'}}>&#10003;</div>
		    ):(
		    	<ReactSVG
		    	   src={process.env.PUBLIC_URL + './svgs/copy.svg'} 
		    	   renumerateIRIElements={false}
		    	   wrapper="span"
		    	   className={styles.tagging_svg}
		    	   id=""
		    	/>
		    )}
		    </div>
		  </div>

		  <span className={styles.tagsLabel}>
		  	{keywords.length} Tags
		  </span>
		  
		  <div>
		    {redKeywords}
		  </div>

		  {showTaggingTool ?  
		    <TrackTaggerPopup 
		      trackTitle={trackTitle}
		      currentKeywords={keywords}
		      closePopup={(selected) => {
		        updateKeywords(selected)
		        setShowTaggingTool(false)
		      }}  
		      updateKeywords={updateKeywords}
		    />  
		    : null  
		  }

		</div>
	)
}

export default TrackTags