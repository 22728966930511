import { useState, useEffect } from 'react'

import FileInput from './FileInput';

import { isMobile } from 'react-device-detect';

import styles from './TrackFiles.module.scss'


const TrackFiles = ({ newTrackFiles, newTrackStems, newSoundDesignFiles, updateTrackFields, isArketype }) => {
	const [ showStems, setShowStems ] = useState( false )
	const [ showSoundDesign, setShowSoundDesign ] = useState( false )

	const fileNames = newTrackFiles ? newTrackFiles : [{name: ''}]
	const stemNames = newTrackStems ? newTrackStems : [{name: ''}]
	const soundDesignNames = newSoundDesignFiles ? newSoundDesignFiles : [{name: ''}]

	const latestFilename = fileNames[-1] ? fileNames[-1].name : null

	if(isMobile){
		return(
			<div className={styles.mobile}>
			  <div className={styles.header}>
			    Current File:
			  </div>
			  <p className={styles.latestFilename}>
			    {latestFilename}
			  </p>
			</div>
		)
	}

	return(
		<div className="inputColumn">

			<div className="formNotesHeader">
			  	<div className="notesHeader">
			  		<span>Files ({newTrackFiles.length}):</span>
			  	</div>
			</div>

			<FileInput 
			  files={newTrackFiles} 
			  showCheckboxes={true}
			  updateFiles={(value) => {updateTrackFields({ trackFiles: value }); } }
			/>

			<div className="formNotesHeader">
			  	<div className="notesHeader">
			    	<div>Stems ({newTrackStems ? newTrackStems.length : "0"}):</div>
			    	<button
			      		style={{width: '60px', fontSize: '12px'}}
			      		className="addRevisionBtn btn-flat"
			      		onClick={() => setShowStems(!showStems)}
			      	>
			      		{showStems ? "Hide" : "Show"}
			   		</button>
			  	</div>
			</div>

			{showStems ?
			    <FileInput 
			      files={newTrackStems} 
			      showCheckboxes={false}
			      updateFiles={(value) => {updateTrackFields({ trackStemFiles: value }); } }
			    />
			: null }

			{isArketype ? (<>
				<div className="formNotesHeader">
				  	<div className="notesHeader">
				    	<div>Sound Design ({newSoundDesignFiles ? newSoundDesignFiles.length : "0"}):</div>
				    	<button
				      		style={{width: '60px', fontSize: '12px'}}
				      		className="addRevisionBtn btn-flat"
				      		onClick={() => setShowSoundDesign(!showSoundDesign)}
				      	>
				      		{showSoundDesign ? "Hide" : "Show"}
				   		</button>
				  	</div>
				</div>

				{showSoundDesign ?
				    <FileInput 
				      files={newSoundDesignFiles} 
				      showCheckboxes={false}
				      updateFiles={(value) => {updateTrackFields({ trackSoundDesign: value }); } }
				    />
				: null }
			</>) : null}
			

			<hr/>
		</div>
	)
}

export default TrackFiles