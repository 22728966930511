/*
* @Author: Joe Steccato
* @Date:   2023-11-27 15:28:32
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-11-28 12:53:57
*/
import axios from 'axios';

const instance = axios.create({
  // baseURL: 'http://localhost:30000'
  baseURL: 'https://api.4elements-rails.com'
});

export default instance;