import React, { useState, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';
import { useList } from 'react-firebase-hooks/database';

import fb from '../../config/config';
import { getDatabase, ref, get, orderByChild, query } from "firebase/database";

import '../../styles/ComposerInput.css';

const db = getDatabase(fb)

const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

const ComposerInput = (props) => {
  const placeholder = props.placeholder
  const [ snapshots, loading, error ] = useList(query(ref(db, 'vl_composers'), orderByChild('Name')));

  const name = props.name

  const [ value, setValue ] = useState( props.value )
  const [ composers, setComposers ] = useState( [] )
  const [ suggestions, setSuggestions ] = useState( [] )

  useEffect(() => {
    if(!loading){
      const arr = snapshots.map(s => s.val())
      setComposers(arr)
    }
  },[loading])

  useEffect(() => {
    setValue(props.value)
  },[props.value])

  const onChange = (event, { newValue, method }) => {
    props.composerChanged(newValue)
    setValue(newValue)
  }

  const onBlur = (event) => { 
    props.onBlur(name, value) 
  }

  const getSuggestions = (value) => {
    const escapedValue = escapeRegexCharacters(value.trim());
    if (escapedValue === '') {
      return [];
    }
    const regex = new RegExp('^' + escapedValue, 'i');
    const newSuggestions = composers.filter(composer => regex.test(composer.name));
    if (newSuggestions.length === 0) {
      return [
        { isAddNew: true }
      ];
    }
    return newSuggestions;
  }

  const getSuggestionValue = (suggestion) => {
    if (suggestion.isAddNew) {
      // unused now with composer VLIDs
      // composerRef.push().set({
      //   name: value
      // })
      return value;
    }
    return suggestion.name;
  };

  const renderSuggestion = (suggestion) => {
    if (suggestion.isAddNew) 
      return <span>"{value}"</span>
    
    return suggestion.name;
  };
  
  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions( getSuggestions(value) )
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions( [] )
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    if(suggestion.defaultFee){
      props.setPayment(suggestion)
    }else{
      props.setPayment({...suggestion, defaultFee: '', defaultPaymentType: ''})
    }
  };

  const inputProps = {
    placeholder,
    name,
    value,
    onChange: onChange,
    onBlur: onBlur,
  };

  return (
     <Autosuggest 
       id={props.id}
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        onSuggestionSelected={onSuggestionSelected}
        inputProps={inputProps} 
      />
  )
}

export default ComposerInput;