import React, { useState, useEffect, useRef } from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
import Dropdown from 'react-dropdown';
import Textarea from 'react-textarea-autosize';
import axios from '../../axios'; 

import TrackTaggerPopup from '../TrackTagger/TrackTaggerPopup'

import { useOpenRequests } from '../../Hooks'
import { pad } from '../../Helpers'

import 'react-dropdown/style.css';
import '../../styles/ComposerInfoForm.css'

import styles from './ComposerInfoForm.module.scss'


const KeyCodes = {
  comma: 188,
  enter: 13,
};


const delimiters = [KeyCodes.enter, KeyCodes.comma];


function convertKeywords(data){
  return Object.keys(data).map(group => {
    return data[group].map(instrument => ({
        text: instrument.keyword,
        id: instrument.id
    }))
  }).flat()
}


const RequiredAstericks = () => <span className={styles.astericks}>*</span>


const FormLabel = ({name, required}) => 
  <div className={styles.formLabel}>
    {name}{required ? <RequiredAstericks/> : null}
  </div>

const FormField = ({error, children, small}) => {
  const outerClasses = [
    styles.formField, 
    (error ? styles.error : ''),
    (small ? styles.small : '')
  ].join(" ")

  return(
    <div className={outerClasses}>
      {children}

      <div className={styles.lock}>  &#128274;</div>
  
      {error && error.markup && <div className={styles.errorMsg}>{error.markup}</div>}
  
    </div>
  )
}


const InstrumentTags = ({ tags, updateTags }) => {
  const [ suggestions, setSuggestions ] = useState([])
  const [ showTaggingTool, setShowTaggingTool ] = useState(false)

  // Fetch Instrument Tags
  useEffect(() => {
    const fetchInstruments = async () => {
      try{
        const result = await axios.get('/keywords')
        const i_list = convertKeywords(result.data.instruments)
        setSuggestions(i_list)
      }catch(e){
        console.error(e)
      }
    }
    fetchInstruments()
  },[])

  function getMatches(tag, property){
    const matches = suggestions.filter(e => e[property].toLowerCase() === tag.trim().toLowerCase())
    if(matches.length > 0)
      return matches[0]
    return {id: tag, text: tag}
  }

  const handleTagInput = (tag) => {
    const newTags = tag.text.split(",").map((t) => getMatches(t, 'text'))
    updateTags([...tags, ...newTags])
  }

  const handleDelete = (i) => {
    updateTags(tags.filter((tag, index) => index !== i))
  }

  const onClosePopup = (selected) => {
    const newTags = selected.map(t => getMatches(t, 'id'))
    updateTags([...newTags])
    setShowTaggingTool(false)
  }

  const TaggingToolButton = (
    <a 
      className={styles.viewAllInstruments} 
      onClick={() => setShowTaggingTool(true)}
    >
      View All
    </a>
  )

  return(
    <>
    <FormLabel 
      name={
        <>
          Instrumentation ({TaggingToolButton})
        </>
      }
    />

    <div className={styles.tagsContainer}>
      <ReactTags tags={tags}
        suggestions={suggestions}
        handleDelete={handleDelete}
        handleAddition={(tag) => handleTagInput(tag)}
        delimiters={delimiters} 
        allowUnique={true}
      />
    </div>

    <div className={styles.autocomplete}>
      Auto-complete (from our website filters list) or type-in your own.
    </div> 

    {showTaggingTool ?  
      <TrackTaggerPopup  
        trackTitle={""}
        currentKeywords={tags.map(t => t.id)}
        instrumentsOnly={true}
        closePopup={onClosePopup}
        updateKeywords={(selected) => function(){}}
      />  
      : 
      null  
    }
    </>
  )
}

const ComposerNotesField = ({ notes, setNotes }) => (
  <FormField>
    <FormLabel name={"Notes"}/>
    <Textarea
      className={styles.noteInput}
      style={{ minRow: 6 }}
      name="notes"
      wrap="soft"
      spellCheck={false}
      value={notes} 
      onChange={(e) => setNotes(e.target.value)}   
    />
    <div className={styles.comment}>
      Feel free to include any comments on revision changes.
      <br/>  
      Please include info on new collaborators, if applicable.
    </div>
  </FormField>
)

export const ComposerNotes = ({ notes, setNotes }) => (
  <div className={[styles.mainForm, styles.create].join(" ")}>
    <ComposerNotesField {...{ notes, setNotes }}/>
  </div>
)


const ComposerInfoForm = ({ submissionType, notes, setNotes, composerNames, composerObjects, titleExists, setTitleExists, showFormErrors, thisTrack, updateTrack }) => {
  const { titleList: openRequestList } = useOpenRequests(true)

  let titleTimeout = null

	const isUpdateMode = (submissionType === "update") ? true : false;

  const thisComposerObj = composerObjects[thisTrack.trackComposer]

  const showLibraryDropdown = thisComposerObj ? (thisComposerObj.isArk && thisComposerObj.is4em) : false


  // On showLibraryDropdown change, set Library to default (4 Elements Music)
  useEffect(() => {
    if(!showLibraryDropdown){
      const trackUpdates = {
        trackLibrary: thisComposerObj.isArk ? 'Arketype' : '4 Elements Music'
      }
      updateTrack(trackUpdates)
    }
  },[showLibraryDropdown])

  // On Title change, check if already exists
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      checkIfUniqueTitle(thisTrack.trackTitle)
    }, 200)
    setTitleExists(false)
    return () => clearTimeout(delayDebounceFn)
  },[thisTrack.trackTitle])

  // Check Rails if Unique Title
  const checkIfUniqueTitle = async (title) => {
    if(title === "" || title === null || title === undefined) {
      setTitleExists(false)
      return;
    }
    const trimmedTitle = title.trim()
    try{
      const result = await axios.post('/tracks/track_title_exists', {
        title: trimmedTitle
      });
      setTitleExists(result.data.result)
    }catch (e){
      console.error(e)
    }
  }

  // Apply Track changes on Composer change
  const onComposerChange = async (e) => {
    const trackUpdates = { trackComposer: e.value }
    if(composerObjects){
      const composerDefaultInfo = composerObjects[e.value]
      trackUpdates.trackFee = composerDefaultInfo['defaultFee'],
      trackUpdates.trackSubmissionType = composerDefaultInfo['defaultPaymentType']
      if(composerDefaultInfo.isArk && composerDefaultInfo.is4em){
        trackUpdates.trackLibrary = ''
      }
    }
    updateTrack(trackUpdates)
  }

  // Apply Track changes on Library change
  const onLibraryChange = (e) => {
    if(thisTrack.trackLibrary === e.value) return
    const trackUpdates = {trackLibrary: e.value}
    // if(e.value === 'Arketype'){
    //   // trackUpdates.trackSubmissionType = 'Upfront Fee - Arketype Trailer'
    // }else{
    //   if(composerObjects){
    //     const composerDefaultInfo = composerObjects[thisTrack.trackComposer]

    //     trackUpdates.trackFee = composerDefaultInfo['defaultFee']
    //     trackUpdates.trackSubmissionType = composerDefaultInfo['defaultPaymentType']
    //   }
    // }
    updateTrack(trackUpdates)
  }

  // Set main form style based on Create or Update
  const outerClasses = [
    styles.mainForm, 
    (isUpdateMode ? styles.update : styles.create)
  ].join(" ")

  // Calculate form errors
  const formErrors = 
    showFormErrors ? {
      title: 
        titleExists
        ? {
            show: true, 
            markup: <>A track with this title already exists in the library.<br/>Please enter a different title.</>
          }
        : (thisTrack.trackTitle === '' && {show: true, markup: null}),
      library: thisTrack.trackLibrary === '' && {show: true, markup: null},
      bpm: thisTrack.trackBPM === '' && {show: true, markup: null},
      key: thisTrack.trackKey === '' && {show: true, markup: null},
    } : {
      title: null,
      library: null,
      bpm: null,
      key: null,
    }


	return (
    <div className={outerClasses}>
      <FormField>
        <FormLabel name={"Composer(s)"} required/>
        <Dropdown
          id="trackComposer"
          disabled={isUpdateMode}
          className={[styles.formInput, styles.formDropDown].join(' ')}
          value={thisTrack.trackComposer}
          options={composerNames} 
          onChange={onComposerChange}
         />  
      </FormField>

      {showLibraryDropdown ? (
          <FormField error={formErrors.library}>
            <FormLabel name={"Library"} required/>
            <Dropdown
              id="trackIsArk"
              disabled={isUpdateMode}
              className={[styles.formInput, styles.formDropDown, (formErrors.library ? 'error' : '')].join(' ')}
              value={thisTrack.trackLibrary}
              options={["4 Elements Music", "Arketype"]} 
              onChange={onLibraryChange}
             />  
             <div className={styles.autocomplete}>
               Please specify if this is an Arketype track.
             </div> 
          </FormField>
        ):(
        null
      )}

      <FormField error={formErrors.title}>
        <FormLabel name={"Title"} required/>
        <input
          id="trackTitle"
          className={styles.formInput}
          value={thisTrack.trackTitle}
          readOnly={isUpdateMode}
          onBlur={() => checkIfUniqueTitle(thisTrack.trackTitle)}
          onChange={(e) => updateTrack({trackTitle: e.target.value})}   
        />
      </FormField>
      
      {!isUpdateMode && (
        <>

          <div className={styles.formRow}>

            <FormField small error={formErrors.key}>
              <FormLabel name={"Key"} required/>
              <input
                  id={"trackKey"}
                  className={styles.formInput}
                  value={thisTrack.trackKey} 
                  readOnly={isUpdateMode}
                  onChange={(e) => updateTrack({trackKey: e.target.value})}   
              />     
            </FormField>
  
            <FormField small error={formErrors.bpm}>
              <FormLabel name={"BPM"} required/>
              <input
                id={'trackBPM'}
                className={[styles.formInput].join(' ')}
                readOnly={isUpdateMode}
                value={thisTrack.trackBPM} 
                onChange={(e) => updateTrack({ trackBPM: e.target.value })}   
              />  
            </FormField>
  
            <FormField small>
              <FormLabel name={"Time Signature"}/>
              <Dropdown
                id="trackTimeSig"
                className={[styles.formInput, styles.formDropDown].join(' ')}
                options={['4/4','3/4','2/4','5/4','6/4','7/4','6/8','12/8','N/A']} 
                value={thisTrack.trackTimeSig}
                disabled={isUpdateMode}
                onChange={(e) => updateTrack({ trackTimeSig: e.value })}
              />               
            </FormField>

          </div>
  
          <FormField>            
            <InstrumentTags 
              tags={thisTrack.trackInstruments}
              updateTags={(newTags) => updateTrack({ trackInstruments: newTags })}
            />
          </FormField>
  
          <FormField>
            <FormLabel name={"Request (#)"}/>
            <Dropdown
              id="trackRequestNum"
              className={[styles.formInput, styles.formDropDown].join(' ')}
              options={openRequestList} 
              value={thisTrack.trackRequestNum}
              disabled={isUpdateMode}
              onChange={(e) => updateTrack({ trackRequestNum: e.value })}
            />  
          </FormField>

        </>
      )}

    </div>
	)
}

export default ComposerInfoForm;