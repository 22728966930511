import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { COLOR_KEY, getColor } from '../../Constants'

import 'react-tabs/style/react-tabs.css';
import '../../styles/InstructionalPopup.css';
import '../../styles/ExportFilesPopup.css'

const Popup = (props) => {
	const [ tabSelected, setTabSelected ] = useState( 0 )

	const tabChange = (i,li,e) => setTabSelected(i)

	return (  
		<div className='popup'>  
			<div className='popup_inner color_key' style={{width: '50%'}}>  

				<div id='closePopup' className="instructionsClose" onClick={props.closePopup}>&#10005;</div>  
				<h1>Songbook Help</h1>  


				<Tabs onSelect={tabChange} default={0}>
					<TabList>
						<Tab>Track Color Key</Tab>
						<Tab>How To Search</Tab>
					</TabList>

					<TabPanel>
				     	<h2>Track Color Key</h2>  
				     	<div style={{backgroundColor: COLOR_KEY['blue']}}>
				     		<span>BLUE</span><br/>
				     		Track requires team attention.
				     	</div>
				     	<div style={{backgroundColor: COLOR_KEY['lightgreen']}}>
				     		<span>LIGHT GREEN</span><br/>
				     		Track has been accepted, composer responded to, and <b><u>includes a fee.</u></b>
				     	</div>
				     	<div style={{backgroundColor: COLOR_KEY['darkgreen'], color: "#fff"}}>
				     		<span>DARK GREEN</span><br/>
				     		Track has been accepted, composer responded to, and <b><u>does not include a fee.</u></b>
				     	</div>
				     	<div style={{backgroundColor: COLOR_KEY['pinkpurple']}}>
				     		<span>PINK-PURPLE</span><br/>Track has been accepted, rejected or needs a revision. <b><u>Requires team attention.</u></b>
				     	</div>
				     	<div style={{backgroundColor: COLOR_KEY['darkgray'], color: "#fff"}}>
				     		<span>DARK GRAY</span><br/>
				     		Track has been rejected, composer has been addressed.
				     	</div>
				     	<div style={{backgroundColor: COLOR_KEY['white']}}>
				     		<span>WHITE</span><br/>
				     		Track needs revision, composer has been addressed.
				     	</div>
				     	<div style={{backgroundColor: COLOR_KEY['lightgray']}}>
				     		<span>LIGHT GRAY</span><br/>
				     		Track has been ingested.
				     	</div>
				   	</TabPanel>
				   	<TabPanel>
				   		<h2>How To Search</h2>  
				   		<ul className="search_rules_ul">
				   			<li>Using the type-in search bar, you can only search for <b><u>one</u></b> criteria at a time.</li>
				   			<li>You can combine the type-in search bar with the toggles and dropdowns in the left sidebar menu.</li>
				   			<li>Typing "$" in the search-bar will show all tracks with Fee info.</li>
				   		</ul>
				   	</TabPanel>
				</Tabs>
				
			</div>  
		</div>  
	);  
}

export default Popup;