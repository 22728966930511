/*
* @Author: Joe Steccato
* @Date:   2023-04-13 13:10:43
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-11-27 15:56:19
*/

import { useState, useEffect } from 'react'
import axios from '../axios'

import { pad } from '../Helpers'


export const useOpenRequests = ( onlyVisible=false ) => {
	const [ loading, setLoading ] = useState(true)
	const [ titleList, setTitleList ] = useState([])
	const [ requestList, setRequestList ] = useState([])

	useEffect(() => {
		fetchOpenRequests()
	},[])

	const fetchOpenRequests = async () => {
		setLoading(true)
	  	const result = await axios.get(
	  	  	onlyVisible 
	  	   		? '/open_requests/all_visible_open_requests'
	  	   		: '/open_requests/all_open_requests'
	  	);
	  	const data = result.data.data
	  	const requestTitles = ["General Submission"]
	  	data.forEach(r => {
	  	  requestTitles.push(`#${pad(r.attributes.request_number)} ${r.attributes.title}`)
	  	})
	  	setTitleList(requestTitles)
	  	setRequestList(data)
	  	setLoading(false)
	}

	return { requestsLoading: loading, requestList, titleList, refreshRequests: fetchOpenRequests }
}