import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import 'react-tabs/style/react-tabs.css';


function formatHtml(html) {
    const indent = '  '; // Define the indentation (two spaces in this case)
    let formattedHtml = '';
    let indentLevel = 0;

    // Replace multiple spaces with a single space and remove leading/trailing whitespace
    html = html.replace(/\s+/g, ' ').trim();

    // Split the HTML into tags and text
    html.split(/(<\/?[^>]+>)/g).forEach(element => {
        if (element.match(/^<\//)) {
            // Closing tag: decrease indent
            indentLevel--;
        }

        // Add new line and indentation
        if (element.trim() !== '') {
            formattedHtml += "\n" + indent.repeat(indentLevel) + element.trim();
        }

        if (element.match(/^<[^\/!]/)) {
            // Opening tag: increase indent
            indentLevel++;
        }
    });

    return formattedHtml.trim();
}


const unformatHtml = (formattedHtml) => {
    // Function to convert formatted HTML back to single line
    return formattedHtml.replace(/\n/g, '').replace(/\s+/g, ' ');
};


const editorConfig = {
	toolbar: [
		'undo', 'redo', '|', 
		'bold', 'italic', 'link', '|', 
		'bulletedList', 'numberedList', '|', 
		'indent', 'outdent'
	]
}


const EditorTabs = ({ currentHtml, setEditedHtml, setIsEditorSaved }) => {
    const [ htmlText, setHtmlText ] = useState(currentHtml); // single string line, to be uploaded to request
    const [ formattedHtml, setFormattedHtml ] = useState(formatHtml(currentHtml)); // formatted HTML "source" 

    const [ tabSelected, setTabSelected ] = useState(0);

    const tabChange = (i) => {
        setTabSelected(i);
        if (i === 0) {
            // Convert formatted HTML to single line for CKEditor
            setHtmlText(unformatHtml(formattedHtml));
        } else {
            // Format HTML for the source view
            setFormattedHtml(formatHtml(htmlText));
        }
    };

    return (
        <div style={{ height: "50vh", display: "flex", flexDirection: "row", justifyContent: "center" }}>
            <Tabs onSelect={tabChange} default={0}>
                <TabList>
                    <Tab>Visual</Tab>
                    <Tab>HTML</Tab>
                </TabList>

                <TabPanel>
                    <div style={{ width: "60vw", height: "50vh" }}>
                      <CKEditor
                          editor={ClassicEditor}
                          config={editorConfig}
                          data={htmlText}
                          onReady={editor => {}}
                          onChange={(event, editor) => {
                              const data = editor.getData();
                              setHtmlText(data);
                              setIsEditorSaved(false)
                          }}
                          onBlur={(event, editor) => {
                              const data = editor.getData();
                              setFormattedHtml(formatHtml(data));
                              setEditedHtml(data)
                          }}
                      />
                    </div>
                </TabPanel>

                <TabPanel>
                    <div style={{ width: "100%" }}>
                        <textarea
                            style={{ width: "60vw", height: "50vh" }}
                            value={formattedHtml}
                            onChange={(event) => {
                            	setFormattedHtml(event.target.value)
                            	setIsEditorSaved(false)
                            }}
                            onBlur={(event) => setEditedHtml(unformatHtml(formattedHtml))}
                        />
                    </div>
                </TabPanel>

            </Tabs>
        </div>
    );
}
			
export default EditorTabs;