/*
* @Author: Joe Steccato
* @Date:   2023-10-10 14:28:50
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2024-01-22 10:42:16
*/
import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from '../../Hooks';
import axios from '../../axios'

import { capitalize } from '../../Helpers'
import { format, parseISO } from 'date-fns'

import Dropdown from 'react-dropdown';


import styles from './UserDetails.module.scss'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';


import { EMAIL_VALIDATION_REGEX, DROPDOWN_OPTIONS } from '../../Constants'

const { WEBSITE_USER_OPTIONS } = DROPDOWN_OPTIONS


const USER_ATTRIBUTES = {
	text: [
		'email', // text
		'password',

		'firstName', // text
		'lastName', // text

		'telephone', // text
		'extension', // text

		'company', // text
		'companyRole', // text

		'streetAddress', // text
		'city', // text
		'state', // text
		'postalcode', // text
		'country' // text
	],
	dropdown: [
		'default_format', // dropdown
		'download_limit', // dropdown
		'show_all_pros', // dropdown
		'show_pro_suspect', // dropdown
	],
	checkbox: [
		'confirmation_token', // LOCKED ?? just a is confirmed checkbox?
		'mailinglist', // checkbox
	]
}

const isValidEmail = email => EMAIL_VALIDATION_REGEX.test(String(email).toLowerCase());

const checkEmailAvailable = async (email) => {
  try {
    const response = await axios.post('/users/check_email_available', { email });
    return response.data.data; // Assuming the API returns { exists: true/false }
  } catch (error) {
    return false;
  }
};

const RequiredAsterisks = () => (<span style={{color: 'red'}}>*</span>)

// info about user (name, address, email, company)
const UserInfo = ({ user, setUser, isSaved, setIsSaved, onSave, buttonText, showPassword, disabled, requiredFields, hideButton }) => {
	const [ isSaving, setIsSaving ] = useState(false)

	const [ errors, setErrors ] = useState(null)

	const handleUserChanges = (attribute, value) => {
		setUser({
			...user,
			[attribute]: value
		})
		setIsSaving(false)
		setIsSaved(false)
	}

	const handleOnSave = async () => {
		setIsSaving(true)
		const { isValid, errors } = await validateForm()
		if(isValid){
			setErrors(null)
			onSave();
		}
		else{
			setErrors({...errors})
			setIsSaving(false)
		}

	}

	const validateForm = async () => {
	  let isValid = true;
	  let errors = {};

	  requiredFields.forEach(field => {
	    if (!user[field]) {
	      isValid = false;
	      errors[field] = 'This field is required';
	    }
	  });

	  if (user.email && !isValidEmail(user.email)) {
	    isValid = false;
	    errors.email = 'Invalid email address';
	  }

	  if(showPassword && (!errors.email && !(await checkEmailAvailable(user.email)))){
	  	isValid = false;
	  	errors.email = 'Email is in use';
	  }

	  // Return both the validity of the form and any error messages
	  return { isValid, errors };
	};


	let attributes = {...USER_ATTRIBUTES}
	if(!showPassword){
		attributes.text = attributes.text.filter(e => e !== 'password');
	}

	const saveText = (isSaving && isSaved) ? (showPassword ? "User Created!" : "Saved!") :  (isSaving ? "Loading..." : buttonText)

	return(<>
		<div className={styles.userInfo}>

			<div className={styles.userTextInfo}>
				{attributes.text.map((attr, i) => {
					const error = (errors !== null && errors[attr]) ? errors[attr] : false
					return(
						<div className={styles.userDetail} key={i}>
							<div className={styles.label}>
								{capitalize(attr)} {(requiredFields.indexOf(attr) > -1 ? (<RequiredAsterisks/>) : "")}
							</div>
							<div className={`${styles.value} ${error ? styles.error : ''}`}>
								<input 
									{...{disabled}}

									value={user[attr]} 
									onChange={(e) => handleUserChanges([attr], e.target.value)}
								/>
								{error ? <div className={styles.errorMsg}>{error}</div> : null}
							</div>
						</div>
					)
				})}
			</div>

			<div className={styles.userDropDownInfo}>
				<div className={styles.composer_pro}>
					Show All PROs
					<Dropdown
						{...{disabled}}
					  name="composer_pro"
					  className=""
					  options={WEBSITE_USER_OPTIONS.PRO} 
					  value={WEBSITE_USER_OPTIONS.PRO[user.show_all_pros]}
					  onChange={(e) => handleUserChanges("show_all_pros", WEBSITE_USER_OPTIONS.PRO.indexOf(e.value))} 
					/>
				</div>
				<div className={styles.composer_suspect}>
					Is PRO Suspect:
					<Dropdown
						{...{disabled}}

					  name="composer_suspect"
					  className=""
					  options={WEBSITE_USER_OPTIONS.SUSPECT} 
					  value={WEBSITE_USER_OPTIONS.SUSPECT[user.show_pro_suspect]}
					  onChange={(e) => handleUserChanges("show_pro_suspect", WEBSITE_USER_OPTIONS.SUSPECT.indexOf(e.value))} 
					/>
				</div>
				<div className={styles.composer_downloads}>
					Download Limit:
					<Dropdown
						{...{disabled}}

					  name="composer_downloads"
					  className=""
					  options={WEBSITE_USER_OPTIONS.DOWNLOAD_LIMIT} 
					  value={WEBSITE_USER_OPTIONS.DOWNLOAD_LIMIT[user.download_limit + 1]}
					  onChange={(e) => handleUserChanges("download_limit", WEBSITE_USER_OPTIONS.DOWNLOAD_LIMIT.indexOf(e.value) - 1)} 
					/>
				</div>
				<div className={styles.composer_downloads}>
					Download Format:
					<Dropdown
						{...{disabled}}
					  name="composer_downloads"
					  className=""
					  options={WEBSITE_USER_OPTIONS.DOWNLOAD_FORMAT} 
					  value={user.default_format}
					  onChange={(e) => handleUserChanges("default_format", e.value)} 
					/>
				</div>
				<div className={styles.composer_downloads}>
					<label>Mailing List:
					  	<input 
					  		{...{disabled}}
					  		type="checkbox" 
					  		checked={user.mailinglist}
					  		onChange={(e) => handleUserChanges("mailinglist", !user.mailinglist)} 
					  	/>
					</label>
				</div>
			</div>
			
		</div>

		{user.id ? 
			<div className={`${styles.idAndConfirmed} ${user.confirmation_token !== null ? styles.unconfirmed : ''}`}>
				id: {user.id} 
				&nbsp;&nbsp;||&nbsp;&nbsp; 
				{user.confirmation_token !== null ? "Account Not Confirmed" : "Account Confirmed"}
			</div>
		:
			<div className={styles.idAndConfirmed}>
				NOTE: Account will be activated automatically.
			</div>
		}

		{!hideButton && (!isSaved || isSaving) ? 
			<button 
				{...{disabled}} 
				className={styles.saveChanges + " " + ((isSaved && isSaving) ? styles.changesSaved : "" )} 
				onClick={handleOnSave}
			>
					{saveText}
			</button> 
		: null}
	</>)
}


export default UserInfo;