import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import { WithContext as ReactTags } from 'react-tag-input';
import { Link } from "react-router-dom";
import { ReactSVG } from 'react-svg';


import { getFunctions, httpsCallable } from 'firebase/functions'
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

import fb from '../../config/config';

import { generatePassword, validateEmail  } from '../../Helpers' 

import LoadingBars from '../Common/LoadingBars'

import 'react-tabs/style/react-tabs.css';
import '../../styles/InstructionalPopup.css';
import '../../styles/ExportFilesPopup.css';

import styles from './EditUser.module.scss'


const functions = getFunctions(fb)
const auth = getAuth(fb);

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];


const AddUserInstructions = () => (
	<div className={styles.instructions}>
		<div><u>Instructions:</u></div>
		<ul>
			<li>Create a new user by filling out the fields below.</li>
			<li className={styles.red}>Add the Composer's PRO registered name from the auto-complete field.</li>
			
			<p className={styles.red}>(NOTE: If the Composer PRO credit is not available in the auto-complete list,
				please check that the Composer has been created in Filemaker.)
			</p>

			<li>User will be emailed a link to finish account creation.</li>
			<li>From the link, user will be able to set their own password and login.</li>
		</ul>
	</div>
)


const UserForm = ({ email, displayName, composerTags, vlComposers, isAdmin, setIsAdmin, handleTextChange, setComposerTags}) => (

	<div className={styles.editUserForm}>
		<div className={styles.userField}>
			<div className={styles.userLabel}>Email:</div>
			<input 
				className={styles.userInput}
				placeholder="Email*" 
				type="email" 
				name="email" 
				value={email} 
				onChange={handleTextChange}
			/>
		</div>
		<div className={styles.userField}>
			<div className={styles.userLabel}>Name:</div>
			<input 
				className={styles.userInput}
				placeholder="Name*" 
				type="text" 
				name="displayName" 
				value={displayName} 
				onChange={handleTextChange}
			/>
		</div>
		<div className={styles.userField}>
			<div className={styles.userLabel}>PRO Credit(s):</div>
			<ReactTags tags={composerTags}
			  suggestions={vlComposers}
			  handleDelete={(i) => {
			  	setComposerTags(composerTags.filter((tag,index) => index !== i))
			  }}
			  handleAddition={(tag)=> setComposerTags([...composerTags, tag])}
			  delimiters={delimiters} 
			  allowUnique={false}
			  placeholder={"Composer PRO Name"}
			  className={{
			    tags: `${styles.reactTags}`
			  }}
			/>
		</div>
		<div className={styles.userAdminField}>
			<label htmlFor="userAdminSelect">Admin
			<input 
			  onChange={() => setIsAdmin(!isAdmin)}
			  id="userAdminSelect"
			  type="checkbox" 
			  defaultChecked={isAdmin}/>
			 </label>
		</div>		
	</div>
)



const EditUser = (props) => {
	const { user, userInfo } = props.fullUser
	const isAdding = props.isAdding

	const [ displayName, setDisplayName ] = useState(user.displayName || "")
	const [ email, setEmail ] = useState(user.email || "")
	const [ password, setPassword ] = useState(isAdding ? generatePassword() : null)
	const [ isAdmin, setIsAdmin ] = useState(userInfo.isAdmin || false)
	const [ loading, setLoading ] = useState(false)
	const [ error, setError ] = useState(null)

	const cTags = []
	if(userInfo.composerIDs){
		const propertyNames = Object.keys(userInfo.composerIDs);
		const propertyValues = Object.values(userInfo.composerIDs);
		propertyNames.forEach((t,i) => {
			cTags.push({id: t, text: propertyValues[i]})
		})
	}

	const [ composerTags, setComposerTags ] = useState( cTags )

	const handleTextChange = (e) => {
		const {name, value} = e.target
		if(name === "displayName"){
			setDisplayName(value)
		}else if(name === "email"){
			setEmail(value)
		}else if(name === "password"){
			setPassword(value)
		}
	}

	const resetPassword = () => {
		if(window.confirm("Are you sure you want to send a reset password link to '"+ email +"'?")){
			sendPasswordResetEmail(auth, email).then(function() {
			  // Email sent
			  setError("Reset Password email sent!")
			}).catch(function(e){
				setError("Error: "+e)
			})
		}
	}

	const submit = async () => {
		setLoading(true)
		const infoUpdates = {}
		const userUpdates = {}
		if(displayName !== user.displayName){
			userUpdates['displayName'] = displayName;
		}
		if(email !== user.email){
			userUpdates['email'] = email;
		}
		if(isAdmin !== userInfo.isAdmin){
			infoUpdates['/isAdmin'] = isAdmin
		}
		const composerObj = {}
		composerTags.forEach(t => {
			composerObj[t.id] = t.text
		})
		infoUpdates['/composerIDs'] = composerObj

		try{
			var updateUserInfo = httpsCallable(functions, 'updateUserInfo');
			const result = await updateUserInfo({
			  uid: user.uid,
			  infoUpdates: infoUpdates,
			  userUpdates: userUpdates,
			})
			props.closePopup(true);
		}catch(e){
			console.error(e)
		}
	}

	const deleteUser = async () => {
		if(window.confirm("Are you sure you want to delete the user '"+ user.email +"'?")){
			setLoading(true)

			try{
				const deleteUser = httpsCallable(functions, 'deleteUser');
				const result = await deleteUser({uid: user.uid})
				setLoading(false)
				props.closePopup(true);
			}catch(e){
				console.error(e)
			}
		}
	}

	const createUser = () => {
		setLoading(true)
		if(email === ""){
			setLoading(false)
			setError("Email address is required.")
			return
		}
		if(password === ""){
			setLoading(false)
			setError("Password is required.")
			return
		}
		if(displayName === ""){
			setLoading(false)
			setError("Name is required.")
			return
		}
		if(!validateEmail(email)){
			setLoading(false)
			setError("Email address is invalid.")
			return
		}
		if(composerTags.length < 1 && !isAdmin){
			setLoading(false)
			setError("Please add Composer PRO Name (not required for admin accounts).")
			return
		}
		const composerObj = {}
		composerTags.forEach(t => {
			composerObj[t.id] = t.text
		})
	  var newUser = {
	    email: email,
	    emailVerified: false,
	    password: password,
	    displayName: displayName,
	    disabled: false
	  }
	  var newUserInfo = {
	  	isAdmin: isAdmin,
	  	composerIDs: composerObj
	  }
	  
	  var createUser = httpsCallable(functions, 'createUser');
	  createUser({newUser, newUserInfo})
	    .then((result) => {
	      // Read result of the Cloud Function.
	      // console.log(result)
	      props.closePopup(true);

	    })
	    .catch((error) => {
	      var message = error.message;
	      setError(message)
	      // console.log(message)
	    });
	}


	return (  
		<div className='popup'>  
			<div className={`popup_inner ${styles.editUserPopup}`}>  

				{loading ? <LoadingBars color={"#1d252c"} loading={loading} /> : (
					<>
						<div id='closePopup' className="instructionsClose" onClick={() => props.closePopup(false)}>
							&#10005;
						</div>  
						
						<h3 className={styles.header}>
							{isAdding ? "ADD PORTAL USER ACCOUNT" : "EDIT PORTAL USER ACCOUNT"}
						</h3>
						

							<div>

								{isAdding ? <AddUserInstructions/> : null}

								<UserForm vlComposers={props.vlComposers} 
									{...{ email, displayName, composerTags, isAdmin, setIsAdmin, error, handleTextChange, setComposerTags}}
								/>

							</div>


							{error && (
								<div className={styles.errorMsg}>{error}</div>
							)}

							{isAdding ? (
							
								<div className={styles.userButtons}>
									<button className={styles.submitCreateButton} onClick={createUser}>CREATE</button>
								</div>

							):(
								<>
									<hr className={styles.buttonDivider}/>

									<div className={styles.userButtons}>
										<button className={styles.submitCreateButton} onClick={resetPassword}>SEND PASSWORD RESET</button>
									</div>

									<hr className={styles.buttonDivider}/>

									<div className={styles.userButtons}>
										<button className={styles.submitCreateButton} onClick={submit}>
												<ReactSVG
													src={process.env.PUBLIC_URL + './svgs/save.svg'}
													renumerateIRIElements={false}
													wrapper="span"
													className={styles.saveSVG}
												/>  
											SAVE CHANGES
										</button>
										<button className={styles.deleteButton} onClick={deleteUser}>X DELETE USER</button>
									</div>

									<div className={styles.userFieldID}>
										<div className={styles.userLabel}>User ID:</div>
										<div className={styles.userInput}>{user.uid}</div>
									</div>
								</>
							)}
					</>
				)}
					
			</div>
		</div>  
	);  
}

export default EditUser;