import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from "react-router-dom";
import { ReactSVG } from 'react-svg';
import Dropdown from 'react-dropdown';
import axios from '../../axios'

import { useNavigate } from "react-router-dom";

import { withRouter } from '../../Hooks';

import { DROPDOWN_OPTIONS } from '../../Constants';
import SearchBar from '../Common/SearchBar';

import LoadingBars from '../Common/LoadingBars'

import styles from './WebsiteUsers.module.scss'

const { WEBSITE_USER_OPTIONS } = DROPDOWN_OPTIONS


function filterUsers(c,filter=""){
  const lc = c.searchable.toLowerCase();
  let searchFilter = filter.toLowerCase();
  return lc.includes(searchFilter);
}


const usersSort = (a, b, sort) => {
	if ((a[sort]) < (b[sort])) {
	  return -1;
	}
	if ((a[sort]) > (b[sort])) {
	  return 1;
	}
	return 0;
}


const LinkGoogleDoc = () => (
	<a 
	  href="https://docs.google.com/document/d/1eKWY4k55hShnWK2DWXoJ3EIYYwucgKLEVN8h7jBLBag/edit?usp=sharing"
	  target="_blank" 
	  rel="noopener noreferrer" 
	  style={{color: "#ff0000", fontSize: "9px"}}
	>
	    Google Doc Instructions
	</a>
)


const SortArrow = ({sortDirection}) => (sortDirection ? <span>&#9650;</span> : <span>&#9660;</span>)


const WebsiteUsers = (props) => {
	const [ loading, setLoading ] = useState(true)
	const [ filtered, setFiltered ] = useState ([])
	const [ users, setUsers ] = useState( [] )
	const [ selectedRow, setSelectedRow ] = useState( -1 )
	const [ sortBy, setSortBy ] = useState( 'id' )
	const [ sortDirection, setSortDirection ] = useState( false )

	const [ addingUser, setAddingUser ] = useState( false )

	const [query, setQuery] = useState("");

	const userRefs = useRef([]);

	const navigate = useNavigate()

	// Load from Realtime DB
	useEffect(() => {
		fetchUsers()
	},[])

	// set new sortBy column, alternating list direction
	const setNewSort = (column) => {
		if(column === sortBy) 	
			sortChange(column, !sortDirection)
		else 						
			sortChange(column, sortDirection)
	}

	// handle showing sortBy and sortDirection changes
	const sortChange = (newSortBy, newSortDirection) => {
		const sortUsers = [...users];
		let sort = newSortBy;

		if(sort === "pro") sort = "show_all_pros"
		if(sort === "suspect") sort = "show_pro_suspect"
		if(sort === "downloads") sort = "download_limit"

		sortUsers.sort((a,b) => usersSort(a, b, sort));

		setUsers(sortUsers);
		setSortDirection(newSortDirection)
		setSortBy(newSortBy)

		if(query !== "")
		  onSearchInput(query)
		else
		  setFiltered(newSortDirection ? sortUsers : sortUsers.reverse())
	}

	// Fetch Website Users from Rails server
	const fetchUsers = async () => {
		const result = await axios.get(`/users/show_all?secret=@viva43l3m3nts!`);

		const fetchedUsers = result.data.data.map(u => ({
				...u.attributes, 
				name: u.attributes.firstName + " " + u.attributes.lastName,
				searchable: [
					u.attributes.firstName, 
					u.attributes.lastName, 
					u.attributes.email,
					u.attributes.company,
					u.attributes.confirmation_token !== null ? "unconfirmed" : ""
				].join(' ')
		}))
		
		setUsers(fetchedUsers)

		if(query !== "")
		  onSearchInput(query)
		else
		  setFiltered(fetchedUsers)

	  setLoading(false)
	}


	// update user
	const updateUser = async (userUpdates, id) => {
		const result = await axios.post(`/users/update_internal`, {
		  secret: "@viva43l3m3nts!",
		  id: id,
		  user: userUpdates
		});

		let index = users.findIndex(e => e.id === id)

		// Create a new array with the updated user
		const updatedUsers = [...users];
		updatedUsers[index] = {
		  ...updatedUsers[index],
		  ...userUpdates
		};

		// Update the state with the new array
		setUsers(updatedUsers);

		// Additional code for re-filtering if necessary
		if(query !== "")
		  onSearchInput(query)
		else
		  setFiltered(fetchedUsers)
	}

	// on search input
	const onSearchInput = (val) => {
	  const newFiltered = [...users].filter(u => filterUsers(u,val));
	  setFiltered(newFiltered);
	  setQuery(val);
	}

	// on search clear
	const onClearQuery = () => {
	  setQuery('')
	  setFiltered(users)
	}

	const sortByStyle = (s) => sortBy === s ? styles.selected_sort_column : ""

	return(
		<div className={styles.main}>
			<div className="usersHeadingButtonsRow" style={{ width: '75%', maxWidth: '1000px'}}>
		  	<div style={{width: '30%',margin: 'auto'}}></div>

		 		<div>
		 		  <h3 className={styles.headerText}>Website Users</h3>
		 		  <div style={{fontSize: "9px", color: 'red'}}>
		 		  	(These accounts are for logging into 4elementsmusic.com.)
		 		  </div>
		 		  {/*<LinkGoogleDoc />*/}
		 		</div>

		  	<div
		  	  style={{
		  	    width: "30%",
		  	    margin: 'auto',
		  	    textAlign: 'right'
		  	  }}
		  	>
		    	<button className="editUserButton addUserButton"
		    	  onClick={() => navigate("/website-users/add")}
		    	>
		    	  + Add User
		    	</button>
		  	</div>
			</div>

			{loading ? <LoadingBars loading={true} color={"#d3d3d3"} /> :(

				<div className={styles.composer_list_main}>
					<SearchBar 
					  page={"requests"}
					  query={query}
					  onSearchInput={onSearchInput}
					  onClear={onClearQuery}
					  placeholder={"Search by name or email..."}
					/>

					{/*   ID	NAME 	EMAIL		PRO		SUSPECT 	UNLIMITED DOWNLOADS   */}
					<div className={styles.composer_row + " " + styles.header_row}>
					  {['id', 'name', 'email', 'pro', 'suspect', 'downloads'].map((column) => (
					    <div 
					      key={column}
					      className={`${styles[`composer_${column}`]} ${sortByStyle(column)}`}
					      onClick={() => setNewSort(column)}>
					        {column.toUpperCase()} 
					        {sortBy === column ? <SortArrow {...{sortDirection}}/> : null}
					    </div>
					  ))}
					  <div className={`${styles.composer_buttons}`}></div>
					</div>

					<div className={styles.composer_item_container}>

					{(!loading) && filtered.map((c,i) => {
						const rowColor = i % 2;
						const isDisabled = false;

						const notConfirmed = c.confirmation_token !== null;
						return(
							<div key={c.id}  ref={el => userRefs.current[c.id] = el} 
								className={`${styles.composer_row} ${styles.not_header} ${styles["row_color_"+rowColor]} ${notConfirmed ? styles.not_confirmed : ""}` }
								onClick={()=>setSelectedRow(c.id)}
							>
								<div className={styles.composer_id}>
									{c.id}
								</div>

								<input name="composer_name"
									disabled={true} 
									className={styles.composer_name} 
									value={`${c.firstName} ${c.lastName}`}
								/>

								<input name="composer_email"
									disabled={true} 
									className={styles.composer_email} 
									value={c.email}
								/>

								<div className={styles.composer_pro}>
									<Dropdown
									  name="composer_pro"
									  className=""
									  options={WEBSITE_USER_OPTIONS.PRO} 
									  value={WEBSITE_USER_OPTIONS.PRO[c.show_all_pros]}
									  onChange={(e) => updateUser({show_all_pros: WEBSITE_USER_OPTIONS.PRO.indexOf(e.value)}, c.id)}
									/>
								</div>

								<div className={styles.composer_suspect}>
									<Dropdown
									  name="composer_suspect"
									  className=""
									  options={WEBSITE_USER_OPTIONS.SUSPECT} 
									  value={WEBSITE_USER_OPTIONS.SUSPECT[c.show_pro_suspect]}
									  onChange={(e) => updateUser({show_pro_suspect: WEBSITE_USER_OPTIONS.SUSPECT.indexOf(e.value)}, c.id)}
									/>
								</div>

								<div className={styles.composer_downloads}>
									<Dropdown
									  name="composer_downloads"
									  className=""
									  options={WEBSITE_USER_OPTIONS.DOWNLOAD_LIMIT} 
									  value={WEBSITE_USER_OPTIONS.DOWNLOAD_LIMIT[c.download_limit + 1]}
									  onChange={(e) => updateUser({download_limit: WEBSITE_USER_OPTIONS.DOWNLOAD_LIMIT.indexOf(e.value) - 1}, c.id)}
									/>
								</div>

								<div className={`${styles.composer_buttons}`}>
										<button onClick={() => navigate("/website-users/"+c.id)}>
											View
									  </button>
								</div>

							</div>
						)}
					)}
					</div>
				</div>
			)}
		</div>
	)
}

export default withRouter(WebsiteUsers);