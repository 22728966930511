import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon'

import AnimateHeight from 'react-animate-height';

import fb from '../../config/config';

import ScaleLoader from 'react-spinners/ScaleLoader';

import { override } from '../Common/LoadingBars'

import styles from './ComposerPreviousUploads.module.scss'


function prettyDate2(timeString) {
  var date = new Date(timeString);
  var dateString = date.toLocaleDateString('en-us',{ dateStyle: 'short' })
  var timeString = date.toLocaleTimeString(navigator.language, {
    hour: '2-digit',
    minute:'2-digit'
  });
  var zoneString = DateTime.local().toFormat('ZZZZ')
  return(dateString+" "+timeString+" "+zoneString)
}



const TrackFiles = ({ type, files }) => (
	<>
		<hr/>
		<div className={styles.fileList}>
			<div className={styles.fileListHeader}>{type.toUpperCase()}</div>
			{files && files.length > 0 ? (
	
					<ul>
						{files.map((f,i) => (
								<li key={i}>
									<div className={styles.name}>{f.name}</div>
									<div className={styles.date}>
										{prettyDate2(f.date)}
									</div>
								</li>
							)	
						)}
					</ul>
			) : (
				<div className={styles.noFiles}>
					No files yet.
				</div>
			)}
		</div> 
	</>
)



const ComposerPreviousTrack = (props) => {
	const [ isViewing, setIsViewing ] = useState(false)
	const t = props.track;

	return(
		<div className={styles.tableTrack}>
			<div className={styles.tableItem} >
				<div className={styles.name}>{t.trackTitle}</div>
				<div className={styles.composer}>{t.trackComposer}</div>
				<div className={styles.date}>
					{prettyDate2(t.trackTimeMod)}
				</div>
				<div className={styles.viewButton}>
					<button	onClick={() => setIsViewing(!isViewing)}>Details</button>
				</div>
			</div>
			<AnimateHeight
			  id='example-panel'
			  duration={ 200 }
			  height={ isViewing ? "auto" : 0 } // see props documentation below
			>
				<div className={styles.trackDetails}>
					<br/>
					<TrackFiles files={t.trackFiles} type={'tracks'} />
					<TrackFiles files={t.trackStemFiles} type={'stems'} />
					{t.trackLibrary === 'Arketype' ? 
						<TrackFiles files={t.trackSoundDesign} type={'sound design'} />

					: null}
				</div>
			</AnimateHeight>
		</div>
	)
}


const ComposerPreviousUploads = (props) => {
	const [ loading, setLoading ] = useState(true)
	const [ files, setFiles ] = useState( [] )
	const [ tracks, setTracks ] = useState( [] )

	useEffect(() => {
			const ts = []
	    props.snapshots.forEach(s => {
	    	const t = s.val()
	    	ts.push({
	    		trackTitle: t.trackTitle,
	    		trackComposer: t.trackComposer,
	    		trackFiles: t.trackFiles,
	    		trackStemFiles: t.trackStemFiles,
	    		trackSoundDesign: t.trackSoundDesign,
	    		trackSubmittedType: t.trackSubmissionType,
	    		trackRequestNum: t.trackRequestNum,
	    		trackTimeAdd: t.trackTimeAdd,
	    		trackTimeMod: t.trackTimeMod,
	    		trackLibrary: t.trackLibrary,
	    		isViewing: false
	    	})
	    })
	    ts.sort((a, b) => a.trackTimeMod < b.trackTimeMod ? -1 : a.trackTimeMod > b.trackTimeMod ? 1 : 0)
	    ts.reverse()
	    setTracks(ts)

	    setLoading(props.loading)
	  }, [props.snapshots])


	return (	
		<div className={styles.container}>
		  <div className={styles.header}>
		    PREVIOUS SUBMISSIONS
		  </div>

			<div className={styles.content}>

				{loading ? (
					<ScaleLoader
		    	  css={override}
		    	  sizeunit={"px"}
		    	  size={300}
		    	  color={'#1d252c'}
		    	  loading={loading}
		    	/>
		    ):(
		    	<>
			  		<div className={styles.tableHeader}>
			  		  <div className={styles.name}><b>SUBMISSION TITLE</b></div>
			  		  <div className={styles.composer}><b>COMPOSER(S)</b></div>
			  		  <div className={styles.date}><b>DATE UPLOADED</b></div>
			  		  <div className={styles.viewButton}><b></b></div>
			  		</div>
	
			  		{/*{tracks.length > 0 ? (*/}
			  			<div className={styles.tableList}>
			  				{tracks.map((t,i) => ( 
			  					<ComposerPreviousTrack key={i} track={t}/> 
			  				))}
			  			</div> 
{/*			  		) : (
			  			<div className={styles.noTracks}>
			  				No tracks yet.
			  				<br/><br/>
			  				Click "New Submission" to get started!
			  			</div>
			  		)}*/}
			  	</>
			  )}
			</div>
		</div>
	)
}

export default ComposerPreviousUploads;